import { Outlet } from 'react-router-dom';
import TDLinkTabs from '../../../components/linkTabs/TDLinkTabs';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';

import { useTranslation } from 'react-i18next';
import TDDepartmentsFilter from '../../../components/filters/TDDepartmentsFilter';
import TDUnitsFilter from '../../../components/filters/TDUnitsFilter';
import { AnalysisSegment } from '../../../utils/analysis';
import ContentAnalysisTooltip from './contentAnalysisTooltip/ContentAnalysisTooltip';
import useContentsPage from './useContentsPage';

const ContentsPage = () => {
  const { tabs, onChangeTab, currentTab } = useContentsPage();

  const { t } = useTranslation();

  return (
    <PageWithHeader
      left={
        <PageTitleWithTooltip
          title={t('content_analysis')}
          testId='content-page-heading'
        />
      }
      center={<TDLinkTabs links={tabs} onChange={onChangeTab} />}
      right={
        currentTab === tabs[0]?.name ? (
          <TDUnitsFilter />
        ) : (
          <TDDepartmentsFilter />
        )
      }
      tooltip={<ContentAnalysisTooltip />}
      pageName={AnalysisSegment.PAGE_NAMES.Content_Page}
    >
      <Outlet />
    </PageWithHeader>
  );
};

export default ContentsPage;
