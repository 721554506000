import CachedIcon from '@mui/icons-material/Cached';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SupportIcon from '@mui/icons-material/Support';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FlagIcon } from '../../../../../assets/icons/flag.svg';
import AccordionBB from '../../../../../components/AccordionBB/AccordionBB';
import DetailLoDialog from '../detailLoDialog/DetailLoDialog';
import './UserReport.scss';
import useUserReport from './useUserReport';

const UserReport = ({ user, courseGuid }) => {
  const { t } = useTranslation();

  const {
    losNeedsHelp,
    losIsMastered,
    losIsMasteredRecently,
    displayedIsMastered,
    setShowAllLosIsMastered,
    showAllLosIsMastered,
    displayedNeedsHelp,
    setShowAllLosNeedsHelp,
    showAllLosNeedsHelp,
    displayedIsMasteredRecently,
    setShowAllLosIsMasteredRecently,
    showAllLosIsMasteredRecently,
    onOpenDetailLoDialog,
    onCloseDetailLoDialog,
    isDetailLoDialogOpen,
    currentLo
  } = useUserReport(user);

  const title = (
    <div className='reports-user-header'>
      <div>
        {user.name} {user.lastname}
        {losNeedsHelp && losNeedsHelp.length > 0 && (
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '16px'
            }}
          >
            {losNeedsHelp.length}
            <FlagIcon
              style={{
                color: 'red'
              }}
            />
          </span>
        )}
      </div>
      <div className='reports-user-header__text'>
        {`${user.number_activities} `}→
        {` ${user.percentage}% ${
          String(user.percentage).includes('-')
            ? t('reports_average_below')
            : t('reports_average_above')
        }`}
      </div>
      <div className='reports-user-header__text'>{user.time}</div>
    </div>
  );

  const description = (
    <div className='reports-user-content'>
      <div className='reports-user-content__needs-help'>
        <div className='reports-user-content__needs-help__title'>
          <SupportIcon className='reports-user-content__needs-help__icon' />
          <span className='reports-user-content__subtitle'>
            {t('reports_has_difficulties')}
          </span>
        </div>
        <ul>
          {displayedNeedsHelp?.map((lo, index) => {
            return (
              <li
                key={lo?.guid || `lo-${index}`}
                onClick={() => onOpenDetailLoDialog(lo)}
              >
                <Tooltip title={`${lo.unit.name} - ${lo.lesson.name}`}>
                  <span>{lo.name}</span>
                </Tooltip>
              </li>
            );
          })}
        </ul>
        {losNeedsHelp && losNeedsHelp.length > 6 && (
          <button
            onClick={() => setShowAllLosNeedsHelp(!showAllLosNeedsHelp)}
            className='reports-user-content__button'
          >
            {showAllLosNeedsHelp
              ? t('reports_button_less')
              : t('reports_button_more')}
          </button>
        )}
      </div>
      <div className='reports-user-content__is-mastered'>
        <div className='reports-user-content__is-mastered__title'>
          <RocketLaunchIcon className='reports-user-content__is-mastered__icon' />
          <span className='reports-user-content__subtitle'>
            {t('reports_mastered')}&nbsp;
          </span>
          {losIsMastered && losIsMastered.length > 0 && (
            <span className='reports-user-content__is-mastered__concept'>
              {losIsMastered.length}{' '}
              {losIsMastered.length == 1
                ? t('reports_micro_concept')
                : t('reports_micro_concepts')}
            </span>
          )}
        </div>
        <div>
          {losIsMastered && losIsMastered.length > 0 && (
            <button
              onClick={() => setShowAllLosIsMastered(!showAllLosIsMastered)}
              className='reports-user-content__button'
            >
              {showAllLosIsMastered
                ? t('reports_button_less')
                : t('reports_button_more')}
            </button>
          )}
        </div>
        <ul>
          {displayedIsMastered?.map((lo, index) => {
            return (
              <li
                key={lo?.guid || `mastered-lo-${index}`}
                onClick={() => onOpenDetailLoDialog(lo)}
              >
                {lo?.unit?.name} - {lo?.lesson?.name}
              </li>
            );
          })}
        </ul>
      </div>
      {losIsMasteredRecently && losIsMasteredRecently.length > 0 && (
        <div className='reports-user-content__is-mastered-recently'>
          <div className='reports-user-content__is-mastered-recently__title'>
            <CachedIcon className='reports-user-content__is-mastered-recently__icon' />
            <span className='reports-user-content__subtitle'>
              {t('reports_forgetting_curve')}&nbsp;
            </span>
            {losIsMasteredRecently && losIsMasteredRecently.length > 0 && (
              <span className='reports-user-content__is-mastered-recently__concept'>
                {losIsMasteredRecently.length}{' '}
                {losIsMasteredRecently.length == 1
                  ? t('reports_concept')
                  : t('reports_concepts')}
              </span>
            )}
          </div>
          <div>
            {losIsMasteredRecently && losIsMasteredRecently.length > 0 && (
              <button
                onClick={() =>
                  setShowAllLosIsMasteredRecently(!showAllLosIsMasteredRecently)
                }
                className='reports-user-content__button'
              >
                {showAllLosIsMasteredRecently
                  ? t('reports_button_less')
                  : t('reports_button_more')}
              </button>
            )}
          </div>
          <ul>
            {displayedIsMasteredRecently?.map((lo, index) => {
              return (
                <li key={index} onClick={() => onOpenDetailLoDialog(lo)}>
                  {lo.unit.name} - {lo.lesson.name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <div className='reports-user'>
      <AccordionBB title={title} description={description} />
      {isDetailLoDialogOpen && (
        <DetailLoDialog
          isOpen={isDetailLoDialogOpen}
          onClose={onCloseDetailLoDialog}
          lo={currentLo}
          courseGuid={courseGuid}
          userGuid={user.guid}
        />
      )}
    </div>
  );
};

export default UserReport;
