import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import { getTeacherDashboardStandards } from '../../../../utils/crud/teacherDashboard';
import { getBBTeacherUserLanguage } from '../../../../utils/helpers/userLanguage';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../utils/localStorageKeys';

const useClassProgressStandards = () => {
  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const { selectedCourse } = useContext(CourseContext);
  const [searchParams] = useSearchParams();
  const { getItem } = useLocalStorage();

  const getData = async () => {
    setIsLoading(true);
    let response = await getTeacherDashboardStandards({
      courseGuid: selectedCourse.guid,
      lang: getBBTeacherUserLanguage(),
      ...(searchParams.get('standard')
        ? { standardGuid: searchParams.get('standard') }
        : {})
    });
    const userLanguage = getItem(localStorageKeys.language)?.toUpperCase();

    const getStandardTranslation = (itemIndex) => {
      // Takes the user language, if it's not defined, takes the first one available
      const translation =
        response.filters[itemIndex]?.standardLanguages?.find(
          (item) => item.langCode === userLanguage
        ) || response.filters[itemIndex]?.standardLanguages?.[0];
      return translation;
    };

    const detailsDataFromAPI = response?.detail || [];

    const parsedData = detailsDataFromAPI.map((item, index) => {
      const singleItemData = item?.data || [];

      const masteredData = singleItemData.filter(
        (i) => i.key === 'mastered'
      )[0];
      const inProgressData = singleItemData.filter(
        (i) => i.key === 'inProgress'
      )[0];
      const needHelpData = singleItemData.filter(
        (i) => i.key === 'needHelp'
      )[0];
      const needMotivationData = singleItemData.filter(
        (i) => i.key === 'needMotivation'
      )[0];

      return {
        name: getStandardTranslation(index)?.skills || item?.name,
        label: item?.name,
        mastered: masteredData?.value,
        masteredStudents: masteredData?.students,
        inProgress: inProgressData?.value,
        inProgressStudents: inProgressData?.students,
        needHelp: needHelpData?.value,
        needHelpStudents: needHelpData?.students,
        needMotivation: needMotivationData?.value,
        needMotivationStudents: needMotivationData?.students
      };
    });

    setData(parsedData);

    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPreloading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (isPreloading || !selectedCourse?.guid) return;
    getData();
  }, [isPreloading, selectedCourse, searchParams.get('standard')]);

  return {
    data,
    isLoading
  };
};

export default useClassProgressStandards;
