import Check from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCourse } from '../../utils/contexts/CourseContext';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';
import './ClassSelectorMenuItem.scss';

const ClassSelectorMenuItem = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { removeItem } = useLocalStorage();
  const navigate = useNavigate();

  const { availableCourses, selectedCourse, setSelectedCourse } = useCourse();
  const [numberOfStudents, setNumberOfStudents] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (availableCourses && !selectedCourse?.guid) {
      setSelectedCourse(availableCourses[0]);
      setNumberOfStudents(
        availableCourses[0]?.users?.filter((user) => user.role_guid === 'R01')
          ?.length || 0
      );
    }
  }, [selectedCourse, availableCourses]);

  useEffect(() => {
    if (selectedCourse?.guid) {
      setNumberOfStudents(
        selectedCourse?.users?.filter((user) => user.role_guid === 'R01')
          ?.length || 0
      );
    }
  }, [selectedCourse?.guid, selectedCourse?.latestFetch]);

  // for topbar type selector
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (course, index) => {
    setAnchorEl(null);
    setSearchParams({});

    // navigate to the selectedCourseURL
    navigate(
      window.location.pathname.replace(selectedCourse?.guid, course.guid)
    );
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const listItemRef = useRef(null);

  return (
    <div className='class-selector__container'>
      <List component='nav'>
        <ListItemButton
          id='class-selector'
          onClick={handleClickListItem}
          className='class-selector__menu-button'
          disableRipple
          ref={listItemRef}
        >
          <ListItemText
            primary={selectedCourse?.school_group_name}
            secondary={`${numberOfStudents} ${t('students')}`}
          />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItemButton>
      </List>
      <Menu
        id='class-selector-menu'
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        className='class-selector__menu'
      >
        {availableCourses
          .sort((a, b) =>
            a?.school_group_name.localeCompare(b?.school_group_name)
          )
          .map((course, index) => (
            <MenuItem
              className='class-selector__menu-item'
              key={course?.guid}
              selected={course?.guid === selectedCourse?.guid}
              onClick={() => handleMenuItemClick(course, index)}
              style={{
                minWidth: listItemRef.current
                  ? listItemRef.current.offsetWidth - 20
                  : 0
              }}
            >
              {course?.school_group_name}
              {course?.guid === selectedCourse?.guid && (
                <Check fontSize='small' />
              )}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default ClassSelectorMenuItem;
