import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TDHeaderButton } from '../../../../../atoms/TDUILib';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import './StudentDetailsMenu.scss';
import useStudentDetailsMenu from './useStudentDetailsMenu';
const StudentDetailsMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedCourse } = useCourse();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState('interventions');
  const {
    student,
    isLoadingStudent,
    previousStudentGuid,
    nextStudentGuid,
    isLoadingAllStudents
  } = useStudentDetailsMenu();

  const menuItems = [
    {
      label: t('interventions_title'),
      value: 'interventions'
    }
  ];

  const navigateUser = (userGuid) => {
    navigate(`/${selectedCourse?.guid}/students/${userGuid}`, {
      replace: true
    });
  };

  useEffect(() => {
    setIsLoading(isLoadingStudent || isLoadingAllStudents);
  }, [isLoadingStudent, isLoadingAllStudents]);

  return (
    <>
      <div className='student-menu__top-section'>
        <div className='student-menu__avatar-container'>
          {isLoadingStudent ? (
            <Skeleton variant='circular' className='student-menu__avatar' />
          ) : (
            <img
              src={student?.avatar} // Use the avatar URL state
              alt='Student Avatar'
              className='student-menu__avatar'
            />
          )}
        </div>
        <div className='student-menu__navigation'>
          <TDHeaderButton
            icon={<ChevronLeft />}
            border
            small
            onClick={() => navigateUser(previousStudentGuid)}
            disabled={!previousStudentGuid || isLoadingStudent}
          />

          <div className='student-menu__student-info'>
            <div className='student-menu__student-name two-line-ellipsis'>
              {!isLoadingStudent &&
                student?.name &&
                `${student?.name} ${student?.lastname}`}
              {isLoadingStudent && <Skeleton variant='text' width={'100%'} />}
            </div>
            <div className='student-menu__student-username two-line-ellipsis'>
              {!isLoadingStudent && student?.username}
              {isLoadingStudent && <Skeleton variant='text' width={'100%'} />}
            </div>
            <div className='student-menu__student-classname two-line-ellipsis'>
              {!isLoadingStudent && student?.classname}

              {isLoadingStudent && <Skeleton variant='text' width={'100%'} />}
            </div>
          </div>

          <TDHeaderButton
            icon={<ChevronRight />}
            border
            small
            onClick={() => navigateUser(nextStudentGuid)}
            disabled={!nextStudentGuid || isLoadingStudent}
          />
        </div>
      </div>
      <div className='student-menu__sections'>
        {menuItems.map((item) => (
          <button
            key={item.value}
            onClick={() => setCurrentSection(item.value)}
            className={`student-menu__sections-item ${
              currentSection === item.value
                ? 'student-menu__sections-item--selected'
                : ''
            }`}
          >
            {item.label}
          </button>
        ))}
      </div>
    </>
  );
};

export default StudentDetailsMenu;
