import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useCourse } from '../../../utils/contexts/CourseContext';
import { useStudent } from '../../../utils/contexts/StudentContext';

const useStudentDetails = () => {
  const { studentGuid } = useParams();
  const { selectedCourse } = useCourse();
  const { setStudentGuid, student, isLoadingStudent } = useStudent();

  useEffect(() => {
    if (!studentGuid || !selectedCourse?.guid) return;
    setStudentGuid(studentGuid);
  }, [studentGuid, selectedCourse]);

  return { student, isLoading: isLoadingStudent };
};

export default useStudentDetails;
