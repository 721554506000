import Table from '../../../../components/table/Table';

import useContentsStandardAnalysis from './useContentsStandardAnalysis';

const ContentsStandardAnalysis = () => {
  const { data, columns, isLoading, onClickOnStandard } =
    useContentsStandardAnalysis();

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      onRowClick={onClickOnStandard}
      testId='content-standards-table'
    />
  );
};

export default ContentsStandardAnalysis;
