import { ReactComponent as ToastInfo } from '../../assets/icons/toast-info.svg';
import { ReactComponent as ToastSuccess } from '../../assets/icons/toast-success.svg';
import { ReactComponent as ToastWarning } from '../../assets/icons/toast-warning.svg';
import './genericToast.scss';

const GenericToast = ({ type, title, text }) => {
  let iconComponent;
  switch (type) {
    case 'success':
      iconComponent = <ToastSuccess />;
      break;

    case 'warning':
      iconComponent = <ToastWarning />;
      break;

    case 'info':
      iconComponent = <ToastInfo />;
      break;

    default:
      iconComponent = <ToastInfo />;
      break;
  }

  return (
    <div className='generic-toast'>
      <div className='generic-toast__icon'>{iconComponent}</div>
      <div className='generic-toast__message'>
        {title && <div className='generic-toast__title'>{title}</div>}
        {text && <div className='generic-toast__text'>{text}</div>}
      </div>
    </div>
  );
};

export default GenericToast;
