import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AnalysisSegment } from '../../../../../utils/analysis';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import { useStudent } from '../../../../../utils/contexts/StudentContext';
import {
  getStudentInterventions,
  validateInterventions
} from '../../../../../utils/crud/student';
import uuidv4 from '../../../../../utils/helpers/uuidv4';
import useAnalytics from '../../../../../utils/hooks/useAnalytics';

const useStudentDetailsInterventions = () => {
  const { selectedCourse } = useCourse();
  const { trackEvent } = useAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStudentInterventions, setIsLoadingStudentInterventions] =
    useState(true);
  const [showLessonDetails, setShowLessonDetails] = useState(false);
  const [interventionsData, setInterventionsData] = useState([]);
  const { studentGuid } = useParams();
  const {
    student,
    isLoadingStudent,
    studentInterventions,
    setStudentInterventions
  } = useStudent();
  const navigate = useNavigate();

  // Handle lesson details
  const handleGoToLessonDetails = (lessonGuid) => {
    navigate(
      `/${selectedCourse?.guid}/students/${studentGuid}/lesson/${lessonGuid}`,
      {
        replace: true
      }
    );
  };

  const handleInterventionValidation = async ({
    kcs = [],
    interventionGuid,
    learningOutcomeGuid,
    learningOutcomeName,
    validationStatus,
    source
  }) => {
    await validateInterventions({
      courseGuid: selectedCourse?.guid,
      studentGuid,
      validationData: [
        {
          validation: validationStatus,
          learningOutcomeGuid
        }
      ]
    });

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_LO_Validated, {
      student_id: studentGuid,
      lo_id: learningOutcomeGuid,
      lo_name: learningOutcomeName,
      kc_id: kcs[0]?.guid,
      kc_name: kcs[0]?.name,
      validation_info: validationStatus,
      source: 'Interventions'
    });

    const updatedInterventions = studentInterventions.map((intervention) => {
      if (intervention.interventionGuid === interventionGuid) {
        return { ...intervention, intervention_validation: validationStatus };
      }
      return intervention;
    });

    setStudentInterventions(updatedInterventions);
  };

  // Get data
  const getData = async ({ courseGuid, studentGuid }) => {
    setIsLoadingStudentInterventions(true);

    const tempData = await getStudentInterventions({
      courseGuid,
      studentGuid
    });

    const apiData = tempData || [];
    const los = apiData?.los || [];
    los.forEach((lo) => {
      lo.interventionGuid = uuidv4();
    });
    console.log('los', los);
    setInterventionsData(los);
    setStudentInterventions(los);
    setIsLoadingStudentInterventions(false);

    const hasInterventions = tempData?.length > 0;

    // set a timeout to avoid race condition with Student_Profile_Button_Clicked event
    setTimeout(() => {
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_Profile_Viewed, {
        student_id: studentGuid,
        needs_help: hasInterventions,
        source: searchParams.get('source')
      });

      if (searchParams.get('source')) {
        // Remove source from search params
        searchParams.delete('source');
        setSearchParams(searchParams);
      }
    }, 100);
  };

  // Get initial data
  useEffect(() => {
    if (searchParams.get('source')) {
      trackEvent(
        AnalysisSegment.SEGMENT_EVENTS.Student_Profile_Button_Clicked,
        {
          student_id: studentGuid,
          source: searchParams.get('source')
        }
      );
    }

    if (!selectedCourse?.guid || !studentGuid) return;

    getData({
      courseGuid: selectedCourse?.guid,
      studentGuid
    });
  }, [selectedCourse?.guid, studentGuid]);

  useEffect(() => {
    setIsLoading(isLoadingStudentInterventions || isLoadingStudent);
  }, [isLoadingStudentInterventions, isLoadingStudent]);

  return {
    isLoading,
    interventionsData,
    showLessonDetails,
    studentHasActivity: student?.has_activity,
    setShowLessonDetails,
    handleGoToLessonDetails,
    handleInterventionValidation
  };
};

export default useStudentDetailsInterventions;
