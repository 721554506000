import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StudentDetailsInterventionsLayout = ({ isLoading, children }) => {
  const { t } = useTranslation();

  return (
    <div className='student-details-interventions'>
      <div className='student-details-interventions__content'>
        <div className='student-details-interventions__title'>
          {t('interventions_title')}
        </div>
        <div className='student-details-interventions__subtitle'>
          {t('interventions_subtitle')}{' '}
        </div>

        <div className='student-details-interventions__list'>
          {isLoading ? (
            <div className='student-details-interventions__loading'>
              <CircularProgress />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentDetailsInterventionsLayout;
