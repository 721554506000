import './TDHeaderButton.scss';
const TDHeaderButton = ({
  children,
  onClick,
  disabled,
  small,
  border,
  icon,
  className,
  selected,
  transparent,
  large
}) => {
  const classes = [
    'td-header-button',
    className,
    selected ? 'td-header-button--selected' : '',
    disabled ? 'td-header-button--disabled' : 'td-header-button--enabled',
    small ? 'td-header-button--size-small' : 'td-header-button--size-large',
    large ? 'td-header-button--size-large' : '',
    border
      ? 'td-header-button--border-default'
      : 'td-header-button--border-none',
    transparent ? 'td-header-button--transparent' : '',
    !children && icon ? 'td-header-button--icon-only' : ''
  ];

  const optionsClasses = classes.join(' ');

  return (
    <div className={optionsClasses} onClick={onClick}>
      {children && <div className='td-header-button__text'>{children}</div>}
      {icon && (
        <div className='td-header-button__icon' role='img'>
          {icon}
        </div>
      )}
    </div>
  );
};

export default TDHeaderButton;
