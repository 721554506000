import { CircularProgress, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../../../../../assets/icons';
import { ReactComponent as ClockIcon } from '../../../../../../assets/icons/clock_sm.svg';
import { ReactComponent as FlagIcon } from '../../../../../../assets/icons/flag.svg';
import { ReactComponent as AbandonedIcon } from '../../../../../../assets/icons/hand.svg';
import { ReactComponent as AlertIcon } from '../../../../../../assets/icons/help_sm.svg';
import { ReactComponent as PencilIcon } from '../../../../../../assets/icons/pencil_sm.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/icons/thumb-down.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../assets/icons/thumb-up.svg';
import MathRenderer from '../../../../../../atoms/MathRenderer/MathRenderer';
import TDTooltip from '../../../../../../atoms/TDTooltip/TDTooltip';
import {
  TDHeaderButton,
  TDTypography,
  TDWarning
} from '../../../../../../atoms/TDUILib';
import { formatTimeFromSeconds } from '../../../../../../utils/helpers/timeFormatter';
import './lessonDetailsListItem.scss';
import useLessonDetailsListItem from './useLessonDetailsListItem';

const LessonDetailsListItem = ({
  loData,
  interventionGuid,
  onShowActivityDialogClick,
  isFirstLOWithInterventions
}) => {
  const { t } = useTranslation();

  const {
    interventionFeedback,
    loAttemptsData,
    loTotalTime,
    loTotalActivities,
    isLoading,
    isLOFlagged,
    isAccordionOpen,
    onToggleAccordion,
    onInterventionFeedback
  } = useLessonDetailsListItem({
    interventionGuid,
    currentLoGuid: loData?.guid,
    loName: loData?.description,
    hasIntervention: loData?.hasIntervention,
    KCGuid: loData?.kcs[0]?.guid,
    KCName: loData?.kcs[0]?.name,
    isFirstLOWithInterventions,
    loInterventionFeedback: loData?.interventionFeedback
  });

  const getStatusIconAndTooltip = (status) => {
    switch (status) {
      case 'success':
        return {
          icon: <SuccessIcon />,
          tooltip: t(
            'student_details_attempt_status_message_tooltip_icon_green'
          )
        };
      case 'abandoned':
        return {
          icon: <AbandonedIcon />,
          tooltip: t(
            'student_details_attempt_status_message_tooltip_icon_orange'
          )
        };
      case 'error':
        return {
          icon: <ErrorIcon />,
          tooltip: t('student_details_attempt_status_message_tooltip_icon_red')
        };
      default:
        return { icon: null, tooltip: '' };
    }
  };

  return (
    <div
      className={`lesson-details-list-item ${
        isLOFlagged ? 'lesson-details-list-item--needs-intervention' : ''
      }`}
    >
      <div className='lesson-details-list-item__accordion'>
        <details
          className={loData.hasIntervention ? 'has-alert' : ''}
          open={isAccordionOpen}
        >
          <summary
            className={loData.hasIntervention ? 'has-alert' : ''}
            onClick={(e) => {
              e.preventDefault();
              onToggleAccordion();
            }}
          >
            <div className='lesson-details-list-item__summary-content'>
              {loData?.hasIntervention && <AlertIcon />}
              {loData?.description || loData?.guid || '-'}
            </div>
          </summary>
          <div className='lesson-details-list-item__accordion-content'>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {loData.interventionFeedback === 'pending' && (
                  <TDWarning
                    showExclamationIcon={false}
                    fullWidth
                    className='lesson-details-list-item__feedback-container'
                  >
                    {interventionFeedback === 'pending' && (
                      <div className='lesson-details-list-item__feedback-question'>
                        <div className='lesson-details-list-item__feedback-question-text'>
                          <TDTypography variant='body-m'>
                            {t('interventions_feedback_question')}
                          </TDTypography>
                        </div>
                        <div className='lesson-details-list-item__feedback-buttons'>
                          <TDHeaderButton
                            border
                            icon={<ThumbsUpIcon />}
                            onClick={() => {
                              onInterventionFeedback({
                                validationStatus: 'ok'
                              });
                            }}
                          />
                          <TDHeaderButton
                            border
                            icon={<ThumbsDownIcon />}
                            onClick={() => {
                              onInterventionFeedback({
                                validationStatus: 'not-ok'
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {interventionFeedback === 'ok' && (
                      <div className='lesson-details-list-item__feedback-question'>
                        <div className='lesson-details-list-item__feedback-text-success'>
                          <TDTypography variant='body-m'>
                            {t('interventions_feedback_yes_feedback_message')}
                          </TDTypography>
                          <ThumbsUpIcon />
                        </div>
                      </div>
                    )}
                    {interventionFeedback === 'not-ok' && (
                      <div className='lesson-details-list-item__feedback-question'>
                        <div className='lesson-details-list-item__feedback-text-error'>
                          <TDTypography variant='body-m'>
                            {t('interventions_feedback_no_feedback_message')}
                          </TDTypography>
                          <ThumbsDownIcon />
                        </div>
                      </div>
                    )}
                  </TDWarning>
                )}
                <div className='lesson-details-list-item__table'>
                  <div className='lesson-details-list-item__header'>
                    <div className='lesson-details-list-item__header-title'>
                      {t('student_details_engagement')}
                    </div>
                    <div className='lesson-details-list-item__header-time'>
                      <ClockIcon />
                      {loTotalTime[0] > 0 && (
                        <>
                          <span>{loTotalTime[0]}</span>h
                        </>
                      )}
                      <span>{loTotalTime[1]}</span>m
                      <span>{loTotalTime[2]}</span>s
                    </div>
                    <div className='lesson-details-list-item__header-attempts'>
                      <PencilIcon />
                      <span>{loTotalActivities}</span>
                    </div>
                  </div>
                  <div className='lesson-details-list-item__attempts'>
                    {loAttemptsData?.map((item, index) => {
                      const { icon, tooltip } = getStatusIconAndTooltip(
                        item.activityState
                      );
                      return (
                        <div
                          className='lesson-details-list-item__row'
                          key={`${item.formattedDate}-${index}`}
                        >
                          <Tooltip title={tooltip}>
                            <div className='lesson-details-list-item__row-icon'>
                              {icon}
                            </div>
                          </Tooltip>
                          <div className='lesson-details-list-item__row-stimulus'>
                            <MathRenderer htmlContent={item?.stimulus} />
                          </div>
                          <div className='lesson-details-list-item__row-date'>
                            {item?.formattedDate}
                          </div>
                          <div className='lesson-details-list-item__row-time'>
                            {item?.formattedTime}
                          </div>
                          <div className='lesson-details-list-item__row-elapsed-time'>
                            {formatTimeFromSeconds(
                              item?.elapsedTimeInSeconds || 0
                            )}
                            {item?.elapsedTimeInSeconds <= 2 && (
                              <TDTooltip
                                mode='hover'
                                text={t('reports_message_warning_seconds')}
                                placement='bottom'
                              >
                                <FlagIcon className='lesson-details-list-item__row-elapsed-time-flagged-icon' />
                              </TDTooltip>
                            )}
                          </div>
                          <div className='lesson-details-list-item__row-button'>
                            <div
                              className='student-details__button student-details__button--text'
                              onClick={() => {
                                onShowActivityDialogClick({
                                  loAttemptsData: loAttemptsData,
                                  attemptIndex: index
                                });
                              }}
                            >
                              {t('student_details_view_activity')}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </details>
      </div>
    </div>
  );
};

export default LessonDetailsListItem;
