import { useTranslation } from 'react-i18next';
import { ReactComponent as VisibilityOff } from '../../../../../assets/icons/eye-off.svg';
import { ReactComponent as Visibility } from '../../../../../assets/icons/eye.svg';
import './headerCellPassword.scss';

const HeaderCellPassword = ({
  togglePasswordVisibility,
  isPasswordVisible
}) => {
  const { t } = useTranslation();

  return (
    <div className='header-cell-password'>
      <span className='header-cell-password__text'>{t('password')}</span>
      <span
        className='header-cell-password__icon'
        onClick={togglePasswordVisibility}
      >
        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
      </span>
    </div>
  );
};

export default HeaderCellPassword;
