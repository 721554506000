import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import { TDHeaderButton } from '../TDUILib';
import './TDDialog.scss';

const TDDialog = ({
  onClose = () => null,
  isOpen,
  title,
  children,
  fullHeight,
  size,
  allowOverflow,
  showCloseButton,
  footer,
  className
}) => {
  const classes = [
    'td-dialog',
    fullHeight
      ? 'td-dialog--full-height'
      : allowOverflow
      ? 'td-dialog__content--allow-overflow'
      : 'td-dialog--auto-height',
    size ? `td-dialog--${size}` : '',
    className ? className : ''
  ].filter(Boolean);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogBackdrop transition className='td-dialog__backdrop' />
      <DialogPanel className={classes.join(' ')}>
        <div className='td-dialog__title'>
          <div className='td-dialog__title-content'>{title}</div>
          {showCloseButton && (
            <>
              <div className='td-dialog__title-close-separator' />
              <TDHeaderButton
                transparent
                large
                icon={<CloseIcon />}
                onClick={onClose}
              />
            </>
          )}
        </div>
        <div className='td-dialog__content'>{children}</div>
        {footer && <div className='td-dialog__footer'>{footer}</div>}
      </DialogPanel>
    </Dialog>
  );
};

export default TDDialog;
