import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';

import BackgroundImage from '../../../../../../assets/images/login_sheets_bg.png';

Font.register({
  family: 'Poppins',
  fonts: [
    { src: '/assets/fonts/Poppins-Medium.ttf', fontWeight: '500' },
    { src: '/assets/fonts/Poppins-Bold.ttf', fontWeight: 'bold' }
  ]
});

const ZOOM_RATIO = 0.75;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: '500'
  },
  card: {
    transform: `scale(${ZOOM_RATIO})`,
    height: 600,
    width: 600,
    border: '1px solid #4D586C',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20
  },
  pageBackground: {
    position: 'absolute',
    display: 'block',
    height: '100%',
    width: '100%'
  },
  header: {
    width: '100%',
    height: 74,
    marginTop: 36,
    justifyContent: 'center'
  },
  box: {
    width: 392,
    borderRadius: '12px',
    padding: 16,
    backgroundColor: '#ffffff',
    border: '2px solid #DFE5EC',
    gap: 20
  },
  input: {
    width: 360,
    borderRadius: 12,
    border: '2px solid #DFE5EC',
    marginTop: 8,
    color: '#4D586C',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    fontSize: 16,
    fontWeight: 'bold'
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
    color: '#4D586C',
    textAlign: 'center'
  },
  class: {
    fontSize: 16,
    fontWeight: '500',
    color: '#939DB0',
    textAlign: 'center',
    marginTop: 8
  },
  nameBox: {
    width: 392,
    borderRadius: 12,
    padding: 8,
    backgroundColor: '#ffffff',
    border: '2px solid #DFE5EC',
    justifyContent: 'center',
    alignItems: 'center'
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#303949'
  },
  label: {
    fontSize: 12,
    fontWeight: '500',
    color: '#939DB0'
  }
});

const LoginSheetsPDF = ({ users, translations }) => {
  return (
    <Document>
      {users.map((user, index) => (
        <Page key={index} size='A4' style={styles.page}>
          <View style={styles.card}>
            <Image cache src={BackgroundImage} style={styles.pageBackground} />

            <View style={styles.header}>
              <Text style={styles.title}>{translations.title}</Text>
              <Text style={styles.class}>{user.group_name}</Text>
            </View>
            <View style={styles.nameBox}>
              <Text style={styles.name}>{user.name + ' ' + user.lastname}</Text>
            </View>

            <View style={styles.box}>
              <View>
                <Text style={styles.label}>{translations.username}</Text>

                <View style={styles.input}>
                  <Text>{user.username}</Text>
                </View>
              </View>
              <View>
                <Text style={styles.label}>{translations.password}</Text>
                <View style={styles.input}>
                  <Text>{user.password}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default LoginSheetsPDF;
