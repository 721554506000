import { usePostHog } from 'posthog-js/react';
import { useCallback, useMemo } from 'react';
import { AnalysisSegment } from '../analysis';
import { useCourse } from '../contexts/CourseContext';

const useAnalytics = () => {
  const { selectedCourse } = useCourse();
  const ph = usePostHog();

  const baseEventProperties = useMemo(() => {
    if (selectedCourse) {
      return {
        class: selectedCourse.guid,
        class_name: selectedCourse.name,
        program_id: selectedCourse.program_guid,
        students: selectedCourse.users?.length
      };
    }
    return {};
  }, [selectedCourse]);

  const trackEvent = useCallback(
    (eventName, additionalProperties = {}) => {
      trackRawEvent(eventName, {
        ...baseEventProperties,
        ...additionalProperties
      });
    },
    [baseEventProperties]
  );

  const trackRawEvent = useCallback((eventName, additionalProperties = {}) => {
    AnalysisSegment.sendSegmentTrackEvent(eventName, additionalProperties);
  }, []);

  const resetAnalytics = useCallback(() => {
    ph.reset();
    analytics.reset();
  }, [ph]);

  return {
    trackEvent,
    trackRawEvent,
    resetAnalytics
  };
};

export default useAnalytics;
