import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../../../_practice-components';
import { ReactComponent as FlagIcon } from '../../../../../../assets/icons/flag.svg';
import { ReactComponent as AbandonedIcon } from '../../../../../../assets/icons/hand.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../../../../assets/icons/solid/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../../assets/icons/solid/chevron-right.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/icons/thumb-down.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../assets/icons/thumb-up.svg';
import TDButton from '../../../../../../atoms/TDButton/TDButton';
import TDDialog from '../../../../../../atoms/TDDialog/TDDialog';
import TDHeaderButton from '../../../../../../atoms/TDHeaderButton/TDHeaderButton';
import { formatTimeFromSeconds } from '../../../../../../utils/helpers/timeFormatter';
import useAuth from '../../../../../../utils/hooks/useAuth';
import useUserSettings from '../../../../../../utils/hooks/useUserSettings';
import './lessonDetailsActivityDialog.scss';

const LessonDetailsActivityDialog = ({
  isOpen,
  onClose,
  selectedLO,
  loAttemptsData,
  startAttemptIndex
}) => {
  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();

  const { getPreviewLanguageSettings } = useUserSettings();
  const [activityLanguage, setActivityLanguage] = useState(null);

  const [showSolution, setShowSolution] = useState(false);
  const [currentAttemptIndex, setCurrentAttemptIndex] =
    useState(startAttemptIndex);

  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };

  useEffect(() => {
    setCurrentAttemptIndex(startAttemptIndex);
    setActivityLanguage(getPreviewLanguageSettings());
  }, []);

  useEffect(() => {
    setCurrentAttemptIndex(startAttemptIndex);

    if (!isOpen) {
      setCurrentAttemptIndex(0);
      setShowSolution(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setShowSolution(false);
  }, [currentAttemptIndex]);

  const getStatusIconAndTooltip = (status) => {
    switch (status) {
      case 'success':
        return {
          icon: <SuccessIcon />,
          tooltip: t(
            'student_details_attempt_status_message_tooltip_icon_green'
          )
        };
      case 'abandoned':
        return {
          icon: <AbandonedIcon />,
          tooltip: t(
            'student_details_attempt_status_message_tooltip_icon_orange'
          )
        };
      case 'error':
        return {
          icon: <ErrorIcon />,
          tooltip: t('student_details_attempt_status_message_tooltip_icon_red')
        };
      default:
        return { icon: null, tooltip: '' };
    }
  };

  const LessonDetailsHeader = () => {
    return (
      <div className='lesson-details-header'>
        <div className='lesson-details-header__title'>
          {selectedLO?.description}
        </div>
        <div className='lesson-details-header__counter'>
          <ChevronRightIcon />
          {t('activity')} {currentAttemptIndex + 1}/
          {selectedLO?.number_of_activities}
        </div>
      </div>
    );
  };

  const LessonDetailsSubheader = () => {
    if (
      !loAttemptsData ||
      currentAttemptIndex === null ||
      currentAttemptIndex === undefined
    ) {
      return null;
    }

    const { icon, tooltip } = getStatusIconAndTooltip(
      loAttemptsData[currentAttemptIndex]?.activityState
    );
    return (
      <div className='lesson-details-subheader'>
        <div className='lesson-details-subheader__icon'>
          <Tooltip title={tooltip}>
            <div>{icon}</div>
          </Tooltip>
        </div>
        <div className='lesson-details-subheader__info'>
          <div className='lesson-details-subheader__date'>
            {loAttemptsData[currentAttemptIndex]?.formattedDate}
          </div>
          <div className='lesson-details-subheader__time'>
            {loAttemptsData[currentAttemptIndex]?.formattedTime}
          </div>
          <div className='lesson-details-subheader__elapsed-time'>
            {formatTimeFromSeconds(
              loAttemptsData[currentAttemptIndex]?.elapsedTimeInSeconds || 0
            )}
            {loAttemptsData[currentAttemptIndex]?.elapsedTimeInSeconds <= 2 && (
              <Tooltip title={t('reports_message_warning_seconds')}>
                <div>
                  <FlagIcon
                    style={{
                      color: 'red'
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  const LessonDetailsActions = () => {
    if (
      !loAttemptsData ||
      currentAttemptIndex === null ||
      currentAttemptIndex === undefined
    ) {
      return null;
    }
    return (
      <div className='lesson-details-actions'>
        <div className='report-user-detail-activity-preview-solution'>
          {showSolution ? (
            <TDButton
              variant='solid'
              color='primary'
              onClick={() => toggleSolution()}
            >
              {t('See answer')}
            </TDButton>
          ) : (
            <TDButton
              variant='outline'
              color='primary'
              onClick={() => toggleSolution()}
            >
              {t('See solution')}
            </TDButton>
          )}
        </div>
      </div>
    );
  };

  return (
    <TDDialog
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true}
      size={'large'}
      title={<LessonDetailsHeader />}
    >
      <div className='lesson-details-activity-dialog'>
        <div className='lesson-details-activity-dialog-container'>
          <div className='lesson-details-activity-dialog__navigation'>
            <TDHeaderButton
              icon={<ChevronLeftIcon />}
              border
              small
              onClick={() => setCurrentAttemptIndex(currentAttemptIndex - 1)}
              disabled={currentAttemptIndex <= 0}
            />
          </div>
          <div className='lesson-details-activity-dialog__content'>
            <LessonDetailsSubheader />
            <div className='lesson-details-activity-dialog__activity'>
              {activityLanguage &&
                loAttemptsData &&
                currentAttemptIndex !== null &&
                currentAttemptIndex !== undefined && (
                  <ActivityBlueberryComponent
                    activityType={ACTIVITY_TYPES.QUIZ}
                    activity={loAttemptsData[currentAttemptIndex]?.questionData}
                    showSolutionQuestionQuizPreview={showSolution}
                    disabledQuestion={true}
                    isHideFooter={true}
                    studentResponse={
                      loAttemptsData[currentAttemptIndex]?.questionResponse
                    }
                    showAllFeedback={false}
                    mathRenderEngine='katex'
                    pathAssetsSchool={schoolAssetsPath}
                    uiLanguage={activityLanguage}
                  />
                )}
            </div>
          </div>

          <div className='lesson-details-activity-dialog__navigation'>
            <TDHeaderButton
              icon={<ChevronRightIcon />}
              border
              small
              onClick={() => setCurrentAttemptIndex(currentAttemptIndex + 1)}
              disabled={currentAttemptIndex >= loAttemptsData?.length - 1}
            />
          </div>
        </div>

        <div className='lesson-details-activity-dialog__actions'>
          <LessonDetailsActions />
        </div>
      </div>
    </TDDialog>
  );
};

export default LessonDetailsActivityDialog;
