import InterventionsListEmptyCase from './interventionsListEmptyCase/InterventionsListEmptyCase';
import InterventionsListEmptyCaseWithoutActivity from './interventionsListEmptyCase/InterventionsListEmptyCaseWithoutActivity';
import InterventionsListItem from './interventionsListItem/InterventionsListItem';
import './studentDetailsInterventions.scss';
import StudentDetailsInterventionsLayout from './StudentDetailsInterventionsLayout';
import useStudentDetailsInterventions from './useStudentDetailsInterventions';

const StudentDetailsInterventions = ({ isLoading }) => {
  const {
    interventionsData,
    studentHasActivity,
    isLoading: isLoadingData,
    handleGoToLessonDetails,
    handleInterventionValidation
  } = useStudentDetailsInterventions();

  return (
    <StudentDetailsInterventionsLayout isLoading={isLoading || isLoadingData}>
      {!studentHasActivity && <InterventionsListEmptyCaseWithoutActivity />}
      {studentHasActivity && interventionsData?.length === 0 && (
        <InterventionsListEmptyCase />
      )}
      {studentHasActivity &&
        interventionsData?.length > 0 &&
        interventionsData?.map((intervention, index) => (
          <InterventionsListItem
            kcs={intervention?.kcs}
            unit={intervention?.unit?.name}
            lesson={intervention?.lesson?.name}
            lessonGuid={intervention?.lesson?.guid}
            blockedSince={intervention.last_student_attempt}
            interventionGuid={intervention?.interventionGuid}
            name={intervention?.learning_outcome?.description}
            validationStatus={intervention?.intervention_validation}
            learningOutcomeGuid={intervention?.learning_outcome?.guid}
            wasInterventionViewed={intervention?.was_intervention_viewed}
            key={`${intervention?.unit?.guid}-${intervention?.lesson?.guid}-${index}`}
            handleGoToLessonDetails={handleGoToLessonDetails}
            handleInterventionValidation={handleInterventionValidation}
          />
        ))}
    </StudentDetailsInterventionsLayout>
  );
};

export default StudentDetailsInterventions;
