import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AnalysisSegment } from '../../../utils/analysis';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import useAnalytics from '../../../utils/hooks/useAnalytics';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';

const useContentsPage = () => {
  const { pathname } = useLocation();
  const { selectedCourse } = useContext(CourseContext);
  const { t } = useTranslation();
  const { getItem } = useLocalStorage();
  const { trackEvent } = useAnalytics();

  const getHref = (path, key) => {
    let filter = getItem(path);

    if (filter) {
      try {
        filter = JSON.parse(filter);
      } catch (e) {
        filter = {};
      }
      return `${path}?${key}=${filter[key]}`;
    }
    return path;
  };

  const UNIT_TAB = {
    name: t('units'),
    href: `/${selectedCourse?.guid}/content/units`,
    link: `/${selectedCourse?.guid}/content/units`,
    filter: 'unit',
    testId: 'content-units-tab'
  };

  const STANDARD_TAB = {
    name: t('Standards'),
    href: `/${selectedCourse?.guid}/content/standards`,
    link: `/${selectedCourse?.guid}/content/standards`,
    filter: 'standard',
    testId: 'content-standards-tab'
  };

  const [currentTab, setCurrentTab] = useState(UNIT_TAB.name);
  const [tabs, setTabs] = useState([]);
  const [isWaitingForTabManagement, setIsWaitingForTabManagement] =
    useState(true);

  useEffect(() => {
    if (!selectedCourse?.guid && !isWaitingForTabManagement) return;

    setTabs([]);
    // setSearchParams({});
    setIsWaitingForTabManagement(true);
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse) return;
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Content_Page_Viewed, {
      class: selectedCourse?.guid,
      program_id: selectedCourse?.program_guid,
      students: selectedCourse?.users?.length
    });
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse?.guid) return;

    let unitTab = {
      ...UNIT_TAB,
      href: getHref(`/${selectedCourse?.guid}/content/units`, 'unit')
    };
    let standardTab = {
      ...STANDARD_TAB,
      href: getHref(`/${selectedCourse?.guid}/content/standards`, 'standard')
    };

    if (selectedCourse.standard_blueberry === 1) {
      setTabs([unitTab, standardTab]);
    } else {
      setTabs([unitTab]);
    }

    if (isWaitingForTabManagement) {
      setIsWaitingForTabManagement(false);
    }
  }, [selectedCourse, currentTab, pathname]);

  const onChangeTab = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  return {
    tabs,
    currentTab,
    onChangeTab
  };
};

export default useContentsPage;
