import { useTranslation } from 'react-i18next';
import { HelpIcon } from '../../../../../assets/icons';
import { TDTypography } from '../../../../../atoms/TDUILib';
import './studentsAnalysisTooltip.scss';

const StudentsAnalysisTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className='students-analysis-tooltip-container'>
      <div>
        <TDTypography variant='body-m' fontWeight='bold'>
          {t('page_tooltip.identify_students_progress_and_difficulties')}
        </TDTypography>
      </div>
      <div className='students-analysis-tooltip-item-container'>
        <TDTypography variant='body-m' fontWeight='medium'>
          {t('page_tooltip.students_with_alert')}
        </TDTypography>
        <HelpIcon />
        <TDTypography>
          {t('page_tooltip.students_with_alert_info')}
        </TDTypography>
      </div>
      <div className='students-analysis-tooltip-item-separator' />
      <div className='students-analysis-tooltip-item-container'>
        <TDTypography variant='body-m' fontWeight='semibold'>
          {t('page_tooltip.content_states')}
        </TDTypography>
      </div>
      <div className='students-analysis-tooltip-item-container'>
        <TDTypography variant='body-m' fontWeight='medium'>
          <TDTypography variant='body-m' fontWeight='semibold' as='span'>
            {t('page_tooltip.has_difficulties')}
          </TDTypography>
          {t('page_tooltip.difficulties_info')}
        </TDTypography>
      </div>
      <div className='students-analysis-tooltip-item-container'>
        <TDTypography variant='body-m' fontWeight='medium'>
          <TDTypography variant='body-m' fontWeight='semibold' as='span'>
            {t('page_tooltip.content_mastered')}
          </TDTypography>
          {t('page_tooltip.mastered_info')}
        </TDTypography>
      </div>
      <div className='students-analysis-tooltip-item-container'>
        <TDTypography variant='body-m' fontWeight='medium'>
          <TDTypography variant='body-m' fontWeight='semibold' as='span'>
            {t('page_tooltip.content_forgotten')}
          </TDTypography>
          {t('page_tooltip.forgotten_info')}
        </TDTypography>
      </div>
    </div>
  );
};

export default StudentsAnalysisTooltip;
