import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import StudentDetailsMenu from '../studentDetailsMenu/StudentDetailsMenu';
import './studentDetailsSidebar.scss';

const StudentDetailsSidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const collapseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div
      className={`student-details__sidebar ${
        isSidebarOpen
          ? 'student-details__sidebar--open'
          : 'student-details__sidebar--closed'
      }`}
    >
      <div className='student-details__sidebar-container'>
        {isSidebarOpen && (
          <div
            className='student-details__button student-details__sidebar-collapse-button'
            onClick={collapseSidebar}
          >
            <KeyboardDoubleArrowLeft />
          </div>
        )}
        <StudentDetailsMenu />
      </div>
    </div>
  );
};

export default StudentDetailsSidebar;
