import './playQuiz.scss';
import usePlayQuiz from './usePlayQuiz';

import { ThemeProvider } from '@emotion/react';
import ReactHowler from 'react-howler';
import { useTranslation } from 'react-i18next';
import QuizBlockedByActiveSessionDialog from '../components/QuizBlockedByActiveSessionDialog';
import quizTheme from '../components/QuizTheme';
import GameLoading from './components/gameLoading/GameLoading';
import PlayQuizCountdown from './playQuizCountdown/PlayQuizCountdown';
import PlayQuizLobby from './playQuizLobby/PlayQuizLobby';
import PlayQuizPodium from './playQuizPodium/PlayQuizPodium';
import PlayQuizQuestion from './playQuizQuestion/PlayQuizQuestion';
import PlayQuizRanking from './playQuizRanking/PlayQuizRanking';

const PlayQuiz = () => {
  const { t } = useTranslation();

  const {
    quizGuid,
    quizName,
    isLoading,
    totalPlayers,
    playerList,
    setPlayerList,
    questions,
    currentQuestion,
    setCurrentQuestion,
    numberOfQuestions,
    currentGameView,
    setSeconds,
    setTimerId,
    playSoundEffect,
    soundFile,
    howlerFXRef,
    isPlaying,
    EFFECTS_VOLUME,
    setIsPlaying,
    isLoop,

    correctAnswerUserGuids,
    totalAnswers,

    gotoLobby,
    gotoCountdown,
    gotoQuestionView,
    gotoRanking,
    gotoPodium,
    sendShowSolution,
    GAME_VIEW,
    seconds,
    syncTimestamp,
    currentGameGuid,
    closeGame,

    isActiveSessionBlockDialogOpen,
    onCloseActiveSessionBlockDialog
  } = usePlayQuiz();

  return (
    <div className='play-quiz-page'>
      <div style={{ display: 'none' }}>
        {soundFile && (
          <ReactHowler
            ref={howlerFXRef}
            src={soundFile}
            playing={isPlaying}
            volume={EFFECTS_VOLUME}
            loop={isLoop}
            onPlay={() => {}}
            onLoad={() => setIsPlaying(true)}
            onEnd={() => !isLoop && setIsPlaying(false)}
            onLoadError={() => console.error('Sound FX load error')}
            onPlayError={(id, errorCode) =>
              console.error('Music play error: ', errorCode)
            }
          />
        )}
      </div>
      {isLoading ? (
        <GameLoading />
      ) : (
        <div className='play-quiz-page__content'>
          {currentGameView === GAME_VIEW.LOBBY_WAITING && (
            <PlayQuizLobby
              quizGuid={quizGuid}
              quizName={quizName}
              totalPlayers={totalPlayers}
              playerList={playerList}
              setPlayerList={setPlayerList}
              gotoCountdown={gotoCountdown}
              playSoundEffect={playSoundEffect}
              currentGameGuid={currentGameGuid}
              closeGame={closeGame}
            />
          )}
          {currentGameView === GAME_VIEW.QUESTION_COUNTDOWN && (
            <PlayQuizCountdown
              quizGuid={quizGuid}
              quizName={quizName}
              gotoQuestionView={gotoQuestionView}
              playSoundEffect={playSoundEffect}
              closeGame={closeGame}
            />
          )}
          {currentGameView === GAME_VIEW.QUESTION_PLAYING && (
            <PlayQuizQuestion
              quizGuid={quizGuid}
              questions={questions}
              totalPlayers={totalPlayers}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              numberOfQuestions={numberOfQuestions}
              gotoRanking={gotoRanking}
              sendShowSolution={sendShowSolution}
              playerList={playerList}
              setPlayerList={setPlayerList}
              correctAnswers={correctAnswerUserGuids}
              totalAnswers={totalAnswers}
              setSeconds={setSeconds}
              seconds={seconds}
              syncTimestamp={syncTimestamp}
              playSoundEffect={playSoundEffect}
              closeGame={closeGame}
            />
          )}

          {currentGameView === GAME_VIEW.QUESTION_RANKING && (
            <PlayQuizRanking
              quizGuid={quizGuid}
              quizName={quizName}
              totalAnswers={totalAnswers}
              totalPlayers={totalPlayers}
              playerList={playerList}
              setPlayerList={setPlayerList}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              numberOfQuestions={numberOfQuestions}
              gotoQuestionView={gotoQuestionView}
              gotoPodium={gotoPodium}
              playSoundEffect={playSoundEffect}
              closeGame={closeGame}
            />
          )}
          {currentGameView === GAME_VIEW.FINISHED_PODIUM && (
            <PlayQuizPodium
              quizGuid={quizGuid}
              quizName={quizName}
              playerList={playerList}
              setPlayerList={setPlayerList}
              closeGame={closeGame}
            />
          )}

          <ThemeProvider theme={quizTheme}>
            <QuizBlockedByActiveSessionDialog
              isOpen={isActiveSessionBlockDialogOpen}
              onClose={onCloseActiveSessionBlockDialog}
              title={t('quiz_blocked_by_active_session_title')}
              description={t('quiz_blocked_by_active_session_description')}
            />
          </ThemeProvider>
        </div>
      )}
    </div>
  );
};

export default PlayQuiz;
