import { MoreHoriz, PlayCircle } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  List,
  MenuItem,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCourse } from '../../utils/contexts/CourseContext';
import './QuizCard.scss';

const QuizCard = ({
  guid,
  lang,
  name = 'Quiz 1',
  quizGuidToDelete = '',
  isDeletingQuiz = false,
  numberOfActivities = 10,
  hasResults = false,
  onEditName = () => {},
  onDeleteQuiz = () => {},
  onPlayQuiz = () => {}
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedCourse } = useCourse();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handlePlayButtonClick = (event) => {
    event.stopPropagation();
    onPlayQuiz({ quizGuid: guid, hasResults });
  };

  const handleDetailsButtonClick = (event) => {
    navigate(`/${selectedCourse?.guid}/quizzes/${guid}`);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    onDeleteQuiz(guid);
  };

  const handleEditNameClick = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    onEditName({ quizGuid: guid });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='quiz-card'>
      <Card variant='outlined' onClick={handleDetailsButtonClick}>
        <CardHeader
          title={
            <Stack direction='row' alignItems='center' mb={'12px'}>
              <Typography variant='langTag' sx={{ marginRight: '12px' }}>
                {lang}
              </Typography>

              <Typography variant='subtitle1'>{`${numberOfActivities} ${t(
                'Activity',
                {
                  count: numberOfActivities
                }
              )}`}</Typography>
            </Stack>
          }
          subheader={<Typography variant='h2'>{name}</Typography>}
          action={
            <IconButton
              onClick={handleMenuClick}
              variant='more'
              disableRipple
              disabled={isDeletingQuiz && quizGuidToDelete === guid}
            >
              {isDeletingQuiz && quizGuidToDelete === guid ? (
                <CircularProgress size={20} />
              ) : (
                <MoreHoriz />
              )}
            </IconButton>
          }
        />

        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <List>
            <MenuItem
              disableRipple
              onClick={handleEditNameClick}
              sx={{ fontWeight: '700' }}
            >
              {t('edit_quiz_name_button')}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={handleDeleteClick}
              sx={{ fontWeight: '700', color: '#F02959' }}
            >
              {t('delete')}
            </MenuItem>
          </List>
        </Popover>

        <CardContent></CardContent>

        <CardActions>
          <Button
            variant='contained'
            disableElevation
            startIcon={<PlayCircle color='white' />}
            onClick={handlePlayButtonClick}
          >
            {t('Play')}
          </Button>

          <Button
            variant='text'
            disableElevation
            onClick={handleDetailsButtonClick}
          >
            {t('see_details')}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

QuizCard.propTypes = {
  numberOfActivities: PropTypes.number,
  name: PropTypes.string
};

export default QuizCard;
