import { Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import './QuizBadge.scss';

const QuizBadge = ({
  label = '',
  tooltip = '',
  badge = '',
  onDelete,
  fullWidth = false
}) => {
  return (
    <div className={`quiz-badge ${fullWidth ? 'quiz-badge--full-width' : ''}`}>
      <div className='quiz-badge__container'>
        <Tooltip title={tooltip}>
          <div className='quiz-badge__content'>
            {badge && (
              <span className='quiz-badge__badge'>{badge.split(' ')[0]}</span>
            )}
            <div className='quiz-badge__label'>{label}</div>
            {onDelete && (
              <div className='quiz-badge__delete' onClick={onDelete}>
                <Cancel />
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

QuizBadge.propTypes = {
  label: PropTypes.string,
  badge: PropTypes.string,
  tooltip: PropTypes.string,
  onDelete: PropTypes.func,
  fullWidth: PropTypes.bool
};

export default QuizBadge;
