import { createContext, useContext, useEffect, useState } from 'react';
import { getStudentDetails } from '../crud/student';
import generateAssetURL from '../helpers/generateAssetURL';
import useAuth from '../hooks/useAuth';
import { useCourse } from './CourseContext';

const StudentContext = createContext({
  student: undefined,
  studentGuid: undefined,
  isLoadingStudent: true,
  studentInterventions: undefined,
  setStudentGuid: () => {},
  setStudentInterventions: () => {}
});

const StudentProvider = ({ children }) => {
  const { schoolAssetsPath } = useAuth();
  const { selectedCourse } = useCourse();
  const [student, setStudent] = useState(undefined);
  const [studentGuid, setStudentGuid] = useState(undefined);
  const [isLoadingStudent, setIsLoadingStudent] = useState(true);
  const [studentInterventions, setStudentInterventions] = useState(undefined);

  const getStudentData = async () => {
    setIsLoadingStudent(true);
    const studentData = await getStudentDetails({
      studentGuid,
      courseGuid: selectedCourse?.guid
    });

    const DEFAULT_AVATAR = generateAssetURL({
      asset: 'default_single_avatar.png',
      schoolAssetsPath
    });

    setStudent({
      ...studentData,
      // avatar can be null or empty string, if so, use the default avatar
      avatar:
        studentData?.avatar && studentData?.avatar !== ''
          ? studentData?.avatar
          : DEFAULT_AVATAR,

      classname: selectedCourse?.school_group_name
    });

    setIsLoadingStudent(false);
  };

  useEffect(() => {
    if (!studentGuid || !selectedCourse?.guid) return;
    setStudentInterventions(undefined);
    getStudentData();
  }, [studentGuid, selectedCourse?.guid]);

  return (
    <StudentContext.Provider
      value={{
        student,
        studentGuid,
        isLoadingStudent,
        studentInterventions,
        setStudentGuid,
        setStudentInterventions
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};

// Hook para usar este contexto, el contexto no se puede exponer directamente, para eso está el hook
const useStudent = () => {
  const {
    student,
    isLoadingStudent,
    studentGuid,
    studentInterventions,
    setStudentGuid,
    setStudentInterventions
  } = useContext(StudentContext);

  return {
    student,
    studentGuid,
    isLoadingStudent,
    studentInterventions,
    setStudentGuid,
    setStudentInterventions
  };
};

export { StudentProvider, useStudent };
