import { PDFDownloadLink } from '@react-pdf/renderer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LoginSheetImage from '../../../../../../assets/images/login_sheets.png';
import GenericToast from '../../../../../../atoms/GenericToast/GenericToast';
import {
  TDButton,
  TDCheckbox,
  TDWarning
} from '../../../../../../atoms/TDUILib';
import './loginSheetsForm.scss';
import LoginSheetsPDF from './LoginSheetsPDF';

const LoginSheetsForm = ({ usersData, onCancel }) => {
  const { t } = useTranslation();
  const [showInstructions, setShowInstructions] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const onHideInstructions = () => {
    setShowInstructions(false);
  };

  const handleUserSelect = (user) => {
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser.guid === user.guid
    );

    // Updates the selection of users: removes the deselected user, adds the user if not selected
    const updatedSelection = isSelected
      ? selectedUsers.filter((selectedUser) => selectedUser.guid !== user.guid)
      : [...selectedUsers, user]; //

    setSelectedUsers(updatedSelection);

    const filteredUsers = usersData.filter(
      (user) => user.encryption_type === 'none'
    );
    const allSelected = updatedSelection.length === filteredUsers.length;
    const someSelected = updatedSelection.length > 0;

    setIsAllSelected(allSelected);
    setIsIndeterminate(someSelected && !allSelected);
  };

  const handleSelectAll = () => {
    const filteredUsers = usersData.filter(
      (user) => user.encryption_type === 'none'
    );
    const newSelection = !isAllSelected;

    setSelectedUsers(newSelection ? filteredUsers : []);
    setIsAllSelected(newSelection);
    setIsIndeterminate(false);
  };

  return (
    <div className='login-sheets-form'>
      {showInstructions ? (
        <div className='login-sheets-form__content'>
          <div className='login-sheets-form__image'>
            <img src={LoginSheetImage} alt='' />
          </div>
          <div className='login-sheets-form__title'>
            {t('students_management_login_sheets_instructions_title')}
          </div>
          <div className='login-sheets-form__text'>
            {t('students_management_login_sheets_instructions_text')}
          </div>
          <div className='login-sheets-form__warning'>
            <TDWarning>
              {t('students_management_login_sheets_warning')}
            </TDWarning>
          </div>
        </div>
      ) : (
        <div className='login-sheets-form__content'>
          <div className='login-sheets-form__text'>
            {t('students_management_login_sheets_select_students_title')}
          </div>
          <div className='login-sheets-form__selector'>
            <div className='login-sheets-form__selector-header'>
              <TDCheckbox
                name='select-all'
                checked={isAllSelected}
                onChange={handleSelectAll}
                indeterminate={isIndeterminate}
                label={t(
                  'students_management_login_sheets_select_students_all'
                )}
              />
            </div>
            <div className='login-sheets-form__selector-list'>
              {usersData
                .filter((user) => user.encryption_type === 'none')
                .map((user) => (
                  <div key={user.guid}>
                    <TDCheckbox
                      name={user.guid}
                      label={user.name + ' ' + user.lastname}
                      checked={selectedUsers.some(
                        (selectedUser) => selectedUser.guid === user.guid
                      )}
                      onChange={() => handleUserSelect(user)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      <div className='login-sheets-form__actions'>
        <TDButton onClick={onCancel} variant='transparent'>
          {t('cancel')}
        </TDButton>
        {showInstructions ? (
          <TDButton
            onClick={onHideInstructions}
            color='primary'
            variant='outline'
          >
            {t('students_management_login_sheets_select_students_button')}
          </TDButton>
        ) : (
          <PDFDownloadLink
            document={
              <LoginSheetsPDF
                users={selectedUsers}
                translations={{
                  title: t('students_management_login_sheets_pdf_title'),
                  username: t('students_management_username'),
                  password: t('students_management_password')
                }}
              />
            }
            fileName={
              t('students_management_login_sheets_pdf_filename') +
              '-' +
              selectedUsers[0]?.group_name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/\s+|\W/g, '') +
              '.pdf'
            }
          >
            {({ blob, url, loading, error }) => {
              if (error) {
                toast(
                  <GenericToast
                    type='warning'
                    title={t(
                      'students_management_login_sheets_pdf_error_title'
                    )}
                    text={t('students_management_login_sheets_pdf_error_info')}
                  />,
                  {
                    position: 'bottom-left',
                    autoClose: 4000,
                    hideProgressBar: true,
                    className: 'generic-toast--warning'
                  }
                );
              }
              return (
                <TDButton
                  color='primary'
                  variant='outline'
                  onClick={onCancel}
                  disabled={selectedUsers.length === 0 || loading}
                >
                  {t('students_management_login_sheets_print_pdf_button')}
                </TDButton>
              );
            }}
          </PDFDownloadLink>
        )}
      </div>
    </div>
  );
};

export default LoginSheetsForm;
