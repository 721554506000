import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AnalysisSegment } from '../../../../../../utils/analysis';
import { useCourse } from '../../../../../../utils/contexts/CourseContext';
import { getQuizResults } from '../../../../../../utils/crud/quiz';
import useAnalytics from '../../../../../../utils/hooks/useAnalytics';

const VIEWS = {
  STUDENTS: 'students',
  ACTIVITIES: 'activities',
  TOPICS: 'topics',
  BNCC: 'BNCC'
};

const useQuizResults = ({
  questions,
  lastGameGuid,
  quizName,
  startsWithValuesStandards,
  standardsByProgram
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { selectedCourse } = useCourse();
  const { trackEvent } = useAnalytics();
  const { quizGuid } = useParams();
  const [selectedTab, setSelectedTab] = useState(VIEWS.STUDENTS);
  const [studentsResults, setStudentsResults] = useState([]);
  const [dialogTabs, setDialogTabs] = useState([]);
  const [dialogSelectedTab, setDialogSelectedTab] = useState(
    dialogTabs.length > 0 ? dialogTabs[0] : ''
  );
  const [activitiesResults, setActivitiesResults] = useState([]);
  const [topicsResults, setTopicsResults] = useState([]);
  const [standardsResults, setStandardsResults] = useState([]);
  const [openDetailedView, setOpenDetailedView] = useState(false);
  const [dialogViewDetailTitle, setDialogViewDetailTitle] = useState(quizName);
  const [dialogSelectedItem, setDialogSelectedItem] = useState(null);

  const commonTabs = [
    { name: t('students'), id: 'students' },
    { name: t('Activities'), id: 'activities' },
    { name: t('Topics'), id: 'topics' }
  ];

  let TABS = [...commonTabs];

  if (standardsByProgram !== null && standardsByProgram.includes('BNCC')) {
    TABS.push({ name: 'BNCC', id: 'BNCC' });
  }

  useEffect(() => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Practice_Results_Viewed, {
      practice_id: quizGuid,
      practice_name: quizName,
      number_of_students: selectedCourse?.users?.filter(
        (user) => user.role_guid === 'R01'
      ).length,
      number_of_activities: questions?.length,
      topics: questions?.map((question) => question?.lesson?.name)
    });
  }, []);

  useEffect(() => {
    initResults();
  }, [questions]);

  useEffect(() => {
    if (selectedTab === VIEWS.STUDENTS) {
      setDialogViewDetailTitle(quizName);
      setDialogTabs(getStudentTabs(studentsResults));
      setDialogSelectedItem(
        studentsResults.find((student) => student.guid === dialogSelectedTab)
      );
    } else if (selectedTab === VIEWS.ACTIVITIES) {
      setDialogViewDetailTitle(quizName);
      setDialogTabs(getActivitiesTabs(activitiesResults));

      setDialogSelectedItem(
        activitiesResults.find(
          (activity) => activity.guid === dialogSelectedTab
        )
      );
    } else if (selectedTab === VIEWS.TOPICS) {
      setDialogViewDetailTitle(quizName);
      setDialogTabs(getTopicsTabs(topicsResults));
      setDialogSelectedItem(
        topicsResults.find((topic) => topic.guid === dialogSelectedTab)
      );
    } else if (selectedTab === VIEWS.BNCC) {
      setDialogViewDetailTitle(quizName);
      setDialogTabs(getStandardsTabs(standardsResults));
      setDialogSelectedItem(
        standardsResults.find((std) => std.guid === dialogSelectedTab)
      );
    }
  }, [selectedTab, dialogSelectedTab]);

  const onChangeTab = (tab) => {
    setSelectedTab(tab);
  };

  const initResults = async () => {
    setLoading(true);
    if (lastGameGuid) {
      const results = await getQuizResults({
        courseGuid: selectedCourse.guid,
        gameGuid: lastGameGuid
      });
      if (results?.students.length > 0) {
        setStudentsResults(parseStudentsResults(results.students));
        setActivitiesResults(parseActivitiesResults(results.students));
        setTopicsResults(parseTopicsResults(results.students));
        setStandardsResults(parseStandardsResults(results.students));
      }
      setLoading(false);
    } else setLoading(false);
  };

  const parseStandardsResults = (results) => {
    const groupedQuestions = {};
    questions.forEach((question) => {
      const questionStandards = question.standards;
      questionStandards.forEach((standard) => {
        if (groupedQuestions[standard.guid]) {
          groupedQuestions[standard.guid].push(question);
        } else {
          groupedQuestions[standard.guid] = [question];
        }
      });
    });

    return Object.keys(groupedQuestions)
      .filter((std) =>
        startsWithValuesStandards.some((value) => std.startsWith(value))
      )
      .map((std) => {
        const standard = groupedQuestions[std];
        let totalAnswers = 0;
        let correctAnswers = 0;

        const standardQuestions = standard.map((question) => {
          let studentsWithCorrectAnswers = [];
          let studentsWithWrongAnswers = [];

          const studentResults = results.map((student) => {
            const answer = student.answers.find(
              (answer) => answer.reference_id === question.guid
            );

            if (parseFloat(answer?.score) === 1) {
              correctAnswers++;
              studentsWithCorrectAnswers.push({
                guid: student.guid,
                name: student.name + ' ' + student.lastname,
                avatar: getStudentAvatar(student)
              });
            } else {
              studentsWithWrongAnswers.push({
                guid: student.guid,
                name: student.name + ' ' + student.lastname,
                avatar: getStudentAvatar(student)
              });
            }

            totalAnswers++;

            return {
              studentsWithCorrectAnswers,
              studentsWithWrongAnswers
            };
          });

          return {
            ...question,
            ...studentResults[0]
          };
        });

        return {
          guid: standard[0].standards.filter((item) => item.guid === std)[0]
            ?.guid,
          standard: standard[0].standards.filter(
            (item) => item.guid === std
          )[0],
          progress: (correctAnswers / totalAnswers).toFixed(2),
          activities: standard.length + ' ' + t('activities'),
          color: getColorProgress(correctAnswers / totalAnswers),
          questions: standardQuestions
        };
      });
  };

  const getStandardsTabs = (standards) => {
    return standards.map((std) => {
      return {
        name: std.standard?.name,
        id: std.guid
      };
    });
  };

  const parseStudentsResults = (students) => {
    const studentsResults = students
      .map((student, index) => {
        let totalPoints = 0;
        let correctAnswers = 0;
        let points = 0;

        student.answers.forEach((answer) => {
          if (parseFloat(answer?.score) === 1 && answer?.session_time) {
            points = calculatePoints(answer?.session_time);
            totalPoints += points;
            correctAnswers++;
          }
        });

        // TODO: Improve loops here to avoid redundant loops
        const questionsWithScores = questions.map((question) => ({
          ...question,
          score: parseFloat(
            student.answers.find(
              (answer) => answer.reference_id === question.guid
            )?.score
          )
        }));

        const studentAnswers = questions.map(
          (question) =>
            student.answers.find(
              (answer) => answer.reference_id === question.guid
            )?.data
        );

        return {
          guid: student.guid,
          name: student.name + ' ' + student.lastname,
          avatar: getStudentAvatar(student),
          activities:
            correctAnswers +
            ' / ' +
            questions.length +
            ' ' +
            t('correct_answers'),
          totalAnswers: questions.length,
          correctAnswers: correctAnswers,
          progress: (correctAnswers / questions.length).toFixed(2),
          color: getColorProgress(correctAnswers / questions.length),
          points: totalPoints,
          answers: studentAnswers,
          questions: questionsWithScores
        };
      })
      .sort((a, b) => b.points - a.points)
      .map((student, index) => ({
        ...student,
        index: `#${index < 9 ? '0' + (index + 1) : index + 1}`
      }));
    setDialogTabs(getStudentTabs(studentsResults));
    return studentsResults;
  };

  const getStudentTabs = (students) => {
    return students.map((student) => {
      return {
        name: student.name,
        image: student.avatar,
        id: student.guid
      };
    });
  };

  const getStudentAvatar = (student) => {
    let avatar = '';
    try {
      avatar = JSON.parse(student.json_settings);
      if (avatar?.blueberry?.avatar?.single_image) {
        avatar = avatar.blueberry.avatar.single_image;
      }
    } catch (error) {
      console.log('Error parsing avatar', error);
    }
    return avatar;
  };

  // const calculatePoints = (timeInMs) => {
  //   return Math.round(1000 + -0.0333 * timeInMs);
  // };

  const calculatePoints = (timeInMs) => {
    const maxTimeInMs = 30000;
    const numberOfQuestions = questions?.length;
    return Math.round(
      (1 - timeInMs / (maxTimeInMs * (numberOfQuestions + 1))) * 1000
    );
  };

  const parseActivitiesResults = (results) => {
    const activitiesResults = questions.map((activity, index) => {
      let totalStudents = results.length;

      const studentsWithCorrectAnswers = results
        .filter(
          (student) =>
            parseFloat(
              student.answers.find(
                (answer) => answer.reference_id === activity.guid
              )?.score
            ) === 1
        )
        .map((item) => ({
          guid: item.guid,
          name: item.name + ' ' + item.lastname,
          avatar: getStudentAvatar(item)
        }));

      // all the remaining students
      const studentsWithWrongAnswers = results
        .filter(
          (student) =>
            parseFloat(
              student.answers.find(
                (answer) => answer.reference_id === activity.guid
              )?.score
            ) !== 1
        )
        .map((item) => ({
          guid: item.guid,
          name: item.name + ' ' + item.lastname,
          avatar: getStudentAvatar(item)
        }));

      const correctAnswers = studentsWithCorrectAnswers.length;
      const question = [
        {
          ...activity,
          studentsWithWrongAnswers,
          studentsWithCorrectAnswers
        }
      ];

      return {
        numActivity: index + 1,
        guid: activity.guid,
        name:
          activity?.question?.title ||
          cleanStimulus(activity?.question?.data?.stimulus),
        progress: (correctAnswers / totalStudents).toFixed(2),
        color: getColorProgress(correctAnswers / totalStudents),
        question,
        correctAnswers:
          studentsWithCorrectAnswers.length +
          ' ' +
          t('out_of') +
          ' ' +
          totalStudents +
          ' ' +
          t('have_answered_correctly')
      };
    });

    setDialogTabs(getActivitiesTabs(activitiesResults));
    return activitiesResults;
  };

  const getActivitiesTabs = (activities) => {
    return activities.map((activity) => {
      return {
        name: activity.name,
        id: activity.guid,
        index: activity.numActivity
      };
    });
  };

  const parseTopicsResults = (results) => {
    const groupedQuestions = {};
    questions.forEach((question) => {
      const lessonGuid = question.lesson.guid;
      if (groupedQuestions[lessonGuid]) {
        groupedQuestions[lessonGuid].push(question);
      } else {
        groupedQuestions[lessonGuid] = [question];
      }
    });

    return Object.keys(groupedQuestions).map((lessonGuid) => {
      const lesson = groupedQuestions[lessonGuid];
      let totalAnswers = 0;
      let correctAnswers = 0;

      const lessonQuestions = lesson.map((question) => {
        let studentsWithCorrectAnswers = [];
        let studentsWithWrongAnswers = [];

        const studentResults = results.map((student) => {
          const answer = student.answers.find(
            (answer) => answer.reference_id === question.guid
          );

          if (parseFloat(answer?.score) === 1) {
            correctAnswers++;
            studentsWithCorrectAnswers.push({
              guid: student.guid,
              name: student.name + ' ' + student.lastname,
              avatar: getStudentAvatar(student)
            });
          } else {
            studentsWithWrongAnswers.push({
              guid: student.guid,
              name: student.name + ' ' + student.lastname,
              avatar: getStudentAvatar(student)
            });
          }

          totalAnswers++;

          return {
            studentsWithCorrectAnswers,
            studentsWithWrongAnswers
          };
        });

        return {
          ...question,
          ...studentResults[0]
        };
      });

      return {
        guid: lesson[0].guid,
        topic: lesson[0].unit.name,
        subtopic: lesson[0].lesson.name,
        level: lesson[0].educationYearTag,
        progress: (correctAnswers / totalAnswers).toFixed(2),
        color: getColorProgress(correctAnswers / totalAnswers),
        questions: lessonQuestions,
        activities:
          lessonQuestions.length +
          ' ' +
          t('Activity', {
            count: lessonQuestions.length
          })
      };
    });
  };

  const getTopicsTabs = (topics) => {
    return topics.map((topic) => {
      return {
        name: topic.subtopic,
        id: topic.guid,
        badge: topic.level
      };
    });
  };

  const cleanStimulus = (stimulus) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(stimulus, 'text/html');
    // Extract text content, which ignores all HTML tags
    const text = doc.body.textContent || '';

    return text.length > 200 ? text.substring(0, 200) + '...' : text;
  };

  const getColorProgress = (progress) => {
    if (progress <= 0.3) {
      return 'danger';
    } else if (progress <= 0.6) {
      return 'warning';
    } else {
      return 'success';
    }
  };

  const onCancelDetailedView = () => {
    setOpenDetailedView(false);
  };

  const onOpenDetailedView = (selectedItem) => {
    setDialogSelectedTab(selectedItem);
    setOpenDetailedView(true);
  };

  const onChangeDialogSelectedTab = (tab) => {
    setDialogSelectedTab(tab);
  };

  return {
    TABS,
    VIEWS,
    selectedTab,
    studentsResults,
    activitiesResults,
    topicsResults,
    standardsResults,
    dialogTabs,
    loading,
    openDetailedView,
    dialogViewDetailTitle,
    dialogSelectedTab,
    dialogSelectedItem,
    educationYear: selectedCourse.education_year_name,
    onChangeDialogSelectedTab,
    onCancelDetailedView,
    onOpenDetailedView,
    onChangeTab
  };
};

export default useQuizResults;
