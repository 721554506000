import { Alert, AlertTitle, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import Button from '../../atoms/button/Button';
import Typography from '../../atoms/typography/Typography';

import { GoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { isLayersTenant } from '../../utils/helpers/whichTenant';
import './LoginForm.scss';
import LoginWithLayers from './components/LoginWithLayers';
import RegionSelect from './components/RegionSelect/RegionSelect';

const LoginForm = ({
  error,
  setError,
  isLoading,
  onLoginWithGoogle,
  onLoginWithEmailAndPassword
}) => {
  const { t } = useTranslation();

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onLoginWithEmailAndPassword(credentials);
  };

  if (isLayersTenant()) {
    return (
      <div className='login-form__container'>
        <div className='login-form__header'>
          <Typography variant='h2'>{t('login_to_dashboard')}</Typography>
        </div>
        {error && (
          <div className='login-form__input'>
            <Alert severity='error'>
              <AlertTitle>{t(error)}</AlertTitle>
            </Alert>
          </div>
        )}
        <div className='login-form__body'>
          <div className='login-form__layers'>
            {isLoading ? <CircularProgress /> : <LoginWithLayers />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='login-form__container'>
      <div className='login-form__header' data-testid='login-heading'>
        <Typography variant='h2'>{t('login_to_dashboard')}</Typography>
      </div>
      <RegionSelect />
      <form className='login-form__body' onSubmit={handleSubmit}>
        {error && (
          <div className='login-form__input' data-testid='error-message'>
            <Alert severity='error'>
              <AlertTitle>{t(error)}</AlertTitle>
            </Alert>
          </div>
        )}
        <div className='login-form__input'>
          <TextField
            fullWidth
            disabled={isLoading}
            type='text'
            placeholder={t('email')}
            value={credentials.email}
            inputProps={{ 'data-testid': 'email-input' }}
            onChange={({ target: { value } }) => {
              setCredentials({
                ...credentials,
                email: value
              });

              setError('');
            }}
          />
        </div>
        <div className='login-form__input'>
          <TextField
            fullWidth
            disabled={isLoading}
            type='password'
            placeholder={t('password')}
            value={credentials.password}
            inputProps={{ 'data-testid': 'password-input' }}
            onChange={({ target: { value } }) => {
              setCredentials({
                ...credentials,
                password: value
              });

              setError('');
            }}
          />
        </div>
        <div className='login-form__submit'>
          <Button
            color='primary'
            variant='contained'
            size='lg'
            fullWidth
            onClick={() => onLoginWithEmailAndPassword(credentials)}
            loading={isLoading}
            text={isLoading ? t('logging in') : t('login')}
            data-testid='login-button'
          />
        </div>
        <div className='login-form__submit'>
          <GoogleLogin
            size='large'
            width={320}
            onSuccess={onLoginWithGoogle}
            onError={() => {
              setError('LOGIN_GOOGLE_ERROR');
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
