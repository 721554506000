import { memo, useEffect } from 'react';
import './playQuizPodium.scss';

import ImageCup from '../assets/ranking_cup.png';
import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import GameQuizTitle from '../components/gameQuizTitle/GameQuizTitle';

import { useTranslation } from 'react-i18next';
import confetti from '../../../../../utils/confetti';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../utils/hooks/useAuth';
import AvatarOnPodiumComponent from './AvatarOnPodiumComponent';

const PlayQuizPodium = memo(function PlayQuizPodium({
  quizGuid,
  quizName,
  playerList,
  setPlayerList,
  closeGame
}) {
  const { schoolAssetsPath } = useAuth();
  const DEFAULT_AVATAR = generateAssetURL({
    asset: 'default_single_avatar.png',
    schoolAssetsPath
  });
  const { t } = useTranslation();

  useEffect(() => {
    const sortedPlayers = [...playerList].sort(
      (a, b) => (b?.points || 0) - (a?.points || 0)
    );

    setPlayerList(sortedPlayers);

    const conffetiTimeout = setTimeout(() => {
      confetti.maxCount = 250;
      confetti.start();
    }, 5500);

    return () => {
      clearTimeout(conffetiTimeout);
      confetti.remove();
    };
  }, []);

  return (
    <div className='play-quiz-podium'>
      <GameQuizHeader
        quizGuid={quizGuid}
        closeGame={closeGame}
        centerComponent={<GameQuizTitle title={quizName} />}
      />

      <div className='play-quiz-podium__content'>
        <div className='play-quiz-podium__podium-wrapper'>
          <AvatarOnPodiumComponent
            position={1}
            userName={playerList[1]?.name || ''}
            userAvatar={playerList[1]?.avatar || DEFAULT_AVATAR}
            userPoints={playerList[1]?.points || 0}
          />
          <AvatarOnPodiumComponent
            position={0}
            userName={playerList[0]?.name || ''}
            userAvatar={playerList[0]?.avatar || DEFAULT_AVATAR}
            userPoints={playerList[0]?.points || 0}
          />
          <AvatarOnPodiumComponent
            position={2}
            userName={playerList[2]?.name || ''}
            userAvatar={playerList[2]?.avatar || DEFAULT_AVATAR}
            userPoints={playerList[2]?.points || 0}
          />
        </div>

        <div
          className={`play-quiz-podium__ranking-wrapper ${
            playerList.length <= 3
              ? 'play-quiz-podium__ranking-wrapper--empty'
              : ''
          }`}
        >
          <div className='play-quiz-podium__ranking-title'>
            <img src={ImageCup} alt='TOP RANKING' />
            TOP RANKING
          </div>
          <div className='play-quiz-podium__ranking-list'>
            {playerList?.slice(3).map((player, index) => (
              <div className='ranking-list__item' key={player.user_guid}>
                <div className='ranking-list__position'>{index + 4}</div>
                <div className='ranking-list__name'>{player?.name || ''}</div>
                <div className='ranking-list__points'>
                  {`${player?.points || 0} ${t('quiz_points')}`}
                </div>
                <div className='ranking-list__avatar'>
                  <img
                    src={player?.avatar || DEFAULT_AVATAR}
                    alt={player?.name || ''}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlayQuizPodium;
