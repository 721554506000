export const DOMAIN_MAP = {
  'dashboard-blueberry.tangerine-qa1.oneclicklabs.es':
    'https://blueberry.tangerine-qa1.oneclicklabs.es/',
  'dashboard-blueberry.tangerine-dev1.oneclicklabs.es':
    'https://blueberry.tangerine-dev1.oneclicklabs.es/',
  'dashboard.blueberry.oneclick.es': 'https://blueberry.oneclick.es/',
  'dashboard.blueberry-demo.oneclicklabs.es':
    'https://blueberry-demo.oneclicklabs.es/',
  'dashboard.blueberry.oneclicklabs.es': 'https://blueberry.oneclicklabs.es/',
  'dashboard.blueberry-sa.oneclicklabs.es':
    'https://blueberry-sa.oneclicklabs.es/',
  'dashboard.positivo-hml.blueberry.app.br':
    'https://positivo-hml.blueberry.app.br/',
  'localhost:3001': 'http://localhost:3000',
  'localhost:3000': 'https://blueberry.tangerine-dev1.oneclicklabs.es/',
  'dashboard.escola.blueberrymath.ai': 'https://escola.blueberrymath.ai',
  'dashboard.escuela.blueberrymath.ai': 'https://escuela.blueberrymath.ai',
  'dashboard.blueberry-family-qa.oneclicklabs.es ':
    'https://blueberry-family-qa.oneclicklabs.es ',
  'dashboard.school.blueberrymath.com': 'https://school.blueberrymath.com/',
  'dashboard.school.blueberrymath.ai': 'https://school.blueberrymath.ai',
  'dashboard.school.blueberry-qa.oneclicklabs.es':
    'https://school.blueberry-qa.oneclicklabs.es',
  'dashboard.school.blueberry-dev.oneclicklabs.es':
    'https://school.blueberry-dev.oneclicklabs.es',
  'dashboard.layers.blueberry.app.br': 'https://layers.blueberry.app.br'
};
