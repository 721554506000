import { useState } from 'react';
import './TDInputText.scss';

import { ReactComponent as VisibilityOff } from '../../assets/icons/eye-off.svg';
import { ReactComponent as Visibility } from '../../assets/icons/eye.svg';

const TDInputText = ({
  name,
  type,
  label,
  onChange,
  placeholder,
  disabled,
  hasError,
  errorText,
  value
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  return (
    <div className={`td-input-text ${hasError ? 'td-input-text--error' : ''}`}>
      {label && <div className='td-input-text__label'>{label}</div>}
      <div className='td-input-text__input-wrapper'>
        <input
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          name={name}
          value={value}
          className='td-input-text__input'
          placeholder={placeholder || ''}
          onChange={onChange}
          disabled={disabled}
        />

        {type === 'password' && (
          <span
            className={`td-input-text__toggle-password ${
              disabled ? 'disabled' : ''
            }`}
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </span>
        )}
      </div>

      {hasError && <div className='td-input-text__error-text'>{errorText}</div>}
    </div>
  );
};

export default TDInputText;
