const generateAssetURL = ({ asset, schoolAssetsPath }) => {
  if (schoolAssetsPath) {
    return schoolAssetsPath === 'default'
      ? `/assets/graphics/${asset}`
      : `/assets/graphics/${schoolAssetsPath}/${asset}`;
  }
  return `/assets/graphics/transparent.svg`;
};

export default generateAssetURL;
