import { useTranslation } from 'react-i18next';
import generateAssetURL from '../../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../../utils/hooks/useAuth';
import './interventionsListEmptyCase.scss';

const InterventionsListEmptyCaseWithoutActivity = () => {
  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();

  const avatarIcon = generateAssetURL({
    asset: 'avatar_worried_sad.svg',
    schoolAssetsPath: schoolAssetsPath
  });

  return (
    <div className='interventions-list-empty-case'>
      <img src={avatarIcon} alt='avatar worried sad' />
      <div>{t('interventions_empty_case_no_activity')}</div>
    </div>
  );
};

export default InterventionsListEmptyCaseWithoutActivity;
