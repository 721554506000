import { useTranslation } from 'react-i18next';

const useRegionSelect = (LOCALHOST_DOMAIN) => {
  const { t } = useTranslation();

  const redirectToRegion = (selectedRegionCode) => {
    let url = '';

    switch (selectedRegionCode) {
      case 'eu':
        url = 'https://dashboard.school.blueberrymath.ai';
        break;
      case 'sa':
      case 'do':
      case 'br':
        url = 'https://dashboard.school.blueberrymath.com';
        break;
      case 'mx':
        url = 'https://dashboard.school.blueberrymath.com.mx';
        break;
      default:
        break;
    }
    // If the current user has the same domain as the one they need to go to, they are not redirected, only the selector is changed
    const fullDomain = window.location.protocol + '//' + window.location.host;
    if (url && fullDomain !== url) {
      window.location.href = url;
    }
  };

  const translateCountry = (countryCode) => {
    let translation = '';

    switch (countryCode) {
      case 'es':
        translation = t('region_select_option_es');
        break;
      case 'do':
        translation = t('region_select_option_rd');
        break;
      case 'ar':
        translation = t('region_select_option_ar');
        break;
      case 'mx':
        translation = t('region_select_option_mx');
        break;
      case 'br':
        translation = t('region_select_option_br');
        break;
      default:
        break;
    }
    return translation;
  };

  const getCurrentDomainEnd = () => {
    let domain = window.location.hostname;

    if (domain.includes('localhost')) {
      domain = LOCALHOST_DOMAIN;
    }
    if (domain.endsWith('com.mx')) {
      return '.com.mx';
    } else if (domain.endsWith('com')) {
      return '.com';
    } else if (domain.endsWith('ai')) {
      return '.ai';
    }

    return '';
  };

  // Save the state of prompt viewed
  const savePromptChecked = () => {
    const currentDomain = getCurrentDomainEnd();

    const defaultPromptState = {
      '.ai': false,
      '.com': false,
      '.com.mx': false
    };

    const savedPromptState =
      JSON.parse(localStorage.getItem('user_region_prompt_checked')) ||
      defaultPromptState;

    const updatedPromptState = {
      ...savedPromptState,
      [currentDomain]: true
    };

    localStorage.setItem(
      'user_region_prompt_checked',
      JSON.stringify(updatedPromptState)
    );
    setIsPromptChecked(true);
  };

  // Get the prompt viewed state for the current domain when mounting the component
  const getPromptChecked = () => {
    const currentDomain = getCurrentDomainEnd();
    const savedPromptState = JSON.parse(
      localStorage.getItem('user_region_prompt_checked')
    );

    if (!savedPromptState) {
      return false;
    }

    return savedPromptState[currentDomain] || false;
  };

  return {
    redirectToRegion,
    translateCountry,
    savePromptChecked,
    getPromptChecked
  };
};

export default useRegionSelect;
