import { useTranslation } from 'react-i18next';
import { TDTypography } from '../../../../atoms/TDUILib';
import './classProgressTooltip.scss';

const ClassProgressTooltip = () => {
  const { t } = useTranslation();
  return (
    <div className='class-progress-tooltip'>
      <div>
        <TDTypography variant='body-m' fontWeight='bold'>
          {t('page_tooltip.class_progress_overview')}
        </TDTypography>
      </div>
      <div>
        <div className='class-progress-tooltip__row'>
          <div className='class-progress-tooltip__needs-to-try-harder' />
          <TDTypography variant='body-m' fontWeight='bold'>
            {t('page_tooltip.needs_to_try_harder')}
          </TDTypography>
          <TDTypography variant='body-m' fontWeight='medium'>
            {t('page_tooltip.needs_to_try_harder_info')}
          </TDTypography>
        </div>

        <div className='class-progress-tooltip__row'>
          <div className='class-progress-tooltip__needs-help' />
          <TDTypography variant='body-m' fontWeight='bold'>
            {t('page_tooltip.needs_help')}
          </TDTypography>
          <TDTypography variant='body-m' fontWeight='medium'>
            {t('page_tooltip.needs_help_info')}
          </TDTypography>
        </div>

        <div className='class-progress-tooltip__row'>
          <div className='class-progress-tooltip__in-progress' />
          <TDTypography variant='body-m' fontWeight='bold'>
            {t('page_tooltip.in_progress')}
          </TDTypography>
          <TDTypography variant='body-m' fontWeight='medium'>
            {t('page_tooltip.in_progress_info')}
          </TDTypography>
        </div>

        <div className='class-progress-tooltip__row'>
          <div className='class-progress-tooltip__achieved' />
          <TDTypography variant='body-m' fontWeight='bold'>
            {t('page_tooltip.achieved')}
          </TDTypography>
          <TDTypography variant='body-m' fontWeight='medium'>
            {t('page_tooltip.achieved_info')}
          </TDTypography>
        </div>
      </div>
    </div>
  );
};

export default ClassProgressTooltip;
