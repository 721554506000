import { Avatar, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../utils/hooks/useAuth';
import './ProfileMenuItem.scss';

const ProfileMenuItem = ({ type }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { onLogout, user } = useAuth();

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='profile-menu-item'>
      <div className='profile-menu-item__icon' onClick={handleClick}>
        <Avatar
          sx={{
            width: '3rem',
            height: '3rem',
            cursor: 'pointer',
            bgcolor: type === 'topbar' ? '#5A34E1' : 'auto'
          }}
          title={user?.username || ''}
        >
          {user?.name?.charAt(0)?.toUpperCase()}
          {type !== 'topbar' && user?.lastname?.charAt(0)?.toUpperCase()}
        </Avatar>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: type === 'topbar' ? 'bottom' : 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: type === 'topbar' ? 'top' : 'center',
          horizontal: type === 'topbar' ? 'right' : 'left'
        }}
        sx={{
          marginTop: type === 'topbar' ? '6px' : 'auto',
          marginLeft: type === 'topbar' ? 'auto' : '6px'
        }}
      >
        <MenuItem
          className='profile-menu-item__button'
          onClick={() => navigate('/user')}
          sx={{ fontWeight: '700' }}
          data-testid='my-profile-button'
        >
          {t('my_profile')}
        </MenuItem>

        <div className='profile-menu-item__separator'></div>
        <MenuItem
          className='profile-menu-item__button'
          onClick={onLogout}
          sx={{ fontWeight: '700', color: '#F02959' }}
          data-testid='logout-button'
        >
          {t('sign_out')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenuItem;
