import { Add, Remove, Search } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, IconButton, ListItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalysisSegment } from '../../../../../../utils/analysis';
import useAnalytics from '../../../../../../utils/hooks/useAnalytics';
import './createQuizTopBar.scss';

const CreateQuizTopBar = ({
  quizName,
  standards,
  onChangeQuizName,
  onFilterAndSearch,
  onChangeNumberOfQuestions,
  standardsByProgram
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const DEFAULT_NUMBER_OF_ACTIVITIES_IN_QUIZ = 5;

  const [numberOfActivities, setNumberOfActivities] = useState(
    DEFAULT_NUMBER_OF_ACTIVITIES_IN_QUIZ
  );
  const [searchValue, setSearchValue] = useState('');
  const [selectedStandard, setSelectedStandard] = useState('');

  const onSelectStandard = (event, newValue) => {
    setSelectedStandard(newValue?.guid);

    if (newValue?.guid) {
      trackEvent(
        AnalysisSegment.SEGMENT_EVENTS.New_Practice_Standard_Selected,
        {
          standard: newValue?.name,
          standard_id: newValue?.guid
        }
      );
    }
  };

  useEffect(() => {
    console.log('searchValue', searchValue);
    console.log('selectedStandard', selectedStandard);
    onFilterAndSearch({
      searchText: searchValue,
      standardGuid: selectedStandard
    });
  }, [searchValue, selectedStandard]);

  useEffect(() => {
    onChangeNumberOfQuestions(numberOfActivities);
  }, [numberOfActivities]);

  return (
    <div className='create-quiz-top-bar'>
      <div className='create-quiz-top-bar__left-wrapper'>
        <div className='create-quiz-top-bar__name'>
          <TextField
            fullWidth
            value={quizName}
            placeholder={t('Quiz name')}
            onChange={({ target: { value } }) => onChangeQuizName(value)}
          />
        </div>
        <div className='create-quiz-top-bar__number-selector'>
          <IconButton
            disabled={numberOfActivities <= 1}
            variant='counter'
            onClick={() => setNumberOfActivities(numberOfActivities - 1)}
          >
            <Remove />
          </IconButton>
          <div className='create-quiz-top-bar__number-selector-text'>
            <span>{numberOfActivities}</span> <div>{t('activities')}</div>
          </div>
          <IconButton
            variant='counter'
            onClick={() => setNumberOfActivities(numberOfActivities + 1)}
          >
            <Add />
          </IconButton>
        </div>
      </div>
      <div className='create-quiz-top-bar__right-wrapper'>
        <div className='create-quiz-top-bar__search'>
          <TextField
            fullWidth
            placeholder={t('Search for topics')}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: <Search />
            }}
          />
        </div>
        {standardsByProgram && (
          <div className='create-quiz-top-bar__filter'>
            <Autocomplete
              popupIcon={<KeyboardArrowDownIcon />}
              disablePortal
              options={standards}
              getOptionLabel={(option) => option.name}
              onChange={onSelectStandard}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('standard')} fullWidth />
              )}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.name}>
                  <div>{option.name}</div>
                  <div className='create-quiz-top-bar__filter-check'>
                    {option?.name === selectedStandard && (
                      <Check fontSize='small' />
                    )}
                  </div>
                </ListItem>
              )}
              componentsProps={{
                popper: {
                  style: {
                    width: 'auto',
                    minWidth: '200px'
                  },
                  placement: 'bottom-end'
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateQuizTopBar;
