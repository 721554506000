import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg';
import GenericToast from '../../../../atoms/GenericToast/GenericToast';
import { AnalysisSegment } from '../../../../utils/analysis';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import {
  addStudents,
  changeStudentClass,
  deleteStudents,
  editStudent,
  getClassStudents
} from '../../../../utils/crud/student';
import {
  checkIfUserHasVisitedPageBefore,
  markUserAsVisitedPage
} from '../../../../utils/helpers/manageUserPageVisits';
import { tryJsonParse } from '../../../../utils/helpers/tryJsonParse';
import useAnalytics from '../../../../utils/hooks/useAnalytics';
import useAuth from '../../../../utils/hooks/useAuth';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../utils/localStorageKeys';
import CellPassword from '../components/CellPassword/CellPassword';
import CellStudentNameWithHelpIcon from '../components/CellStudentNameWithHelpIcon/CellStudentNameWithHelpIcon';
import HeaderCellPassword from '../components/HeaderCellPassword/HeaderCellPassword';
import useStudentsSorting from '../useStudentsSorting';

const useStudentsManagement = () => {
  const filterKey = location.pathname;
  const { setItem, getItem } = useLocalStorage();
  const { state: locationState } = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { selectedCourse, availableCourses, hydrateCourses } = useCourse();
  const { trackEvent } = useAnalytics();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChangingClass, setIsChangingClass] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [hasEncryptedPasswords, setHasEncryptedPasswords] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [groupName, setGroupName] = useState(null);
  const columnHelper = createColumnHelper();
  const { sortData } = useStudentsSorting();

  const [isLoginSheetsDialogOpen, setIsLoginSheetsDialogOpen] = useState(false);
  const [isAddStudentsDialogOpen, setIsAddStudentsDialogOpen] = useState(false);
  const [isEditStudentDialogOpen, setIsEditStudentDialogOpen] = useState(false);
  const [isChangeClassDialogOpen, setIsChangeClassDialogOpen] = useState(false);
  const [isDeleteStudentDialogOpen, setIsDeleteStudentDialogOpen] =
    useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState();
  const [isReady, setIsReady] = useState(false);

  const openLoginSheetsDialog = () => setIsLoginSheetsDialogOpen(true);
  const closeLoginSheetsDialog = () => setIsLoginSheetsDialogOpen(false);

  const openAddStudentsDialog = () => {
    setIsAddStudentsDialogOpen(true);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Add_Students_Pop_Up_Opened);
  };

  const closeAddStudentsDialog = () => {
    setIsAddStudentsDialogOpen(false);
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Add_Students_Pop_Up_Closed);
  };

  const [itemToEdit, setItemToEdit] = useState(null);
  const openChangeClassDialog = () => setIsChangeClassDialogOpen(true);
  const closeChangeClassDialog = () => setIsChangeClassDialogOpen(false);

  const openEditStudentDialog = () => {
    setIsEditStudentDialogOpen(true);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Edit_Students_Pop_Up_Opened);
  };
  const closeEditStudentDialog = () => setIsEditStudentDialogOpen(false);

  const openDeleteStudentDialog = () => setIsDeleteStudentDialogOpen(true);
  const closeDeleteStudentDialog = () => setIsDeleteStudentDialogOpen(false);

  const [itemToChangeClass, setItemToChangeClass] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [selectedClassOption, setSelectedClassOption] = useState();
  const [classOptions, setClassOptions] = useState([]);

  const onChangeClassOption = (selectedOption) => {
    setSelectedClassOption(selectedOption);
  };

  const STUDENT_PAGE_DEFAULT_ORDER_BY = {
    label: t('alphabetical_order_by_lastname'),
    value: 'lastname'
  };

  const SORT_OPTIONS = ['name', 'lastname'].map((option) => ({
    label: t(`alphabetical_order_by_${option}`),
    value: option
  }));

  const TABLE_MENU_OPTIONS = [
    {
      label: t('students_management_edit_data'),
      onClick: (user) => {
        setItemToEdit(user);
        openEditStudentDialog();
      }
    },
    {
      label: t('students_management_change_class'),
      onClick: (user) => {
        setItemToChangeClass(user);
        openChangeClassDialog();
      },
      disabled: availableCourses && availableCourses.length < 2
    },
    {
      isSeparator: true
    },
    {
      icon: <DeleteIcon />,
      label: t('students_management_delete_student'),
      onClick: (user) => {
        setItemToDelete(user);
        openDeleteStudentDialog();
      },
      isDangerous: true
    }
  ];

  const togglePasswordVisibility = () => {
    if (hasEncryptedPasswords && !isPasswordVisible) {
      toast(
        <GenericToast
          type='info'
          title={t('students_password_not_displayed_toast_title')}
          text={t('students_password_not_displayed_toast_info')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--info'
        }
      );
    }
    setIsPasswordVisible(!isPasswordVisible);

    if (!isPasswordVisible) {
      trackEvent(
        AnalysisSegment.SEGMENT_EVENTS.Students_Management_Passwords_Viewed,
        {
          order_by:
            AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
              .Students_Management_Passwords_Viewed[selectedSortOption?.value]
        }
      );
    }
  };

  // Go to detail
  const onClickOnStudent = (studentGuid) => {
    navigate(`/${selectedCourse?.guid}/students/${studentGuid}`, {
      state: {
        from: location.pathname
      }
    });
  };

  // Table definition
  const columns = [
    columnHelper.accessor('lastname', {
      id: 'Student',
      header: t('student'),
      enableSorting: false,
      cell: (props) => (
        <CellStudentNameWithHelpIcon
          name={props.row.original?.name}
          lastName={props.row.original?.lastname}
          needsHelpInfo={false}
          onClickOnStudent={() => onClickOnStudent(props.row.original?.guid)}
        />
      )
    }),
    columnHelper.accessor('username', {
      header: t('username'),
      enableSorting: false,
      cell: (props) => props.row.original?.username
    }),
    columnHelper.accessor('password', {
      header: (
        <HeaderCellPassword
          togglePasswordVisibility={togglePasswordVisibility}
          isPasswordVisible={isPasswordVisible}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellPassword
          password={props.row.original?.password}
          isPasswordVisible={isPasswordVisible}
        />
      )
    })
  ];

  // Get data
  const getData = async () => {
    if (isLoading) return;
    setIsLoading(true);

    let tempData = await getClassStudents({
      schoolGuid: user?.schools[0].guid,
      groupGuid: selectedCourse?.school_group_guid
    });

    let apiData = tempData || [];

    // Check if passwords encryption to see if we can't show some of them

    const usersFromApi = apiData?.users || [];

    const aux = usersFromApi.some((user) => user?.encryption_type !== 'none');

    setHasEncryptedPasswords(aux);

    // Order users data by filter
    const sortedUsers = sortData(apiData?.users, selectedSortOption?.value);
    const hasUserVisitedBefore = checkIfUserHasVisitedPageBefore(
      localStorageKeys.hasVisitedStudentsManagementPage
    );
    if (!hasUserVisitedBefore) {
      markUserAsVisitedPage(localStorageKeys.hasVisitedStudentsManagementPage);
      showSortingToast(selectedSortOption);
    }

    setUsersData(sortedUsers);

    setIsLoading(false);
  };

  const onAddStudents = async (newStudents) => {
    setIsSaving(true);
    const addStudentsResponse = await addStudents({
      groupGuid: selectedCourse?.school_group_guid,
      students: newStudents
    });

    if (addStudentsResponse.status === 'success') {
      await hydrateCourses();

      toast(
        <GenericToast
          type='success'
          title={t('students_management_students_added')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--success'
        }
      );

      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Added, {
        students_added: newStudents?.length,
        method: 'manual'
      });

      closeAddStudentsDialog();
      getData();
    } else {
      toast(
        <GenericToast
          type='warning'
          title={t('students_management_add_students_error')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--error'
        }
      );

      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Added_Error, {
        students_added: newStudents?.length,
        method: 'manual'
      });
    }

    setIsSaving(false);
  };

  const onEditStudent = async (editStudentData) => {
    setIsEditing(true);
    const editStudentResponse = await editStudent({
      schoolGuid: user?.schools[0].guid,
      userGuid: editStudentData.userGuid,
      name: editStudentData.name?.trim(),
      lastname: editStudentData.lastname?.trim(),
      username: editStudentData.username?.trim(),
      password: editStudentData.password?.trim()
    });
    setIsEditing(false);

    // compare the old and new student data and get the number of properties that changed
    const oldStudentData = usersData?.find(
      (user) => user.guid === editStudentData.userGuid
    );
    const newStudentData = editStudentData;
    const propertiesThatChanged = ['name', 'lastname', 'username'].filter(
      (key) => {
        return newStudentData[key] !== oldStudentData[key];
      }
    );

    // if password changed, add it to the properties that changed
    // unedited password is an empty string which will be considered as changed but in fact it was not edited
    if (
      newStudentData.password &&
      newStudentData.password !== oldStudentData.password
    ) {
      propertiesThatChanged.push('password');
    }

    if (editStudentResponse.status === 'success') {
      toast(
        <GenericToast
          type='success'
          title={t('students_management_edit_success')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--success'
        }
      );

      closeEditStudentDialog();

      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Edited, {
        data_changed: propertiesThatChanged
      });
      getData();
    } else {
      toast(
        <GenericToast
          type='warning'
          title={t('students_management_edit_error')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--error'
        }
      );

      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Edition_Failed, {
        data_changed: propertiesThatChanged
      });
    }
  };

  const onDeleteStudent = async () => {
    setIsDeleting(true);

    const deleteStudentResponse = await deleteStudents({
      schoolGuid: user?.schools[0].guid,
      groupGuid: selectedCourse?.school_group_guid,
      studentGuids: [itemToDelete?.guid]
    });

    if (deleteStudentResponse.status === 'success') {
      await hydrateCourses();
      toast(
        <GenericToast
          type='success'
          title={
            itemToDelete?.username +
            ' ' +
            t('students_management_student_deleted')
          }
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--success'
        }
      );

      closeDeleteStudentDialog();
      getData();

      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_Deleted);
    } else {
      toast(
        <GenericToast
          type='warning'
          title={t('students_management_delete_student_error')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--error'
        }
      );

      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_Deleted_Error);
    }

    setIsDeleting(false);
  };

  const onChangeClass = async () => {
    setIsChangingClass(true);

    const changeClassResponse = await changeStudentClass({
      schoolGuid: user?.schools[0].guid,
      groupGuid: selectedClassOption?.value,
      studentGuid: [itemToChangeClass?.guid]
    });

    if (changeClassResponse.status === 'success') {
      await hydrateCourses();
      toast(
        <GenericToast
          type='success'
          title={t('students_management_class_changed', {
            studentName:
              itemToChangeClass?.name + ' ' + itemToChangeClass?.lastname,
            targetClass: selectedClassOption?.label
          })}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--success'
        }
      );

      closeChangeClassDialog();
      getData();
    } else {
      toast(
        <GenericToast
          type='warning'
          title={t('students_management_change_class_error')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--error'
        }
      );
    }

    setIsChangingClass(false);
  };

  const onChangeSortOption = (selectedOption) => {
    setSelectedSortOption(selectedOption);
  };

  const showSortingToast = (selectedOption) => {
    toast(
      <GenericToast
        type='info'
        title={t(`student_order_by_alphabetical_${selectedOption?.value}_info`)}
      />,
      {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: true,
        className: 'generic-toast--info'
      }
    );
  };

  useEffect(() => {
    if (!selectedSortOption || usersData.length === 0) return;
    const sortedUsers = sortData(usersData, selectedSortOption.value);
    setItem(
      filterKey,
      JSON.stringify({
        orderBy: selectedSortOption,
        sortedUserList: sortedUsers
      })
    );
    setUsersData(sortedUsers);
    showSortingToast(selectedSortOption);

    trackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Students_Management_Page_Order_Changed,
      {
        order_by:
          AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
            .Students_Management_Page_Order_Changed[selectedSortOption?.value]
      }
    );
  }, [selectedSortOption]);

  // Get initial data
  useEffect(() => {
    if (!selectedCourse?.guid || !isReady) return;

    toast.dismiss();
    setIsPasswordVisible(false);
    setGroupName(selectedCourse?.school_group_name);

    getData();
  }, [selectedCourse, isReady]);

  useEffect(() => {
    // Get class options
    const availableClassOptions = availableCourses?.map((course) => ({
      label: course?.school_group_name,
      value: course?.school_group_guid
    }));

    setSelectedClassOption({
      label: selectedCourse?.school_group_name,
      value: selectedCourse?.school_group_guid
    });

    setClassOptions(availableClassOptions);
  }, [availableCourses]);

  useEffect(() => {
    setSelectedClassOption({
      label: selectedCourse?.school_group_name,
      value: selectedCourse?.school_group_guid
    });
  }, [selectedCourse]);

  useEffect(() => {
    if (locationState?.action === 'open-add-students-popup') {
      openAddStudentsDialog();
    }
  }, [locationState]);

  useEffect(() => {
    let orderBy = STUDENT_PAGE_DEFAULT_ORDER_BY;

    // get filters from local storage if available
    let filter = getItem(filterKey);

    if (filter) {
      try {
        filter = tryJsonParse(filter);
        orderBy = filter.orderBy || STUDENT_PAGE_DEFAULT_ORDER_BY;
      } catch (e) {}
    }

    setSelectedSortOption(orderBy);

    setIsReady(true);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Management_Page_Viewed, {
      order_by:
        AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
          .Students_Management_Page_Order_Changed[orderBy?.value]
    });

    // Hide toasts when exiting section
    return () => {
      toast.dismiss();
    };
  }, []);

  return {
    usersData,
    columns,
    isLoading: isLoading,
    onAddStudents,
    onEditStudent,
    onDeleteStudent,
    onChangeClass,
    groupName,
    isLoginSheetsDialogOpen,
    openLoginSheetsDialog,
    closeLoginSheetsDialog,
    isAddStudentsDialogOpen,
    openAddStudentsDialog,
    closeAddStudentsDialog,
    isSaving,
    isEditStudentDialogOpen,
    isChangeClassDialogOpen,
    closeChangeClassDialog,
    itemToChangeClass,
    isChangingClass,
    isDeleteStudentDialogOpen,
    closeEditStudentDialog,
    closeDeleteStudentDialog,
    itemToEdit,
    isEditing,
    itemToDelete,
    isDeleting,
    TABLE_MENU_OPTIONS,
    SORT_OPTIONS,
    selectedSortOption,
    onChangeSortOption,
    onChangeClassOption,
    selectedClassOption,
    classOptions
  };
};

export default useStudentsManagement;
