import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/bb-math-logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as XIcon } from '../../assets/icons/x.svg';
import { ReactComponent as ButtonGenerateTraces } from '../../assets/images/generate_traces.svg';
import { ReactComponent as ButtonGenerateTracesWhite } from '../../assets/images/generate_traces_white.svg';
import AlertDialog from '../../atoms/AlertDialog/AlertDialog';
import ClassSelectorMenuItem from '../../components/classSelectorMenuItem/ClassSelectorMenuItem';
import ProfileMenuItem from '../../components/profileMenuItem/ProfileMenuItem';
import generateAssetUrl from '../../utils/helpers/generateAssetURL';
import { goToGame } from '../../utils/helpers/goToGame';
import useAuth from '../../utils/hooks/useAuth';
import DashboardMenuItem from './DashboardMenuItem';

import { CircularProgress, Tooltip } from '@mui/material';

import { AnalysisSegment } from '../../utils/analysis';
import useAnalytics from '../../utils/hooks/useAnalytics';
import './dashboardWithTopbarMenu.scss';
import useGenerateTraces from './useGenerateTraces';

const DashboardWithTopbarMenu = ({ upperNavigation }) => {
  const { user, schoolAssetsPath } = useAuth();
  const { trackEvent } = useAnalytics();

  const [isLoginAsStudentOpen, setIsLoginAsStudentOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    selectedCourse,
    isGenerateTracesLoading,
    lastDateGenerateTraces,
    setIsGenerateTracesDialog,
    isGenerateTracesDialog,
    onConfirmGenerateTracesDialog,
    handleCloseGenerateTracesDialog
  } = useGenerateTraces();

  const loginAsStudent = () => {
    setIsLoginAsStudentOpen(true);
  };

  function onConfirmDialog() {
    handleCloseDialog();
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.GAME_OPENED);
    goToGame();
  }

  const onOpenGoToGameDialog = () => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.See_As_Student_Button_Clicked);
    setIsLoginAsStudentOpen(true);
  };

  function handleCloseDialog() {
    setIsLoginAsStudentOpen(false);
  }

  const avatarRaisedHandsImageURL = generateAssetUrl({
    asset: 'avatar_raised_hands.svg',
    schoolAssetsPath
  });

  return (
    <div className='dashboard-with-topbar-menu'>
      <div
        className='dashboard-with-topbar-menu__topbar'
        data-testid='topbar-menu'
      >
        <div className='dashboard-with-topbar-menu__topbar-left'>
          <div className='dashboard-with-topbar-menu__left-buttons-container'>
            {isHamburgerMenuOpen && (
              <nav className='dashboard-with-topbar-menu__navigation dashboard-with-topbar-menu__mobile'>
                {upperNavigation.map((item) => (
                  <div
                    key={item.key[0]}
                    className='dashboard-with-topbar-menu__navigation-item'
                  >
                    <DashboardMenuItem
                      name={item?.name}
                      action={item?.action}
                      href={`/${selectedCourse?.guid}${item?.href}`}
                      key={item.key}
                      pageKey={item.key}
                      icon={item?.icon}
                      flag={item?.flag}
                      testId={item?.testId}
                      subsections={item?.subsections}
                      isResponsiveMode
                    />
                  </div>
                ))}

                <div className='dashboard-topbar__divider'></div>
                <div className='dashboard-with-topbar-menu__mobile-buttons-container'>
                  <button
                    className='dashboard-topbar__button dashboard-topbar__button--outlined'
                    onClick={() => {
                      navigate(`/${selectedCourse?.guid}/quizzes`);
                    }}
                  >
                    {t('Quiz_singular')}
                  </button>

                  <button
                    className='dashboard-topbar__button dashboard-topbar__button--outlined dashboard-topbar__button--outlined-with-icon'
                    style={{
                      backgroundImage: `url(${avatarRaisedHandsImageURL})`
                    }}
                    onClick={() => {
                      loginAsStudent();
                    }}
                  >
                    {t('See')}
                    <span className='dashboard-topbar__button--tooltip'>
                      {t('see_as_student')}
                    </span>
                  </button>
                </div>
              </nav>
            )}
            <div className='dashboard-with-topbar-menu__hamburger'>
              <button
                className='dashboard-topbar__button dashboard-topbar__button--outlined'
                onClick={() => {
                  setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
                }}
              >
                {isHamburgerMenuOpen ? <XIcon /> : <MenuIcon />}
              </button>
            </div>
            <div className='dashboard-with-topbar-menu__logo'>
              <Logo />
            </div>
          </div>
          <nav className='dashboard-with-topbar-menu__navigation'>
            {upperNavigation.map((item) => (
              <div
                key={item.key[0]}
                className='dashboard-with-topbar-menu__navigation-item'
              >
                {item.type === 'separator' ? (
                  <div className='dashboard-with-topbar-menu__navigation-separator' />
                ) : (
                  <DashboardMenuItem
                    name={item?.name}
                    action={item?.action}
                    href={`/${selectedCourse?.guid}${item?.href}`}
                    key={item.key}
                    pageKey={item.key}
                    icon={item?.icon}
                    flag={item?.flag}
                    testId={item?.testId}
                    subsections={item?.subsections}
                  />
                )}
              </div>
            ))}
          </nav>
        </div>
        <div className='dashboard-with-topbar-menu__topbar-middle'>
          <div className='dashboard-with-topbar-menu__middle-buttons-container'>
            <button
              className='dashboard-topbar__button dashboard-topbar__button--outlined'
              data-testid='quiz-menu-button'
              onClick={() => {
                navigate(`/${selectedCourse?.guid}/quizzes`);
              }}
            >
              {t('Quiz_singular')}
            </button>

            <button
              className='dashboard-topbar__button dashboard-topbar__button--outlined dashboard-topbar__button--outlined-with-icon'
              style={{
                backgroundImage: `url(${avatarRaisedHandsImageURL})`
              }}
              onClick={onOpenGoToGameDialog}
              data-testid='see-as-student-menu-button'
            >
              {t('Play')}
              <span className='dashboard-topbar__button--tooltip'>
                {t('see_as_student')}
              </span>
            </button>
          </div>
        </div>
        <div className='dashboard-with-topbar-menu__topbar-right'>
          {user?.is_demo === 1 &&
            user?.devmode === 1 &&
            (isGenerateTracesLoading ? (
              <Tooltip title={t('button_generate_traces_generating')}>
                <div className='dashboard-with-topbar-menu__topbar-traces-loading'>
                  <CircularProgress />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${t('button_generate_traces_hover')}  ${
                  lastDateGenerateTraces
                    ? ' ' +
                      t('button_generate_traces_tooltip', {
                        dateTraces: lastDateGenerateTraces
                      })
                    : ''
                }`}
              >
                <button
                  className='dashboard-topbar__button'
                  onClick={() => {
                    setIsGenerateTracesDialog(true);
                  }}
                >
                  <ButtonGenerateTraces className='dashboard-with-topbar-menu__topbar-traces' />
                </button>
              </Tooltip>
            ))}

          <ClassSelectorMenuItem />
          <ProfileMenuItem type='topbar' />
        </div>
      </div>
      <div
        className='dashboard-with-topbar-menu__main-content'
        data-testid='main-content'
      >
        <main>
          <Outlet />
        </main>

        <AlertDialog
          isOpen={isLoginAsStudentOpen}
          title={t('Are you sure you want to enter student view?')}
          description={t('A new tab will open in the browser')}
          onConfirm={onConfirmDialog}
          onCancel={handleCloseDialog}
          confirmText={t('Yes')}
          cancelText={t('No')}
        />

        <AlertDialog
          isOpen={isGenerateTracesDialog}
          title={t('button_generate_traces_title_dialog', {
            nameClass: selectedCourse?.school_group_name
          })}
          description={t('button_generate_traces_description_dialog')}
          onConfirm={onConfirmGenerateTracesDialog}
          onCancel={handleCloseGenerateTracesDialog}
          confirmText={t('button_generate_traces_generate')}
          cancelText={t('button_generate_traces_cancel')}
          className='dashboard-with-topbar-menu-dialog'
          iconButtonConfirm={<ButtonGenerateTracesWhite />}
        />
      </div>
    </div>
  );
};

export default DashboardWithTopbarMenu;
