import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import GenericToast from '../../../../../../atoms/GenericToast/GenericToast';
import TDButton from '../../../../../../atoms/TDButton/TDButton';
import TDInputText from '../../../../../../atoms/TDInputText/TDInputText';
import { checkUsername } from '../../../../../../utils/crud/student';
import './editStudentForm.scss';

const EditStudentForm = ({ student, onCancel, onSave, isSaving }) => {
  const { t } = useTranslation();
  const [isFormValid, setIsFormValid] = useState(false);
  const isPasswordEncrypted = student?.encryption_type !== 'none';
  const [showEncryptedMessage, setShowEncryptedMessage] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    username: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    lastname: '',
    username: '',
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (student) {
      setFormData({
        name: student.name || '',
        lastname: student.lastname || '',
        username: student.username || '',
        password: isPasswordEncrypted ? '' : student.password || '',
        confirmPassword: isPasswordEncrypted ? '' : student.password || ''
      });
    }
  }, [student]);

  useEffect(() => {
    validateFilledForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    if (
      !isPasswordEncrypted ||
      (name !== 'password' && name !== 'confirmPassword')
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim()
          ? ''
          : t('students_management_edit_error_empty_field')
      }));
    }
  };

  const validateStudentUsername = async (username) => {
    const checkUsernameResponse = await checkUsername(username);

    let isValidStudentUsername = true;
    if (checkUsernameResponse.status === 'success') {
      if (
        checkUsernameResponse?.data[0]?.username_check?.message ===
        'Username available'
      )
        isValidStudentUsername = true;
      else {
        isValidStudentUsername = false;
      }
    } else {
      toast(
        <GenericToast
          type='warning'
          title={t('students_management_edit_error')}
        />,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          className: 'generic-toast--error'
        }
      );
    }
    return isValidStudentUsername;
  };

  const validateFilledForm = () => {
    const fieldsToValidate = isPasswordEncrypted
      ? ['name', 'lastname', 'username']
      : ['name', 'lastname', 'username', 'password', 'confirmPassword'];

    const isValid = fieldsToValidate.every(
      (field) => formData[field].trim() !== ''
    );
    setIsFormValid(isValid);
  };

  const onValidateAndSaveStudent = async () => {
    let hasErrors = false;
    const newErrors = {
      name: '',
      lastname: '',
      username: '',
      password: '',
      confirmPassword: ''
    };

    // Validate empty fields
    Object.keys(formData).forEach((key) => {
      if (isPasswordEncrypted) {
        if (!formData[key] && key !== 'password' && key !== 'confirmPassword') {
          newErrors[key] = t('students_management_edit_error_empty_field');
          hasErrors = true;
        }
      } else {
        if (!formData[key]) {
          newErrors[key] = t('students_management_edit_error_empty_field');
          hasErrors = true;
        }
      }
    });

    // Always validate that passwords match
    if (formData.password !== formData.confirmPassword) {
      newErrors.password = t('students_management_edit_error_password_match');
      newErrors.confirmPassword = t(
        'students_management_edit_error_password_match'
      );
      hasErrors = true;
    }

    // Validate minimum length
    if (!isPasswordEncrypted) {
      // If the password is not encrypted, it must always have at least 4 characters
      if (formData.password.length < 4) {
        newErrors.password = t(
          'students_management_edit_error_password_length'
        );
        hasErrors = true;
      }
    } else {
      // If the password is encrypted, we only validate if something has been entered
      if (formData.password !== '' && formData.password.length < 4) {
        newErrors.password = t(
          'students_management_edit_error_password_length'
        );
        hasErrors = true;
      }
    }

    // Validate username
    if (!newErrors.username) {
      if (formData.username !== student.username) {
        const isValidUsername = await validateStudentUsername(
          formData.username
        );

        if (!isValidUsername) {
          newErrors.username = t(
            'students_management_edit_error_username_exists'
          );
          hasErrors = true;
        }
      }
    }

    setErrors(newErrors);

    if (!hasErrors) {
      const editStudentData = {
        userGuid: student.guid,
        name: formData.name,
        lastname: formData.lastname,
        username: formData.username,
        password: formData.password
      };

      onSave(editStudentData);
    }
  };

  return (
    <>
      <div
        className={`edit-students-form ${
          !isSaving ? 'edit-students-form--hidden' : ''
        }`}
      >
        <div className='edit-students-form__content'>
          <div className='edit-students-form__loading'>
            <CircularProgress size='48px' />
            <div className='edit-students-form__loading-text'>
              {t('students_management_editing_data')}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`edit-students-form ${
          isSaving ? 'edit-students-form--hidden' : ''
        }`}
      >
        <div className='edit-students-form__content'>
          <TDInputText
            name='name'
            label={t('students_management_name')}
            placeholder={t('students_management_name')}
            value={formData.name}
            onChange={handleChange}
            hasError={!!errors.name}
            errorText={errors.name}
          />
          <TDInputText
            name='lastname'
            label={t('students_management_lastname')}
            placeholder={t('students_management_lastname')}
            value={formData.lastname}
            onChange={handleChange}
            hasError={!!errors.lastname}
            errorText={errors.lastname}
          />
          <TDInputText
            name='username'
            label={t('students_management_username')}
            placeholder={t('students_management_username')}
            value={formData.username}
            onChange={handleChange}
            hasError={!!errors.username}
            errorText={errors.username}
          />
          <div
            className='edit-students-form__confirm-password-wrapper'
            onMouseEnter={() => setShowEncryptedMessage(true)}
            onMouseLeave={() => setShowEncryptedMessage(false)}
          >
            <TDInputText
              name='password'
              type='password'
              label={t('students_management_password')}
              placeholder={t('students_management_password')}
              value={formData.password}
              onChange={handleChange}
              hasError={!!errors.password}
              errorText={errors.password}
            />
            {isPasswordEncrypted && showEncryptedMessage && (
              <div className='edit-students-form__hover-message'>
                {t('students_management_edit_hidden_password')}
              </div>
            )}
          </div>
          <TDInputText
            name='confirmPassword'
            type='password'
            label={t('students_management_confirm_password')}
            placeholder={t('students_management_confirm_password')}
            value={formData.confirmPassword}
            onChange={handleChange}
            hasError={!!errors.confirmPassword}
            errorText={errors.confirmPassword}
            disabled={student?.password === formData.password}
          />
        </div>

        <div className='edit-students-form__actions'>
          <TDButton onClick={onCancel} variant='transparent'>
            {t('cancel')}
          </TDButton>

          <TDButton
            onClick={onValidateAndSaveStudent}
            color='primary'
            variant='outline'
            disabled={!isFormValid || isSaving}
          >
            {t('save')}
          </TDButton>
        </div>
      </div>
    </>
  );
};

export default EditStudentForm;
