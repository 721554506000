import { createContext, useContext, useMemo, useState } from 'react';

// context of add students form

const initialState = {
  isBackButtonVisible: false,
  areTabsVisible: true,
  selectedTab: 'manual',
  bulkImportStep: 0,
  setSelectedTab: () => {},
  setAreTabsVisible: () => {},
  setBulkImportStep: () => {},
  setIsBackButtonVisible: () => {}
};

const AddStudentsFormContext = createContext(initialState);

const AddStudentsFormProvider = ({ children }) => {
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
  const [areTabsVisible, setAreTabsVisible] = useState(true);
  const [selectedTab, setSelectedTab] = useState('manual');
  const [bulkImportStep, setBulkImportStep] = useState(0);
  const contextValue = useMemo(
    () => ({
      isBackButtonVisible,
      areTabsVisible,
      selectedTab,
      bulkImportStep,
      setIsBackButtonVisible,
      setAreTabsVisible,
      setSelectedTab,
      setBulkImportStep
    }),
    [
      isBackButtonVisible,
      areTabsVisible,
      selectedTab,
      bulkImportStep
      // Note: setter functions from useState are stable and don't need to be dependencies
    ]
  );

  return (
    <AddStudentsFormContext.Provider value={contextValue}>
      {children}
    </AddStudentsFormContext.Provider>
  );
};

const useAddStudentsForm = () => {
  const {
    isBackButtonVisible,
    areTabsVisible,
    selectedTab,
    bulkImportStep,
    setSelectedTab,
    setIsBackButtonVisible,
    setAreTabsVisible,
    setBulkImportStep
  } = useContext(AddStudentsFormContext);

  return {
    isBackButtonVisible,
    areTabsVisible,
    selectedTab,
    bulkImportStep,
    setSelectedTab,
    setIsBackButtonVisible,
    setAreTabsVisible,
    setBulkImportStep
  };
};

export { AddStudentsFormProvider, useAddStudentsForm };
