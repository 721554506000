import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnalysisSegment } from '../../../../utils/analysis';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import {
  checkForActiveSessions,
  deleteQuiz,
  getQuizzes
} from '../../../../utils/crud/quiz';
import useAnalytics from '../../../../utils/hooks/useAnalytics';
import QuizToast from '../viewQuiz/components/quizToast/QuizToast';

const useListQuizzes = () => {
  const { selectedCourse } = useCourse();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const [prevCourseGuid, setPreviousCourseGuid] = useState({ current: null });
  const [isLoading, setIsLoading] = useState(true);
  const [quizzes, setQuizzes] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeletingQuiz, setIsDeletingQuiz] = useState(false);
  const [isPlayDialogOpen, setIsPlayDialogOpen] = useState(false);
  const [selectedQuizGuid, setSelectedQuizGuid] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isActiveSessionBlockDialogOpen, setIsActiveSessionBlockDialogOpen] =
    useState(false);

  const toastSettings = {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true
  };

  const loadQuizzes = async (isStart = true) => {
    const response = await getQuizzes({ courseGuid: selectedCourse?.guid });

    // check if the response is an array, if not show an error. We show an error because even if there are no quizzes we still get an empty array
    if (!Array.isArray(response)) {
      toast.error(<QuizToast variant='quiz-generic-error' />, {
        ...toastSettings,
        autoClose: false
      });

      return;
    }
    const tempListOfQuizzes = response?.map((quiz) => ({
      guid: quiz?.guid,
      name: quiz?.name,
      lang: quiz?.lang?.id,
      numberOfActivities: quiz?.number_of_questions,
      hasResults: Boolean(quiz?.has_results),
      numberOfGames: quiz?.number_of_games
    }));

    setQuizzes(tempListOfQuizzes);
    if (isStart) {
      let totalGames = response?.reduce(
        (total, obj) => total + obj.number_of_games,
        0
      );
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Practice_Page_Viewed, {
        practices_created: response?.length || 0,
        practices_played: totalGames
      });
    }
    setIsLoading(false);
  };

  const onDeleteQuiz = (quizGuid) => {
    setSelectedQuizGuid(quizGuid);
    setIsDeleteDialogOpen(true);
    const quizToDelete = quizzes?.find((quiz) => quiz.guid === quizGuid);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Practice_Delete_Button_Clicked, {
      practice_id: quizGuid,
      number_of_activities: quizToDelete?.numberOfActivities,
      times_played: quizToDelete?.numberOfGames,
      number_of_students: selectedCourse?.users?.filter(
        (user) => user.role_guid === 'R01'
      ).length
    });
  };

  const onConfirmDeleteQuiz = async () => {
    setIsDeleteDialogOpen(false);
    setIsDeletingQuiz(true);
    if (!selectedQuizGuid) return;
    await deleteQuiz({ quizGuid: selectedQuizGuid });
    await loadQuizzes(false);

    const quizToDelete = quizzes?.find(
      (quiz) => quiz.guid === selectedQuizGuid
    );

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Practice_Deleted, {
      practice_id: selectedQuizGuid,
      number_of_activities: quizToDelete?.numberOfActivities,
      times_played: quizToDelete?.numberOfGames,
      number_of_students: selectedCourse?.users?.filter(
        (user) => user.role_guid === 'R01'
      ).length
    });

    setIsDeletingQuiz(false);
    toast(<QuizToast variant='quiz-deleted-successfully' />, toastSettings);
  };

  const onCancelDeleteQuiz = () => {
    setIsDeleteDialogOpen(false);
    setSelectedQuizGuid(null);
    setIsDeletingQuiz(false);
  };

  const onPlayQuiz = async ({ quizGuid, hasResults }) => {
    // check for active sessions
    const activeSessions = await checkForActiveSessions({
      courseGuid: selectedCourse?.guid
    });

    if (activeSessions?.hasActiveGame) {
      setIsActiveSessionBlockDialogOpen(true);
    } else if (hasResults) {
      setSelectedQuizGuid(quizGuid);
      setIsPlayDialogOpen(true);
    } else {
      navigate(`/${selectedCourse?.guid}/quizzes/play/${quizGuid}`);
    }
  };

  const onConfirmPlayQuiz = () => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Practice_Play_Button_Clicked, {
      practice_id: selectedQuizGuid
    });

    navigate(`/${selectedCourse?.guid}/quizzes/play/${selectedQuizGuid}`);
    setIsPlayDialogOpen(false);
    setSelectedQuizGuid(null);
  };

  const onCancelPlayQuiz = () => {
    setIsPlayDialogOpen(false);
    setSelectedQuizGuid(null);
  };

  // This function checks in localStorage for the flag that indicates that a quiz has been deleted from the quiz page.
  // We use this method to confirm to the user that the quiz has been deleted successfully. Silent success messages are not user-friendly.
  const checkIfDeletedQuizFlagExists = () => {
    const deletedQuizFlag = localStorage.getItem('deletedQuizFlag');
    if (deletedQuizFlag) {
      toast(<QuizToast variant='quiz-deleted-successfully' />, toastSettings);
      localStorage.removeItem('deletedQuizFlag');
    }
  };

  useEffect(() => {
    if (!selectedCourse || prevCourseGuid === selectedCourse?.guid) return;
    setPreviousCourseGuid(selectedCourse.guid);
    checkIfDeletedQuizFlagExists();
    loadQuizzes(true);
  }, [selectedCourse]);

  const onConfirmEditName = async ({ name }) => {
    setIsEditingName(true);

    loadQuizzes();

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Practice_Name_Edited, {
      practice_id: selectedQuizGuid,
      practice_name: name,
      number_of_students: selectedCourse?.users?.filter(
        (user) => user.role_guid === 'R01'
      ).length,
      number_of_activities: selectedQuiz?.numberOfActivities
    });

    setIsEditingName(false);
    setIsEditNameDialogOpen(false);
    setSelectedQuizGuid(null);
    setSelectedQuiz(null);
  };

  const onEditName = ({ quizGuid }) => {
    setIsEditNameDialogOpen(true);
    setSelectedQuizGuid(quizGuid);
    setSelectedQuiz(quizzes.find((quiz) => quiz.guid === quizGuid));
  };

  const onCancelEditName = () => {
    setIsEditNameDialogOpen(false);
  };

  const onCloseActiveSessionBlockDialog = () => {
    setIsActiveSessionBlockDialogOpen(false);
  };

  const onClickOnCreateQuiz = () => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.New_Practice_Button_Clicked);
    navigate(`/${selectedCourse?.guid}/quizzes/create`);
  };

  return {
    quizzes,
    isLoading,
    selectedQuiz,
    isEditingName,
    isDeletingQuiz,
    selectedQuizGuid,
    isPlayDialogOpen,
    isDeleteDialogOpen,
    isEditNameDialogOpen,
    isActiveSessionBlockDialogOpen,
    onDeleteQuiz,
    onEditName,
    onPlayQuiz,
    onConfirmPlayQuiz,
    onCancelPlayQuiz,
    onConfirmEditName,
    onCancelEditName,
    onConfirmDeleteQuiz,
    onCancelDeleteQuiz,
    onClickOnCreateQuiz,
    onCloseActiveSessionBlockDialog
  };
};

export default useListQuizzes;
