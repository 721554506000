export const formatTimeFromSeconds = (seconds, alwaysShowSeconds = false) => {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Build the formatted time string
  let formattedTime = '';
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  if (
    remainingSeconds > 0 ||
    (hours === 0 && minutes === 0) ||
    alwaysShowSeconds
  ) {
    formattedTime += `${remainingSeconds}s`;
  }

  return formattedTime;
};
