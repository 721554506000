import { localStorageKeys } from '../localStorageKeys';

export const getBBTeacherUserLanguage = () => {
  const bbUserLanguage = localStorage.getItem(localStorageKeys.language);

  const navigatorLang = navigator.language.split('-')[0];

  const defaultLanguage =
    bbUserLanguage && bbUserLanguage !== 'null'
      ? bbUserLanguage
      : navigatorLang;

  return defaultLanguage;
};
