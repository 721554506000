import { ClickAwayListener } from '@mui/base';
import { Fade, Popper } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../Table';
import './CellStatusChip.scss';
import CellWithOneLineOfText from './CellWithOneLineOfText';

const CellStatusChip = ({ status, LOData }) => {
  const columnHelper = createColumnHelper();
  const [anchorEl, setAnchorEl] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const onClickAway = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const color =
    status === 'needHelp'
      ? 'danger'
      : status === 'needMotivation'
      ? 'warning'
      : status === 'forgetting' || status === 'todo'
      ? 'warning'
      : status === 'inProgress' || status === 'relearning'
      ? 'info'
      : status === 'mastered' || status === 'done'
      ? 'success'
      : 'default';

  return (
    <div className={`cell__container cell--status`}>
      <span
        className={`cell--status-${color} ${
          LOData && LOData.length > 0 && 'cell--status-clickable'
        }`}
        onClick={(e) => {
          if (!LOData || LOData.length === 0) return;

          setAnchorEl(e.currentTarget);
          setIsOpen(true);
        }}
      >
        {t(status)}
      </span>

      {isOpen && (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popper
            id={isOpen && Boolean(anchorEl) ? 'simple-popper' : undefined}
            open={isOpen}
            disablePortal={false}
            placement='right'
            anchorEl={anchorEl}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 16]
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  padding: { top: 5, bottom: 5, left: 70, right: 5 }
                }
              }
            ]}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div>
                  <Table
                    className={'table--popup'}
                    data={LOData}
                    columns={[
                      columnHelper.accessor(t('learning_objective'), {
                        header: t('Content'),
                        id: t('learning_objective'),
                        enableSorting: false,
                        cell: (info) => (
                          <CellWithOneLineOfText
                            text={
                              info.row.original.description
                                ? info.row.original.description
                                : t('no_data')
                            }
                          />
                        )
                      }),
                      columnHelper.accessor(t('status'), {
                        header: t('status'),
                        id: t('status'),
                        enableSorting: false,
                        cell: (info) => (
                          <CellStatusChip status={info.row.original.status} />
                        )
                      })
                    ]}
                  />
                </div>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CellStatusChip;
