import { useTranslation } from 'react-i18next';
import { TDTypography } from '../../../../../atoms/TDUILib';
import './listQuizzesTooltip.scss';

const ListQuizzesTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className='list-quizzes-tooltip'>
      <div>
        <TDTypography variant='body-m' fontWeight='bold'>
          {t('page_tooltip.list_quizzes_overview')}
        </TDTypography>
      </div>
      <div>
        <TDTypography variant='body-m' fontWeight='medium'>
          {t('page_tooltip.list_quizzes_info')}
        </TDTypography>
      </div>
    </div>
  );
};

export default ListQuizzesTooltip;
