import { localStorageKeys } from '../localStorageKeys';
import { tryJsonParse } from './tryJsonParse';

export const checkIfUserHasVisitedPageBefore = (whichPage) => {
  let sessionManager = localStorage.getItem(
    localStorageKeys.toastSessionManager
  );
  if (sessionManager) {
    try {
      sessionManager = tryJsonParse(sessionManager);
      if (typeof sessionManager === 'object') {
        if (sessionManager[whichPage]) {
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      sessionManager = {};
      return false;
    }
  } else {
    return false;
  }

  return false;
};

export const markUserAsVisitedPage = (whichPage) => {
  let sessionManager = localStorage.getItem(
    localStorageKeys.toastSessionManager
  );
  if (sessionManager) {
    sessionManager = tryJsonParse(sessionManager);
    if (typeof sessionManager !== 'object') {
      sessionManager = {};
    }
  } else {
    sessionManager = {};
  }

  localStorage.setItem(
    localStorageKeys.toastSessionManager,
    JSON.stringify({
      ...sessionManager,
      [whichPage]: true
    })
  );
};
