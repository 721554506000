import AddIcon from '@mui/icons-material/Add';
import {
  CircularProgress,
  Fab,
  Skeleton,
  Stack,
  ThemeProvider
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAssetURL from '../../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../../utils/hooks/useAuth';
import QuizConfirmDeleteDialog from '../../../components/QuizConfirmDeleteDialog';
import quizTheme from '../../../components/QuizTheme';
import QuizQuestionCard from '../quizQuestionCard/QuizQuestionCard';
import './QuizQuestions.scss';

const QuizQuestions = ({
  questions = [],
  badge = null,
  readOnly = false,
  studentResponses,
  onRegenerateQuestion,
  questionBeingRegenerated,
  isRegeneratingQuestion,
  onDeleteQuestion = () => {},
  isDeletingQuestion,
  allowCreateAndAddQuestions,
  onAddQuestion,
  isAddingQuestion,
  isLoading,
  onChangePositionOfQuestion,
  startsWithValuesStandards
}) => {
  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();

  const [selectedQuestionGuidToDelete, setSelectedQuestionGuidToDelete] =
    useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const onClickOnDelete = ({ questionGuid }) => {
    setIsDeleteDialogOpen(true);
    setSelectedQuestionGuidToDelete(questionGuid);
  };

  const onCancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedQuestionGuidToDelete(null);
  };

  const onConfirmDeleteDialog = () => {
    onDeleteQuestion({ questionGuid: selectedQuestionGuidToDelete });
    setIsDeleteDialogOpen(false);
  };

  const noQuestionsImage = generateAssetURL({
    asset: 'calculator_avatar.png',
    schoolAssetsPath
  });

  if (isLoading)
    return (
      <ThemeProvider theme={quizTheme}>
        <div className='quiz-questions'>
          <Stack alignItems='center' spacing={3}>
            <div />
            <Skeleton variant='image' width={'100%'} height={300} />
            <Skeleton variant='image' width={'100%'} height={300} />
            <Skeleton variant='image' width={'100%'} height={300} />
          </Stack>
        </div>
      </ThemeProvider>
    );

  if (questions.length === 0) {
    return (
      <ThemeProvider theme={quizTheme}>
        <div className='quiz-questions'>
          <Stack alignItems='center'>
            <div className='quiz-questions__no-questions-container'>
              <div className='quiz-questions__no-questions'>
                <img src={noQuestionsImage} alt='No questions' />
                <div className='quiz-questions__no-questions-text'>
                  {t('quiz_no_activities')}
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={quizTheme}>
      <QuizConfirmDeleteDialog
        isOpen={isDeleteDialogOpen}
        onCancel={onCancelDeleteDialog}
        onDelete={onConfirmDeleteDialog}
        title={t('delete_quiz_activity_dialog_title')}
        description={t('delete_quiz_activity_dialog_description')}
      />
      <div className='quiz-questions'>
        {questions.map((question, index) => (
          <QuizQuestionCard
            badge={badge}
            guid={question.guid}
            key={question.guid}
            readOnly={readOnly}
            question={question.question}
            score={question.score}
            unit={question?.unit}
            questionNumber={index + 1}
            lesson={question?.lesson}
            educationYear={question?.educationYearTag}
            standards={question.standards.filter((standard) =>
              startsWithValuesStandards.some((value) =>
                standard.name.startsWith(value)
              )
            )}
            onRegenerateQuestion={onRegenerateQuestion}
            questionBeingRegenerated={questionBeingRegenerated}
            isRegeneratingQuestion={isRegeneratingQuestion}
            allowShowSolution={!Boolean(question.score)}
            onDeleteQuestion={onClickOnDelete}
            isDeletingQuestion={isDeletingQuestion}
            questionBeingDeleted={selectedQuestionGuidToDelete}
            studentResponse={
              (studentResponses && studentResponses[index]) || []
            }
            isLastQuestion={index === questions.length - 1}
            isFirstQuestion={index === 0}
            onChangePositionOfQuestion={onChangePositionOfQuestion}
            studentsWithWrongAnswers={question?.studentsWithWrongAnswers}
            studentsWithCorrectAnswers={question?.studentsWithCorrectAnswers}
          />
        ))}
      </div>
      {allowCreateAndAddQuestions && (
        <div className='quiz-questions__add'>
          <Fab
            color='primary'
            aria-label='add'
            onClick={onAddQuestion}
            disabled={isAddingQuestion}
          >
            {isAddingQuestion ? <CircularProgress /> : <AddIcon />}
          </Fab>
        </div>
      )}
    </ThemeProvider>
  );
};

QuizQuestions.propTypes = {
  questions: PropTypes.array,
  badge: PropTypes.string,
  readOnly: PropTypes.bool,
  studentResponses: PropTypes.array,
  onDeleteQuestion: PropTypes.func
};

export default QuizQuestions;
