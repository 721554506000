import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../atoms/icon';
import CellWithLabelAndProgressBar from '../../../../../../components/table/cell/cellTypes/CellWithLabelAndProgressBar';
import CellWithTwoFontWeights from '../../../../../../components/table/cell/cellTypes/CellWithTwoFontWeights';
import generateAssetURL from '../../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../../utils/hooks/useAuth';
import { StandardChip } from '../../../CreateQuiz/components/standardChips/StandardChips';
import QuizBadge from '../quizBadge/QuizBadge';
import QuizResultsTableRow from '../quizResultsTableRow/QuizResultsTableRow';
import './QuizResultsTable.scss';

const QuizResultsTable = ({ results, onOpenDetailedView }) => {
  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();

  const noResultsImage = generateAssetURL({
    asset: 'calculator_avatar.png',
    schoolAssetsPath
  });

  if (results.length === 0) {
    return (
      <div className='quiz-results-table'>
        <div className='quiz-results-table__no-results'>
          <img
            src={noResultsImage}
            alt='No results'
            className='quiz-results-table__no-results-image'
          />
          <div className='quiz-results-table__no-results-text'>
            {t('no_results')}
          </div>
        </div>
      </div>
    );
  }

  const isTopic = results[0]?.topic;
  const isStandard = results[0]?.standard;

  return (
    <div className='quiz-results-table'>
      {results.map((result) => (
        <QuizResultsTableRow
          key={result.guid}
          index={result?.index || result?.numActivity}
          indexStyle={result?.numActivity ? 'bullet' : 'default'}
          left={
            isTopic ? (
              <QuizBadge
                tooltip={result.topic + ' → ' + result.subtopic}
                label={result.subtopic}
                badge={result.level}
                fullWidth
              />
            ) : isStandard ? (
              <StandardChip
                name={result?.standard?.name}
                description={result?.standard?.description}
              />
            ) : (
              <CellWithTwoFontWeights
                bold={result.name}
                {...(result?.points
                  ? { regular: result.points + ' ' + t('quiz_points') }
                  : {})}
                align={'column'}
                singleLine
              />
            )
          }
          center={result.activities}
          right={
            <CellWithLabelAndProgressBar
              label={Math.ceil(result.progress * 100) + '%'}
              progress={result.progress}
              color={result.color}
              disableHover
            />
          }
          end={
            <div
              className='quiz-results-table__view'
              onClick={() => onOpenDetailedView(result.guid)}
            >
              <Icon type='eye' color='secondary' />
            </div>
          }
        />
      ))}
    </div>
  );
};

QuizResultsTable.propTypes = {
  results: PropTypes.array
};

export default QuizResultsTable;
