// useDragAndDrop.ts

import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const useTDDragAndDrop = ({ acceptedTypes, maxFileSize, onChange }) => {
  const { t } = useTranslation();

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isFileDroppedSuccessfully, setIsFileDroppedSuccessfully] =
    useState(false);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      // file-invalid-type
      // file-too-large
      console.log(acceptedFiles);
      console.log(rejectedFiles);
      onChange(acceptedFiles);

      const errorMessages = rejectedFiles.map((rejectedFile) => {
        const acceptedTypesValues = Object.values(acceptedTypes).join(', ');

        return rejectedFile.errors
          .map((error) => {
            if (error.code === 'file-invalid-type') {
              return t('drag_and_drop_error_file_invalid_type_message', {
                acceptedTypes: acceptedTypesValues
              });
            }
            if (error.code === 'file-too-large') {
              return t('drag_and_drop_error_file_too_large_message', {
                maxFileSize: maxFileSize / 1024 / 1024
              });
            }
            return '';
          })
          .filter((error) => error !== '')
          .join(', ');
      });

      setHasError(rejectedFiles.length > 0);
      setErrorMessage(errorMessages.join(', '));
      setIsFileDroppedSuccessfully(acceptedFiles.length > 0);
      setAcceptedFiles(acceptedFiles);
    },
    [acceptedTypes]
  );

  const onClearFiles = () => {
    setIsFileDroppedSuccessfully(false);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog
  } = useDropzone({
    noClick: true,
    onDrop,
    multiple: false,
    accept: acceptedTypes,
    maxSize: maxFileSize
  });

  return {
    rootProps: getRootProps(),
    inputProps: getInputProps(),
    isDragActive,
    hasError,
    errorMessage,
    isFileDroppedSuccessfully,
    onClearFiles,
    openFileDialog,
    acceptedFiles
  };
};

export default useTDDragAndDrop;
