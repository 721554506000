import axios from 'axios';
import { getToken } from './token';

import { tryJsonParse } from '../helpers/tryJsonParse';
import { localStorageKeys } from '../localStorageKeys';

const { origin } = window.location;
const isLocalhost = origin.includes('localhost');

const teacherAPI = isLocalhost
  ? 'https://dashboard.school.blueberry-qa.oneclicklabs.es/api/'
  : origin + '/api/';

/**
 * @param {string} method - The HTTP method to use
 * @param {object} data - The data to send to the API
 * @param {string} path - The API endpoint
 * @param {object} config - The axios config
 * @returns {object} - The response data from the API
 */
const fetcher = async ({
  method = 'GET',
  data,
  path,
  config = {},
  disableCatchHandling,
  defaultLang = true
}) => {
  const getLanguageSettings = () => {
    const fullLanguageNavigator = navigator.language || navigator.userLanguage;
    const languageNavigator = fullLanguageNavigator.split('-')[0];

    const userSettings = localStorage.getItem(localStorageKeys.userSettings);
    const userSettingsLanguage = tryJsonParse(userSettings)?.previewLanguage;

    // Get the language directly from localStorage
    let languageSetting = localStorage.getItem(localStorageKeys.language);

    // Check if languageSetting is valid
    if (
      !languageSetting ||
      languageSetting === 'null' ||
      languageSetting === 'undefined'
    ) {
      languageSetting = undefined;
    }

    // Determine the language to use
    const settings =
      userSettingsLanguage || languageSetting || languageNavigator || undefined;
    return settings;
  };

  // Always pass the lang parameter in the url
  // This overrides previous lang configurations!
  const addLangParam = (path) => {
    const url = new URL(path, teacherAPI);
    url.searchParams.set('lang', getLanguageSettings());

    const pathname = url.pathname.startsWith('/api')
      ? url.pathname.replace('/api', '')
      : url.pathname;

    return pathname + url.search;
  };

  return axios({
    baseURL: teacherAPI,
    url: defaultLang ? addLangParam(path) : path,
    method,
    data,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      ...config?.headers
    }
  })
    .then((response) => {
      if (response?.status === 200 || response?.status === 'success') {
        return response.data;
      }
    })
    .catch((error) => {
      // Check for network errors
      if (!error.response) {
        if (error.code === 403) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        }
        if (error.code === 404) {
          setTimeout(() => {
            window.location.href = '/error404';
          }, 500);
        }
        if (error.code === 500) {
          setTimeout(() => {
            window.location.href = '/error500';
          }, 500);
        }
        return { error: 'Network error', errorCode: error.code };
      }

      if (disableCatchHandling) {
        return { ...error?.response?.data, errorCode: error?.response?.status };
      }

      // If the token is invalid, redirect to login
      if (error.response.status === 403 || error.response.status === 401) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
      }

      if (error.response.status === 404) {
        setTimeout(() => {
          window.location.href = '/error404';
        }, 500);
      }

      if (error.response.status === 500) {
        setTimeout(() => {
          window.location.href = '/error500';
        }, 500);
      }

      return error.response;
    });
};

export const fetchWithToken = async ({
  method,
  data,
  path,
  config = {},
  defaultLang
}) => {
  if (getToken().length === 0) {
    throw { error: 'no token' };
  }

  const headerAuthorization = {
    Authorization: getToken()
  };

  return fetcher({
    method,
    data,
    path,
    config: {
      ...config,
      headers: {
        ...headerAuthorization,
        ...config.headers
      }
    },
    defaultLang
  });
};

export const fetchWithoutToken = async ({
  method,
  data,
  path,
  config = {},
  disableCatchHandling,
  defaultLang
}) =>
  fetcher({ method, data, path, config, disableCatchHandling, defaultLang });
