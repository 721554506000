import { Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuizBadge from '../quizBadge/QuizBadge';
import './QuizDetails.scss';

const QuizDetails = ({
  name = 'Quiz Name',
  numberOfActivities = 0,
  subTopics = [],
  lang = '-',
  isLoading = false
}) => {
  const { t } = useTranslation();

  const MINIMUM_BADGES_TO_SHOW = 4;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [itemsToShow, setItemsToShow] = useState(MINIMUM_BADGES_TO_SHOW);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    if (isCollapsed) {
      setItemsToShow(MINIMUM_BADGES_TO_SHOW);
    } else {
      setItemsToShow(lessons.length * 2);
    }
  }, [isCollapsed, lessons]);

  useEffect(() => {
    // order subTopics by education year ascending and then by name
    const orderedSubTopics = subTopics.sort((a, b) => {
      const aEducationYear = parseInt(a.educationYear.replace(/\D/g, '')) || 0;
      const bEducationYear = parseInt(b.educationYear.replace(/\D/g, '')) || 0;
      return aEducationYear - bEducationYear || a.name.localeCompare(b.name);
    });

    setLessons(orderedSubTopics);
  }, [subTopics]);

  return (
    <div className='quiz-details-card__container'>
      <div className='quiz-details-card__header'>
        <Stack direction='column' alignItems='flex-start' mb={'12px'}>
          <Stack direction='row' mb={'12px'}>
            <Typography
              sx={{ marginRight: '12px' }}
              {...(isLoading ? {} : { variant: 'langTag' })}
            >
              {isLoading ? (
                <Skeleton variant='text' width={40} height={40} />
              ) : (
                lang
              )}
            </Typography>

            <Typography variant='subtitle1'>
              {isLoading ? (
                <Skeleton variant='text' width={80} height={40} />
              ) : (
                `${numberOfActivities} ${t('Activity', {
                  count: numberOfActivities
                })}`
              )}
            </Typography>
          </Stack>
          {isLoading ? (
            <Skeleton variant='text' width={200} height={40} />
          ) : (
            <Typography variant='h2'>{name}</Typography>
          )}
        </Stack>
      </div>
      <div className='quiz-details-card__content'>
        <div className='quiz-details-card__details-container'>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={40} />
          ) : (
            <div>
              <div className='quiz-details-card__details--topics-container'>
                {lessons.slice(0, itemsToShow).map((lesson) => (
                  <div
                    className='quiz-details-card__details--topics-container--badge'
                    key={lesson.guid}
                  >
                    <QuizBadge
                      label={lesson.name}
                      tooltip={`${lesson.unitName} → ${lesson.name}`}
                      badge={lesson.educationYear}
                    />
                  </div>
                ))}
                {lessons.length > MINIMUM_BADGES_TO_SHOW && (
                  <div
                    className='quiz-details-card__details--collapse-button'
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    {isCollapsed ? (
                      <span>
                        {`+${lessons.length - MINIMUM_BADGES_TO_SHOW}`}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

QuizDetails.propTypes = {
  name: PropTypes.string,
  educationYear: PropTypes.string,
  numberOfActivities: PropTypes.number,
  subTopics: PropTypes.array,
  lang: PropTypes.string,
  isLoading: PropTypes.bool
};

export default QuizDetails;
