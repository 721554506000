import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TDButton from '../../../../../../atoms/TDButton/TDButton';
import './deleteStudentForm.scss';

const DeleteStudentForm = ({ onCancel, onDelete, isDeleting }) => {
  const { t } = useTranslation();
  return isDeleting ? (
    <div className='delete-student-form'>
      <div className='delete-student-form__content'>
        <div className='delete-student-form__loading'>
          <CircularProgress size='48px' />
          <div className='delete-student-form__loading-text'>
            {t('students_management_deleting_student')}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='delete-student-form'>
      <div className='delete-student-form__content'>
        <div className='delete-student-form__info'>
          {t('students_management_delete_student_info')}
        </div>
      </div>
      <div className='delete-student-form__actions'>
        <TDButton onClick={onCancel} variant='transparent'>
          {t('cancel')}
        </TDButton>

        <TDButton onClick={onDelete} variant='outline' color='primary'>
          {t('delete')}
        </TDButton>
      </div>
    </div>
  );
};

export default DeleteStudentForm;
