import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CellWithLabelAndProgressBar from '../../../../components/table/cell/cellTypes/CellWithLabelAndProgressBar';
import CellWithTitleAndSubtitle from '../../../../components/table/cell/cellTypes/CellWithTitleAndSubtitle';
import CellWithTwoFontWeights from '../../../../components/table/cell/cellTypes/CellWithTwoFontWeights';
import ColumnTitleWithTooltip from '../../../../components/table/cell/cellTypes/ColumnTitleWithTooltip';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import { getCourseUnitsWithStatus } from '../../../../utils/crud/content';
import { handleNaN } from '../../../../utils/helpers/handleNaN';

const useContentsUnitsAnalysis = () => {
  const columnHelper = createColumnHelper();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedCourse } = useContext(CourseContext);

  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [columns] = useState([
    columnHelper.accessor('unit', {
      header: t('Units'),
      id: 'units',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTitleAndSubtitle
          title={info.row.original.unit}
          aboveTitle={info.row.original.knowledgeComponent}
        />
      )
    }),
    columnHelper.accessor('numberOfStudentsFraction', {
      header: (
        <ColumnTitleWithTooltip
          title={t('accessed')}
          tooltip={t('accessed_tooltip')}
        />
      ),
      id: 'numberOfStudentsFraction',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTwoFontWeights
          bold={info.row.original.numberOfStudentsFraction}
          regular={t(info.row.original.students + '_lowercase')}
        />
      )
    }),
    columnHelper.accessor('achievedProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('MASTERED')}
          tooltip={t('page_tooltip.mastered_info')}
        />
      ),
      id: 'achievedProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.achieved}
          progress={info.row.original.achievedProgress}
          color='success'
        />
      )
    }),
    columnHelper.accessor('inProgressProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('PROGRESS')}
          tooltip={t('page_tooltip.in_progress_info')}
        />
      ),
      id: 'inProgressProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.inProgress}
          progress={info.row.original.inProgressProgress}
          color='info'
        />
      )
    }),
    columnHelper.accessor('needsHelpProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('HELP')}
          tooltip={t('page_tooltip.needs_help_info')}
        />
      ),
      id: 'needsHelpProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.needsHelp}
          progress={info.row.original.needsHelpProgress}
          color='warning'
        />
      )
    }),
    columnHelper.accessor('needsEncouragementProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('MOTIVATION')}
          tooltip={t('page_tooltip.difficulties_info')}
        />
      ),
      id: 'needsEncouragementProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.needsEncouragement}
          progress={info.row.original.needsEncouragementProgress}
          color='danger'
        />
      )
    })
  ]);

  const getData = async () => {
    if (isLoading) return;

    setIsLoading(true);
    let tempData = await getCourseUnitsWithStatus({
      courseGuid: selectedCourse.guid,
      ...(searchParams.get('unit')
        ? { unitGuid: searchParams.get('unit') }
        : {})
    });

    const apiUnits = tempData?.units || [];

    const parsedData = apiUnits.map((unit) => {
      const totalStudents = tempData?.totalStudents;

      return {
        lessonGuid: unit?.lessonGuid,
        knowledgeComponent: unit?.unitName,
        unit: unit?.lessonName,
        numberOfStudentsFraction: `${unit?.students}/${totalStudents}`,
        students: 'students',

        achieved:
          handleNaN(parseInt((100 * unit?.mastered) / totalStudents)) + '%',
        achievedProgress: handleNaN(unit?.mastered / totalStudents),
        achievedColor: 'success',

        inProgress:
          handleNaN(parseInt((100 * unit?.inProgress) / totalStudents)) + '%',
        inProgressProgress: handleNaN(unit?.inProgress / totalStudents),
        inProgressColor: 'info',

        needsHelp:
          handleNaN(parseInt((100 * unit?.needHelp) / totalStudents)) + '%',
        needsHelpProgress: handleNaN(unit?.needHelp / totalStudents),
        needsHelpColor: 'warning',

        needsEncouragement:
          handleNaN(parseInt((100 * unit?.needMotivation) / totalStudents)) +
          '%',
        needsEncouragementProgress: handleNaN(
          unit?.needMotivation / totalStudents
        ),
        needsEncouragementColor: 'danger'
      };
    });
    setData(parsedData);
    setIsLoading(false);
  };

  const onClickOnUnit = (unit) => {
    navigate(`/${selectedCourse?.guid}/content/units/${unit.lessonGuid}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPreloading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (isPreloading || !selectedCourse?.guid) return;
    getData();
  }, [isPreloading, selectedCourse, searchParams.get('unit')]);

  return {
    data,
    columns,
    isLoading: isLoading || isPreloading,
    onClickOnUnit
  };
};

export default useContentsUnitsAnalysis;
