import { useTranslation } from 'react-i18next';
import {
  TDDragAndDrop,
  TDHeaderButton,
  TDHeading
} from '../../../../../../../../atoms/TDUILib';
import { useAddStudentsForm } from '../../useAddStudentsForm';

const BulkStudentImportFormStepOne = ({
  setIsFileUploaded,
  setIsFileValid,
  setData
}) => {
  const { t, i18n } = useTranslation();
  const { setIsBackButtonVisible, setAreTabsVisible, setBulkImportStep } =
    useAddStudentsForm();

  const validateData = (files) => {
    // read content of file
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const content = e.target.result;

      // convert csv to json after detecting the start row which is the first row that contains Name or Nome or Nombre or default to row 9 and if not possible to detect
      try {
        const csvContent = content.split('\n').map((row) => row.split(','));
        let startRow = csvContent.findIndex((row) =>
          ['Name', 'Nome', 'Nombre', 'name', 'nome', 'nombre'].some((keyword) =>
            row.includes(keyword)
          )
        );
        if (startRow === -1) {
          startRow = 8;
        }

        const data = csvContent.slice(startRow + 1).map((row) => {
          // check if the row is empty and skip it. This includes checking if the cell has a space or a tab.
          if (row.every((cell) => cell?.trim() === '')) {
            return null;
          }
          const name = row[0]?.trim();
          const lastname = row[1]?.trim();
          return {
            name: name,
            lastname: lastname,
            error: !name || !lastname
          };
        });

        // remove null values from the data which are empty rows
        const filteredData = data.filter((row) => row !== null);

        // flag if there are empty values in the data
        const hasEmptyValues = filteredData.some((row) => row.error);

        setData(filteredData);
        setIsFileValid(!hasEmptyValues && data.length > 0);
      } catch (error) {
        console.error(error);
      }
    };
    fileReader.readAsText(files[0]);
  };

  const onClearFiles = () => {
    setIsFileUploaded(false);
    setIsFileValid(false);
    setBulkImportStep(0);
    setIsBackButtonVisible(false);
    setAreTabsVisible(true);
  };

  const onAddFiles = (files) => {
    setIsFileUploaded(true);
    validateData(files);
  };

  const acceptedTypes = {
    'text/csv': ['.csv']
  };

  const maxFileSize = 10 * 1024 * 1024;

  const downloadFile = () => {
    const templateContent = {
      en: {
        path: '/assets/csv/BlueberryMath - User creation template.csv',
        name: 'BlueberryMath - User creation template.csv'
      },
      es: {
        path: '/assets/csv/BlueberryMath - Plantilla para crear usuarios.csv',
        name: 'BlueberryMath - Plantilla para crear usuarios.csv'
      },
      pt: {
        path: '/assets/csv/BlueberryMath - Modelo de criação de usuários.csv',
        name: 'BlueberryMath - Modelo de criação de usuários.csv'
      }
    };

    const detectedLanguage = i18n.resolvedLanguage.toLowerCase() || 'es';
    window.open(templateContent[detectedLanguage].path, '_blank');
  };

  return (
    <>
      <div className='td-bulk-student-import-form--step-container'>
        <div className='td-bulk-student-import-form--step-container-header'>
          <TDHeading variant='h5'>
            <span className='muted'>{`${t(
              'students_management_add_students_import_step',
              {
                stepNumber: 1
              }
            )} `}</span>
            <span>
              {t('students_management_add_students_import_download_template')}
            </span>
          </TDHeading>
          <div className='td-bulk-student-import-form--description'>
            {t(
              'students_management_add_students_import_download_template_description'
            )}
          </div>
        </div>
        <div className='td-bulk-student-import-form--download-template-container'>
          <div>
            <div className='template-file--name'>
              {`${t('students_management_add_students_import_template')} ${t(
                'students_management_add_students_import_template_name'
              )}`}
            </div>
            <div className='template-file--size'>.csv 0.2KB</div>
          </div>
          <div>
            <TDHeaderButton onClick={downloadFile} border small>
              {t('students_management_add_students_import_download')}
            </TDHeaderButton>
          </div>
        </div>
      </div>
      <div className='td-bulk-student-import-form--step-container'>
        <div className='td-bulk-student-import-form--step-container-header'>
          <TDHeading variant='h5'>
            <span className='muted'>
              {t('students_management_add_students_import_step', {
                stepNumber: 2
              })}
            </span>{' '}
            <span>
              {t('students_management_add_students_import_upload_file')}
            </span>
          </TDHeading>
          <div className='td-bulk-student-import-form--description'>
            {t(
              'students_management_add_students_import_upload_file_description'
            )}
          </div>
        </div>
        <div>
          <TDDragAndDrop
            acceptedTypes={acceptedTypes}
            maxFileSize={maxFileSize} // 10 MB
            onChange={onAddFiles}
            onClear={onClearFiles}
          />
        </div>
      </div>
    </>
  );
};

export default BulkStudentImportFormStepOne;
