import { useState } from 'react';
import { TDNavigator } from '../TDUILib';
import './TDTabs.scss';

const TDTabs = ({
  tabs = [],
  selectedTab,
  onSelectTab = () => {},
  orientation = 'horizontal' // horizontal, vertical
}) => {
  const [tabSelected, setTabSelected] = useState(selectedTab || tabs[0].id);

  const handleSelectTab = (tabId) => {
    setTabSelected(tabId);
    onSelectTab(tabId);
  };

  return (
    <div className='td-tabs'>
      <div className={`td-tabs__container td-tabs__container--${orientation}`}>
        {tabs.map((tab) => (
          <div className='td-tabs__tab'>
            <TDNavigator
              key={tab.id}
              onClick={() => handleSelectTab(tab.id)}
              active={tabSelected === tab.id}
            >
              {tab.label}
            </TDNavigator>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TDTabs;
