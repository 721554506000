import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const QuizBlockedByActiveSessionDialog = ({
  title = '',
  description = '',
  onClose = () => {},
  isOpen = false
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <Typography variant='h3' color={theme.palette.grey[200]}>
              {title}
            </Typography>
            <Typography color={theme.palette.grey[200]}>
              {description}
            </Typography>
          </Stack>
          <Stack spacing={3} direction='row' justifyContent='flex-end'>
            <Button
              onClick={onClose}
              variant='contained'
              color='primary'
              disableElevation
            >
              {t('play_quiz_activity_dialog_close')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

QuizBlockedByActiveSessionDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default QuizBlockedByActiveSessionDialog;
