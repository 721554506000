import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '../../../../../assets/icons';
import { TDHeaderButton } from '../../../../../atoms/TDUILib';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../utils/hooks/useAuth';
import './emptyStudentAnalysis.scss';

const EmptyStudentAnalysis = () => {
  const { schoolAssetsPath } = useAuth();
  const { selectedCourse } = useCourse();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const avatarPath = generateAssetURL({
    schoolAssetsPath,
    asset: 'avatar_searching.svg'
  });

  const onAddStudents = () => {
    navigate(`/${selectedCourse?.guid}/students/management`, {
      state: {
        action: 'open-add-students-popup'
      }
    });
  };

  return (
    <div className='empty-student-analysis'>
      <img src={avatarPath} alt='avatar_searching' />
      <div className='empty-student-analysis__text'>
        {t('student_analysis_empty_title')}
      </div>
      <div className='empty-student-analysis__button--container'>
        <TDHeaderButton onClick={onAddStudents} icon={<AddIcon />} border>
          {t('add_students_button')}
        </TDHeaderButton>
      </div>
    </div>
  );
};

export default EmptyStudentAnalysis;
