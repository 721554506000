import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import StudentDetailsSidebar from './components/studentDetailsSidebar/StudentDetailsSidebar';
import StudentDetailsTopBar from './components/studentDetailsTopBar/StudentDetailsTopBar';
import './StudentDetails.scss';
import useStudentDetails from './useStudentDetails';

const StudentDetails = () => {
  const { t } = useTranslation();
  const { student, isLoading } = useStudentDetails();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Handle case where student data is null or undefined
  if (!student && !isLoading) {
    return <div>{t('error-student-not-found')}</div>;
  }

  return (
    <div className='student-details'>
      <div className='student-details__container'>
        <StudentDetailsSidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <div
          className={`student-details__content ${
            isSidebarOpen
              ? 'student-details__content--sidebar-open'
              : 'student-details__content--sidebar-closed'
          }`}
        >
          <StudentDetailsTopBar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
