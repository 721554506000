import { fetchWithToken } from '../helpers/fetcher';

export const getQuizzes = async ({ courseGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/courses/${courseGuid}/quizzes`
  });

  return response.data;
};

export const deleteQuiz = async ({ quizGuid }) => {
  let response = await fetchWithToken({
    method: 'DELETE',
    path: `cms/contents`,
    data: {
      guid: [quizGuid]
    }
  });

  return response.data;
};

export const getPrograms = async () => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/programs/`
  });

  return response.data;
};

export const getUnitsAndLessonsFromProgram = async ({
  programGuid,
  langId
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/courses/${programGuid}/items?preview=1&numberOfSeeds=true&lang=${langId}`,
    defaultLang: false
  });

  return response.data;
};

export const getUnitsAndLessons = async ({ courseGuid, langId }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/courses/${courseGuid}/items?numberOfSeeds=true&lang=${langId}`,
    defaultLang: false
  });

  return response.data;
};

export const createQuizContent = async ({
  langId,
  quizName,
  courseGuid,
  lessonGuids,
  numberOfQuestions
}) => {
  let response = await fetchWithToken({
    method: 'POST',
    path: `cms/contents`,
    data: {
      type_guid: 'CTTY_QUIZ',
      name: quizName,
      course_guid: courseGuid,
      number_of_questions: numberOfQuestions,
      lang_id: langId,
      lessons: lessonGuids
    }
  });

  return response.data;
};

export const getQuizContent = async ({ quizGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `cms/contents/${quizGuid}`
  });

  return response.data;
};

export const startQuiz = async ({ courseGuid, quizGuid }) => {
  let response = await fetchWithToken({
    method: 'POST',
    path: `front/courses/${courseGuid}/quizzes/${quizGuid}/start`,
    data: {}
  });

  return response.data;
};

export const getQuizResults = async ({ courseGuid, gameGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/courses/${courseGuid}/quiz-games/${gameGuid}`
  });

  return response.data;
};

export const deleteQuestion = async ({
  courseGuid,
  quizGuid,
  questionGuids
}) => {
  const response = await fetchWithToken({
    method: 'DELETE',
    path: `front/courses/${courseGuid}/quizzes/${quizGuid}/questions`,
    data: {
      guid: questionGuids
    }
  });

  return {
    status: response.status,
    ...response.data
  };
};

export const regenerateQuestion = async ({ courseGuid, questionGuid }) => {
  // POST front/courses/{courseGuid}/quiz-regenerate-question/{questionGuid}
  let response = await fetchWithToken({
    method: 'POST',
    path: `front/courses/${courseGuid}/quiz-regenerate-question/${questionGuid}`,
    data: {}
  });

  return {
    ...response.data,
    status: response.status
  };
};

export const addQuestion = async ({ courseGuid, quizGuid, lessons }) => {
  // POST front/courses/{courseGuid}/quizzes/{quizGuid}/questions
  let response = await fetchWithToken({
    method: 'POST',
    path: `front/courses/${courseGuid}/quizzes/${quizGuid}/questions`,
    data: {
      lessons
    }
  });

  return response.data;
};

export const getStandards = async () => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/standars`
  });

  return response.data;
};

export const reorderQuestions = async ({ courseGuid, quizGuid, questions }) => {
  // PATCH front/courses/{courseGuid}/quizzes/{quizGuid}
  let response = await fetchWithToken({
    method: 'PATCH',
    path: `front/courses/${courseGuid}/quizzes/${quizGuid}`,
    data: {
      reorder: questions
    }
  });

  return response.data;
};

export const resendInvitations = async ({ courseGuid, gameGuid }) => {
  let response = await fetchWithToken({
    method: 'PATCH',
    path: `front/courses/${courseGuid}/quiz-games/${gameGuid}`,
    data: {
      action: 'resendInvitations'
    }
  });

  return response.data;
};

export const editQuizName = async ({ quizGuid, newQuizName }) => {
  let response = await fetchWithToken({
    method: 'PUT',
    path: `cms/contents/${quizGuid}`,
    data: {
      name: newQuizName
    }
  });

  return response.data;
};

export const checkForActiveSessions = async ({ courseGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/courses/${courseGuid}/quiz-status`
  });

  return response.data;
};
