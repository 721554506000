import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BulkStudentImportFormStepOne from './steps/BulkStudentImportFormStepOne';
import BulkStudentImportFormStepTwo from './steps/BulkStudentImportFormStepTwo';

import { TDButton } from '../../../../../../../atoms/TDUILib';
import { useAddStudentsForm } from '../useAddStudentsForm';

import './bulkStudentImportForm.scss';

const BulkStudentImportForm = ({ onSave, onCancel, isSaving }) => {
  const { t } = useTranslation();
  const {
    setIsBackButtonVisible,
    setAreTabsVisible,
    setBulkImportStep,
    bulkImportStep
  } = useAddStudentsForm();

  const containerRef = useRef(null);

  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFileValid, setIsFileValid] = useState(false);
  const [data, setData] = useState([]);

  const steps = [
    {
      id: 'upload',
      button: t('students_management_add_students_import_upload'),
      onClick: () => {
        setBulkImportStep(1);
        setIsBackButtonVisible(true);
        setAreTabsVisible(false);
      },
      disabled: !isFileUploaded
    },
    {
      step: 'save',
      button: t('save'),
      onClick: () => {
        setIsBackButtonVisible(false);
        setAreTabsVisible(true);
        onSave(data);
      },
      disabled: !isFileUploaded || !isFileValid
    }
  ];

  useEffect(() => {
    if (bulkImportStep === 0) {
      setIsFileUploaded(false);
    }
  }, [bulkImportStep]);

  return (
    <div className='td-bulk-student-import-form--container' ref={containerRef}>
      {!isSaving && bulkImportStep === 0 && (
        <BulkStudentImportFormStepOne
          setIsFileUploaded={setIsFileUploaded}
          setIsFileValid={setIsFileValid}
          setData={setData}
        />
      )}

      {!isSaving && bulkImportStep === 1 && (
        <BulkStudentImportFormStepTwo data={data} isFileValid={isFileValid} />
      )}

      {!isSaving && (
        <div className='td-bulk-student-import-form__actions'>
          <TDButton onClick={onCancel} color='primary' variant='transparent'>
            {t('cancel')}
          </TDButton>

          <TDButton
            onClick={steps[bulkImportStep].onClick}
            color='primary'
            variant='outline'
            disabled={steps[bulkImportStep].disabled}
          >
            {steps[bulkImportStep].button}
          </TDButton>
        </div>
      )}

      {isSaving && (
        <div className='td-bulk-student-import-form__loading-state'>
          <CircularProgress />
          <div>
            {t('students_management_add_students_import_adding_students')}
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkStudentImportForm;
