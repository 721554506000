import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormControlLabel, FormGroup, Switch, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../../_practice-components';
import MathRenderer from '../../../../../atoms/MathRenderer/MathRenderer';
import useAuth from '../../../../../utils/hooks/useAuth';
import useUserSettings from '../../../../../utils/hooks/useUserSettings';
import './ViewActivity.scss';
import useViewActivity from './useViewActivity';

const ViewActivity = ({ attempt }) => {
  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();

  const { getPreviewLanguageSettings } = useUserSettings();
  const [activityLanguage, setActivityLanguage] = useState(null);

  const {
    showActivity,
    setShowActivity,
    htmlNameActivity,
    timeActivity,
    dateAttempt,
    totalTimeOfActivityInSeconds
  } = useViewActivity(t, attempt);

  const [showSolution, setShowSolution] = useState(false);

  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };

  useEffect(() => {
    setActivityLanguage(getPreviewLanguageSettings());
  }, []);

  return (
    <>
      <div className='report-user-detail-activity-container'>
        <div className='report-user-detail-phase'>
          {attempt?.phase === 'evocar'
            ? t('reports_phase_evocar')
            : attempt?.phase === 'identificar'
            ? t('reports_phase_identificar')
            : attempt?.phase === 'aplicar'
            ? t('reports_phase_aplicar')
            : ''}
        </div>
        <div className='report-user-detail-date'>{dateAttempt}</div>
        <div className='report-user-detail-question'>
          {(attempt?.is_ok === null || attempt?.is_ok !== 1) && (
            <Tooltip
              title={
                attempt?.is_ok === null
                  ? t('reports_message_tooltip_icon_orange')
                  : t('reports_message_tooltip_icon_red')
              }
            >
              <CloseIcon
                className={
                  attempt?.is_ok === null
                    ? 'report-user-detail-question-no_answered'
                    : attempt?.is_ok === 1
                    ? 'report-user-detail-question-success'
                    : 'report-user-detail-question-error'
                }
              />
            </Tooltip>
          )}
          {attempt?.is_ok === 1 && (
            <Tooltip title={t('reports_message_tooltip_icon_green')}>
              <CheckIcon
                className={`${
                  attempt?.is_ok === null
                    ? 'report-user-detail-question-no_answered'
                    : attempt?.is_ok === 1
                    ? 'report-user-detail-question-success'
                    : 'report-user-detail-question-error'
                } `}
              />
            </Tooltip>
          )}

          <div className='report-user-detail-activity-truncate'>
            <MathRenderer htmlContent={htmlNameActivity} />
          </div>

          {/* <div
            dangerouslySetInnerHTML={{
              __html: htmlNameActivity
            }}
            className='report-user-detail-activity-truncate'
          /> */}
        </div>

        <div className='report-user-detail-date'>
          {timeActivity &&
            `${timeActivity?.hours > 0 ? timeActivity?.hours + ' h ' : ''}${
              timeActivity?.minutes > 0 ? timeActivity?.minutes + ' min ' : ''
            }${timeActivity.seconds} s`}
          &nbsp;
          {totalTimeOfActivityInSeconds <= 2 && (
            <>
              <Tooltip title={t('reports_message_warning_seconds')}>
                <ErrorIcon className='report-user-detail-question-error' />
              </Tooltip>
            </>
          )}
        </div>
        <div className='report-user-detail-preview'>
          <button
            onClick={() => setShowActivity(!showActivity)}
            className='reports-user-detail__button'
          >
            {!showActivity && (
              <VisibilityIcon className='reports-user-detail__button_icon' />
            )}
            {showActivity && (
              <VisibilityOffIcon className='reports-user-detail__button_icon' />
            )}
          </button>
        </div>
      </div>
      {showActivity && attempt?.question && (
        <div className='report-user-detail-activity-preview'>
          {activityLanguage && (
            <ActivityBlueberryComponent
              activityType={ACTIVITY_TYPES.QUIZ}
              activity={attempt?.question}
              showSolutionQuestionQuizPreview={showSolution}
              disabledQuestion={true}
              isHideFooter={true}
              studentResponse={attempt?.question_response}
              showAllFeedback={false}
              mathRenderEngine='katex'
              pathAssetsSchool={schoolAssetsPath}
              uiLanguage={activityLanguage}
            />
          )}
          {attempt?.is_ok !== 1 && (
            <div className='report-user-detail-activity-preview-solution'>
              <FormGroup>
                <FormControlLabel
                  variant='switch'
                  control={<Switch onChange={() => toggleSolution()} />}
                  label={t('Show solution')}
                />
              </FormGroup>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ViewActivity;
