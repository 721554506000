import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { STUDENT_PAGE_DEFAULT_ORDER_BY } from '../../../../../utils/constants/student';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import { useStudent } from '../../../../../utils/contexts/StudentContext';
import { getStudents } from '../../../../../utils/crud/student';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import { tryJsonParse } from '../../../../../utils/helpers/tryJsonParse';
import useAuth from '../../../../../utils/hooks/useAuth';
import { useLocalStorage } from '../../../../../utils/hooks/useLocalStorage';
import useStudentsSorting from '../../../students/useStudentsSorting';

const useStudentDetailsMenu = () => {
  const { student, isLoadingStudent } = useStudent();
  const { sortData } = useStudentsSorting();
  const { studentGuid } = useParams();
  const { getItem } = useLocalStorage();
  const { schoolAssetsPath } = useAuth();
  const { selectedCourse } = useCourse();
  const location = useLocation();

  const [orderedStudents, setOrderedStudents] = useState([]);
  const [nextStudentGuid, setNextStudentGuid] = useState(null);
  const [previousStudentGuid, setPreviousStudentGuid] = useState(null);
  const [isLoadingAllStudents, setIsLoadingAllStudents] = useState(true);

  const getStudentsData = async () => {
    setIsLoadingAllStudents(true);
    const studentsData = await getStudents({
      courseGuid: selectedCourse?.guid
    });

    const DEFAULT_AVATAR = generateAssetURL({
      asset: 'default_single_avatar.png',
      schoolAssetsPath
    });

    const jointStudentsArray = studentsData?.users?.map((item) => {
      const studentFromCourse = selectedCourse?.users?.find(
        (user) => user.person_guid === item.guid
      );

      const studentAvatarFromCourse = tryJsonParse(
        studentFromCourse?.jsonSettings
      )?.avatar?.url;

      return {
        ...item,
        ...studentFromCourse,
        avatar: item.avatar || studentAvatarFromCourse || DEFAULT_AVATAR
      };
    });

    const newlyOrderedStudents = sortData(
      jointStudentsArray,
      STUDENT_PAGE_DEFAULT_ORDER_BY
    );
    setOrderedStudents(newlyOrderedStudents);
    setIsLoadingAllStudents(false);
  };

  const updateStudentPositions = () => {
    const currentStudentIndex = orderedStudents.findIndex(
      (s) => s.guid === studentGuid
    );

    // handle if student is not found
    if (currentStudentIndex === -1) return;

    // handle if student is the last one
    if (currentStudentIndex === orderedStudents.length - 1) {
      setNextStudentGuid(null);
    } else {
      setNextStudentGuid(orderedStudents[currentStudentIndex + 1]?.guid);
    }

    // handle if student is the first one
    if (currentStudentIndex === 0) {
      setPreviousStudentGuid(null);
    } else {
      setPreviousStudentGuid(orderedStudents[currentStudentIndex - 1]?.guid);
    }
  };

  useEffect(() => {
    if (!orderedStudents.length) return;

    updateStudentPositions();
  }, [studentGuid, orderedStudents]);

  useEffect(() => {
    // sort by sorting from students page. If not, sort by last name
    const studentsPagePreferences = getItem(location.state?.from);
    const parsedStudentsPagePreferences = tryJsonParse(studentsPagePreferences);

    let sortedStudentListFromStudentsPage = [];
    if (
      typeof parsedStudentsPagePreferences === 'object' &&
      parsedStudentsPagePreferences?.sortedUserList
    ) {
      sortedStudentListFromStudentsPage =
        parsedStudentsPagePreferences.sortedUserList;
    }

    if (sortedStudentListFromStudentsPage.length) {
      setOrderedStudents(sortedStudentListFromStudentsPage);
      setIsLoadingAllStudents(false);
    } else {
      getStudentsData();
    }
  }, []);

  return {
    student,
    isLoadingStudent,
    nextStudentGuid,
    previousStudentGuid,
    isLoadingAllStudents
  };
};

export default useStudentDetailsMenu;
