export const localStorageKeys = {
  userGuid: 'bb_user_guid',
  token: 'bb_user_token',
  firstName: 'bb_user_first_name',
  lastName: 'bb_user_last_name',
  email: 'bb_user_email',
  role: 'bb_user_role',
  whoAmI: 'bb_user_whoami',
  language: 'bb_user_language',
  loginTimeStamp: 'bb_user_login_timestamp',
  userSettings: 'bb_user_settings',
  providerLogin: 'bb_user_provider_login',
  traceIsLoginHome: 'bb_user_trace_is_login_home',
  currentCourse: 'bb_current_course',
  schoolGuid: 'bb_school_guid',
  isProduction: 'bb_is_production',
  currentCourseGuid: 'bb_current_course_guid',
  toastSessionManager: 'bb_toast_session_manager',
  hasVisitedStudentsManagementPage: 'has_visited_students_management_page',
  hasVisitedStudentsAnalysisPage: 'has_visited_students_analysis_page'
};
