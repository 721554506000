import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../../../../../assets/icons';
import { TDHeaderButton, TDTypography } from '../../../../../../atoms/TDUILib';
import './interventionsListItem.scss';

const InterventionsListItem = ({
  kcs,
  name,
  unit,
  lesson,
  lessonGuid,
  blockedSince,
  validationStatus = 'pending',
  interventionGuid,
  learningOutcomeGuid,
  wasInterventionViewed,
  handleGoToLessonDetails,
  handleInterventionValidation
}) => {
  const { t } = useTranslation();
  const date = new Date(blockedSince);
  const formattedDate = date.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  const [interventionValidation, setInterventionValidation] =
    useState(validationStatus);

  useEffect(() => {
    setInterventionValidation(validationStatus);
  }, [validationStatus]);

  return (
    <div
      className={`interventions-list-item ${
        !wasInterventionViewed ? 'interventions-list-item--new' : ''
      }`}
    >
      <div className='interventions-list-item__info'>
        <div className='interventions-list-item__info-content'>
          <div className='interventions-list-item__title'>{name}</div>
          <div className='interventions-list-item__text'>
            {unit + ' > ' + lesson}
          </div>
          <div className='interventions-list-item__text'>
            {t('interventions_needs_help_since') + ' ' + formattedDate}
          </div>
        </div>

        <div
          className='student-details__button student-details__button--text'
          onClick={() => handleGoToLessonDetails(lessonGuid)}
        >
          {t('See')}
        </div>
      </div>
      {/* validation status is the server status that we use to know if the user has already validated. 
      Since we show a thank you message after validating then the we depend on a local state to know if the user has already validated in the moment */}
      {validationStatus === 'pending' && (
        <div className='interventions-list-item__footer'>
          {interventionValidation === 'ok' && (
            <>
              <div className='interventions-list-item__footer-text-success'>
                <TDTypography variant='body-m'>
                  {t('interventions_feedback_yes_feedback_message')}
                </TDTypography>
                <ThumbsUpIcon />
              </div>
            </>
          )}
          {interventionValidation === 'not-ok' && (
            <div className='interventions-list-item__footer-text-error'>
              <TDTypography variant='body-m'>
                {t('interventions_feedback_no_feedback_message')}
              </TDTypography>
              <ThumbsDownIcon />
            </div>
          )}
          {interventionValidation === 'pending' && (
            <>
              <div className='interventions-list-item__footer-text'>
                <TDTypography variant='body-m'>
                  {t('interventions_feedback_question')}
                </TDTypography>
              </div>
              <div className='interventions-list-item__footer-button'>
                <TDHeaderButton
                  border
                  icon={<ThumbsUpIcon />}
                  onClick={() => {
                    handleInterventionValidation({
                      kcs,
                      interventionGuid,
                      learningOutcomeGuid,
                      learningOutcomeName: name,
                      validationStatus: 'ok',
                      source: 'student profile interventions card'
                    });
                    setInterventionValidation('ok');
                  }}
                />
                <TDHeaderButton
                  border
                  icon={<ThumbsDownIcon />}
                  onClick={() => {
                    handleInterventionValidation({
                      kcs,
                      interventionGuid,
                      learningOutcomeGuid,
                      learningOutcomeName: name,
                      validationStatus: 'not-ok',
                      source: 'student profile interventions card'
                    });
                    setInterventionValidation('not-ok');
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default InterventionsListItem;
