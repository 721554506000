import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import './TDCheckbox.scss';

const CheckIcon = () => (
  <svg
    width='11'
    height='9'
    viewBox='0 0 11 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.90625 5.15625L4.03125 7.28125L9.34375 1.96875'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const IndeterminateIcon = () => (
  <svg
    width='14'
    height='2'
    viewBox='0 0 14 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H13C13.4142 0.25 13.75 0.585786 13.75 1C13.75 1.41421 13.4142 1.75 13 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1Z'
      fill='white'
    />
  </svg>
);

const TDCheckbox = ({
  label,
  checked,
  onChange,
  name,
  disabled,
  indeterminate
}) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
    onChange();
  };

  return (
    <div className='td-checkbox'>
      <input
        type='checkbox'
        name={name}
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        ref={(input) => {
          if (input) {
            input.indeterminate = indeterminate;
          }
        }}
        id={name}
      />
      <label htmlFor={name}>
        <span
          className={`td-checkbox__custom-checkbox ${
            indeterminate ? 'td-checkbox__custom-checkbox--indeterminate' : ''
          } ${disabled ? 'td-checkbox__custom-checkbox--disabled' : ''}`}
        >
          <span className={`td-checkbox__checkmark`}>
            {isChecked && !indeterminate && <CheckIcon />}
            {indeterminate && <IndeterminateIcon />}
          </span>
        </span>
        {label && <span className='td-checkbox__label-text'>{label}</span>}
      </label>
    </div>
  );
};

export default TDCheckbox;

TDCheckbox.propTypes = {
  /** Unique name and id of the checkbox*/
  name: PropTypes.string.isRequired,
  /** Label text if needed */
  label: PropTypes.string,
  /** Change check state handler */
  onChange: PropTypes.func,
  /** Controls check state */
  checked: PropTypes.bool,
  /** Controls disabled state */
  disabled: PropTypes.bool,
  /** Controls indeterminate state (-) */
  indeterminate: PropTypes.bool
};
