import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getCourses } from '../crud/course';
import { tryJsonParse } from '../helpers/tryJsonParse';
import { localStorageKeys } from '../localStorageKeys';

const CourseContext = createContext({
  availableCourses: [],
  isLoadingCourses: true,
  selectedCourse: undefined,
  setAvailableCourses: () => {},
  setSelectedCourse: () => {},
  hydrateCourses: () => {}
});

const CourseProvider = () => {
  const [selectedCourse, setSelectedCourse] = useState(undefined);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const { courseGuid } = useParams();
  const navigate = useNavigate();

  const initialize = async () => {
    setIsLoadingCourses(true);

    let tempCourse = localStorage.getItem(localStorageKeys.currentCourse);

    const courses = await getCourses();
    if (Array.isArray(courses)) {
      const tempCourses = courses.map((course) => ({
        ...course,
        latestFetch: new Date()
      }));
      setAvailableCourses(tempCourses);

      if (courseGuid) {
        const foundCourse = tempCourses.find(
          (course) => course.guid === courseGuid
        );
        if (foundCourse) {
          setSelectedCourse(foundCourse);
        } else {
          setSelectedCourse(undefined);
        }
      } else if (tempCourse) {
        const tempCourseJson = tryJsonParse(tempCourse);
        if (tempCourseJson) {
          setSelectedCourse(tempCourseJson);
        }
      } else {
        setSelectedCourse(tempCourses[0]);
      }
    }
    setIsLoadingCourses(false);
  };

  const hydrateCourses = () => {
    return initialize();
  };

  const saveCourseLocally = () => {
    localStorage.setItem(
      localStorageKeys.currentCourseGuid,
      selectedCourse.guid
    );
    localStorage.setItem(
      localStorageKeys.currentCourse,
      JSON.stringify(selectedCourse)
    );
  };

  useEffect(() => {
    initialize();
  }, [courseGuid]);

  useEffect(() => {
    if (selectedCourse) {
      saveCourseLocally();
    }
  }, [selectedCourse]);

  return (
    <CourseContext.Provider
      value={{
        selectedCourse,
        availableCourses,
        isLoadingCourses,
        hydrateCourses,
        setSelectedCourse,
        setAvailableCourses
      }}
    >
      <Outlet />
    </CourseContext.Provider>
  );
};

// Hook para usar este contexto, el contexto no se puede exponer directamente, para eso está el hook
const useCourse = () => {
  const {
    selectedCourse,
    availableCourses,
    isLoadingCourses,
    hydrateCourses,
    setSelectedCourse
  } = useContext(CourseContext);

  return {
    selectedCourse,
    availableCourses,
    isLoadingCourses,
    hydrateCourses,
    setSelectedCourse
  };
};

// TODO: FRAN: "no es buena práctica exponer el contexto, si el día de mañna decidimos usar otra tecnología para gestionar el estado global que hacemos?
// para eso están los hooks que es de la propia tecnología React"
export { CourseContext, CourseProvider, useCourse };
