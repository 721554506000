import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';

import useAuth from '../../../../../utils/hooks/useAuth';
import './listLessons.scss';

const ListLessonsEmptyCase = () => {
  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();
  const notFoundImage = generateAssetURL({
    asset: 'avatar_not_found.png',
    schoolAssetsPath
  });
  return (
    <div className='list-lessons'>
      <div className='list-lessons__not-found'>
        <img src={notFoundImage} alt={t('no_results_topic_search')} />
        <Typography variant='caption'>
          {t('no_results_topic_search')}
        </Typography>
      </div>
    </div>
  );
};

export default ListLessonsEmptyCase;
