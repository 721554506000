import { useTranslation } from 'react-i18next';
import { DoubleChevronLeftIcon } from '../../../../../../../assets/icons';
import { TDButton, TDTabs } from '../../../../../../../atoms/TDUILib';
import { useAddStudentsForm } from '../useAddStudentsForm';

const AddStudentsFormNavigation = () => {
  const { t } = useTranslation();
  const {
    areTabsVisible,
    isBackButtonVisible,
    setAreTabsVisible,
    setBulkImportStep,
    selectedTab,
    setSelectedTab,
    setIsBackButtonVisible
  } = useAddStudentsForm();

  const TABS = [
    {
      label: t('students_management_add_students_manual'),
      id: 'manual'
    },
    {
      label: t('students_management_add_students_import'),
      id: 'import'
    }
  ];

  return (
    <div>
      {areTabsVisible && (
        <div className='add-students-form__tabs'>
          <TDTabs
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
            tabs={TABS}
          />
        </div>
      )}
      {isBackButtonVisible && (
        <div className='add-students-form__back-button'>
          <TDButton
            variant='text'
            color='primary'
            radius='small'
            size='xs'
            onClick={() => {
              setBulkImportStep(0);
              setAreTabsVisible(true);
              setIsBackButtonVisible(false);
            }}
            icon={<DoubleChevronLeftIcon />}
            iconPosition='left'
          >
            {t('students_management_add_students_import_back')}
          </TDButton>
        </div>
      )}
    </div>
  );
};

export default AddStudentsFormNavigation;
