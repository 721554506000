import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YearSelector from '../../../CreateQuiz/components/yearSelector/YearSelector';
import ListLessons from '../../../CreateQuiz/listLessons/ListLessons';
import './AddActivityToQuizDialog.scss';
import useAddActivityToQuizDialog from './useAddActivityToQuizDialog';

const AddActivityToQuizDialog = ({
  isOpen = false,
  onClose = () => {},
  subTopics = [],
  quizLanguage = '',
  allStandards = [],
  onAddActivities = () => {},
  isAddingQuestions = false,
  standardsByProgram = []
}) => {
  const { t } = useTranslation();

  const {
    programs,
    isLoading,
    standards,
    lessonsToList,
    selectedLessons,
    selectedProgram,
    selectedLessonsToAdd,
    onSelectLesson,
    onChangeProgram,
    onFilterAndSearch
  } = useAddActivityToQuizDialog({
    subTopics,
    standardsByProgram,
    quizLanguage,
    allStandards
  });

  const [searchValue, setSearchValue] = useState('');
  const [selectedStandard, setSelectedStandard] = useState(null);
  const [showOnlyTopicsInUse, setShowOnlyTopicsInUse] = useState(false);

  useEffect(() => {
    onFilterAndSearch({
      searchText: searchValue,
      standardGuid: selectedStandard,
      showOnlyTopicsInUse
    });
  }, [searchValue, selectedStandard, showOnlyTopicsInUse]);

  useEffect(() => {
    setSearchValue('');
    setSelectedStandard(null);
    setShowOnlyTopicsInUse(false);
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>
        <Stack spacing={3}>
          <Typography variant='h6'>{t('add_activity_to_quiz')}</Typography>
          <div className='add-activity-to-quiz-dialog__filters'>
            <div className='add-activity-to-quiz-dialog__year-filter'>
              <YearSelector
                programs={programs}
                onChangeProgram={(newProgram) => {
                  setSearchValue('');
                  setSelectedStandard(null);
                  onChangeProgram(newProgram);
                }}
                selectedProgram={selectedProgram}
              />
            </div>
            <div className='add-activity-to-quiz-dialog__search'>
              <TextField
                fullWidth
                value={searchValue}
                placeholder={t('Search for topics')}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  endAdornment: <Search />
                }}
              />
            </div>
            {standardsByProgram && (
              <div className='add-activity-to-quiz-dialog__filter'>
                <Autocomplete
                  disablePortal
                  options={standards}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) =>
                    setSelectedStandard(newValue?.guid)
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('standard')}
                      fullWidth
                    />
                  )}
                />
              </div>
            )}
          </div>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <div className='add-activity-to-quiz-dialog__lessons'>
            <ListLessons
              isLoading={isLoading}
              lessons={lessonsToList}
              onSelect={onSelectLesson}
              onSelectAll={() => {}}
              selectedLessons={selectedLessons}
              variant='addActivity'
              standardsByProgram={standardsByProgram}
            />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions className='add-activity-to-quiz-dialog__actions-container'>
        <div className='add-activity-to-quiz-dialog__actions'>
          <FormGroup>
            <FormControlLabel
              variant='switch'
              control={
                <Switch
                  onChange={() => setShowOnlyTopicsInUse(!showOnlyTopicsInUse)}
                />
              }
              label={t('Show only topics in use')}
            />
          </FormGroup>
          <Stack direction='row' spacing={3}>
            <Button variant='text' color='info' disableRipple onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              variant='contained'
              disableElevation
              onClick={() => {
                onAddActivities({
                  selectedLessonsToAdd,
                  onClose
                });
              }}
              disabled={isAddingQuestions || !selectedLessonsToAdd.length}
            >
              {isAddingQuestions ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {t('Add')} (
                  {lessonsToList.reduce(
                    (acc, lesson) =>
                      acc + (lesson.numberOfActivitiesToAdd || 0),
                    0
                  )}
                  )
                </>
              )}
            </Button>
          </Stack>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddActivityToQuizDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  subTopics: PropTypes.array,
  quizLanguage: PropTypes.string,
  onAddActivities: PropTypes.func,
  isAddingQuestions: PropTypes.bool
};

export default AddActivityToQuizDialog;
