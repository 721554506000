const CellPassword = ({ password, isPasswordVisible }) => {
  const PASSWORD_PLACEHOLDER = '***';
  return (
    <div className='cell-password'>
      {isPasswordVisible ? password : PASSWORD_PLACEHOLDER}
    </div>
  );
};

export default CellPassword;
