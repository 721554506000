import { fetchWithToken } from '../helpers/fetcher';

export const getStudents = async ({ courseGuid, days, lang }) => {
  let path = `blueberry/teacher/${courseGuid}/students`;
  const params = [];
  if (days) params.push(`days=${days}`);
  if (lang) params.push(`lang=${lang}`);
  if (params.length > 0) path += `?${params.join('&')}`;

  let response = await fetchWithToken({
    method: 'GET',
    path
  });

  return response.data;
};

export const getStudentInterventions = async ({ courseGuid, studentGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/course/${courseGuid}/students/${studentGuid}/interventions`
  });

  return response.data;
};

export const validateInterventions = async ({
  courseGuid,
  studentGuid,
  validationData
}) => {
  let response = await fetchWithToken({
    method: 'POST',
    path: `blueberry/course/${courseGuid}/students/${studentGuid}/interventions`,
    data: validationData
  });

  return response.data;
};

export const getStudentLessonDetails = async ({
  courseGuid,
  studentGuid,
  lessonGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/course/${courseGuid}/students/${studentGuid}?lesson=${lessonGuid}`
  });

  return response.data;
};

export const getStudentDetails = async ({ courseGuid, studentGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/course/${courseGuid}/student/${studentGuid}`
  });

  return response.data;
};

export const getStudentLoDetails = async ({ courseGuid, userGuid, loGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/course/${courseGuid}/students/${userGuid}/?learningOutcome=${loGuid}`
  });

  return response.data;
};

export const getClassStudents = async ({ schoolGuid, groupGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `front/school-admin/${schoolGuid}/groups/${groupGuid}/users?pageSize=100&offset=0&role[]=R01`
  });

  return response.data;
};

export const addStudents = async ({ groupGuid, students }) => {
  let response = await fetchWithToken({
    method: 'POST',
    path: `front/school-admin-groups/${groupGuid}/add-students`,
    data: students
  });
  return response;
};

export const deleteStudents = async ({
  schoolGuid,
  groupGuid,
  studentGuids
}) => {
  const response = await fetchWithToken({
    method: 'DELETE',
    path: `front/school-admin/${schoolGuid}/groups/${groupGuid}/users`,
    data: {
      guid: studentGuids,
      removeUserAccounts: true
    }
  });

  return response;
};

export const checkUsername = async (username) => {
  let response = await fetchWithToken({
    method: 'POST',
    path: `front/school-admin/check/check-users/`,
    data: {
      users: [
        {
          username: username
        }
      ]
    }
  });
  return response;
};

export const editStudent = async ({
  schoolGuid,
  userGuid,
  name,
  lastname,
  username,
  password
}) => {
  const response = await fetchWithToken({
    method: 'PUT',
    path: `front/school-admin/${schoolGuid}/students`,
    data: {
      guid: [userGuid],
      data: {
        name,
        lastname,
        username,
        password
      }
    }
  });
  return response;
};

export const changeStudentClass = async ({
  schoolGuid,
  groupGuid,
  studentGuid
}) => {
  const response = await fetchWithToken({
    method: 'POST',
    path: `front/school-admin/${schoolGuid}/groups/${groupGuid}/users`,
    data: {
      guid: studentGuid,
      removeFromOtherGroups: true
    }
  });
  return response;
};
