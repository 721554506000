import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/icon';
import BBTooltip from '../../atoms/tooltip/BBTooltip';
import { useCourse } from '../../utils/contexts/CourseContext';

const DashboardMenuItem = ({
  name,
  action,
  href,
  pageKey,
  icon,
  flag,
  testId,
  subsections,
  isResponsiveMode
}) => {
  const { t } = useTranslation();
  const { selectedCourse } = useCourse();
  const { isFlagEnabled } = useFeatureFlag({ flag });
  const location = useLocation();

  const [isCurrentTab, setIsCurrentTab] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(isResponsiveMode || false);

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const checkIsCurrentTab = (key) => {
    // key can be string or array

    if (typeof key === 'string') {
      return location.pathname.split('/').includes(key);
    } else if (Array.isArray(key)) {
      return key.some((k) => location.pathname.split('/').includes(k));
    }
  };

  useEffect(() => {
    setIsCurrentTab(checkIsCurrentTab(pageKey));
  }, [location.pathname]);

  if (flag && !isFlagEnabled) {
    return null;
  }

  if (action) {
    return (
      <div>
        <BBTooltip text={t(name)} placement='bottom'>
          <div
            className='dashboard-with-topbar-menu__navigation-item'
            sx={{ padding: 0 }}
            onClick={() => onClickForAction(action)}
          >
            <Icon type={icon} format='outline' />
          </div>
        </BBTooltip>
      </div>
    );
  }

  if (subsections) {
    return isResponsiveMode ? (
      <div className='dashboard-with-topbar-menu__navigation-item-with-subsection responsive'>
        <div
          className={`dashboard-with-topbar-menu__navigation-item  ${
            isCurrentTab
              ? 'dashboard-with-topbar-menu__navigation-item--active'
              : ''
          }`}
          onClick={handleMenu}
        >
          <div>{t(name)}</div>
          <div
            className={`dashboard-with-topbar-menu__marker ${
              isMenuOpen ? 'active' : ''
            }`}
          >
            <ChevronDownIcon />
          </div>
        </div>

        {isMenuOpen && (
          <div
            className='dashboard-with-topbar-menu__navigation-item--subsections'
            data-testid='menu-subsections'
          >
            {subsections?.map((subsection) => (
              <Link
                to={subsection?.href}
                key={subsection?.key}
                className={`dashboard-with-topbar-menu__navigation-item ${
                  checkIsCurrentTab(subsection?.key)
                    ? 'dashboard-with-topbar-menu__navigation-item--active'
                    : ''
                }`}
                data-testid={subsection?.testId}
              >
                {t(subsection?.name)}
              </Link>
            ))}
          </div>
        )}
      </div>
    ) : (
      <div className='dashboard-with-topbar-menu__navigation-item-with-subsection inline'>
        <div
          className={`dashboard-with-topbar-menu__navigation-item ${
            isMenuOpen ? 'active' : ''
          } ${
            isCurrentTab
              ? 'dashboard-with-topbar-menu__navigation-item--active'
              : ''
          }`}
          onClick={handleMenu}
          data-testid={testId}
        >
          <div>{t(name)}</div>
          <div
            className={`dashboard-with-topbar-menu__marker ${
              isMenuOpen ? 'active' : ''
            }`}
          >
            <ChevronDownIcon />
          </div>
        </div>
        {isMenuOpen && (
          <>
            <div
              className='dashboard-with-topbar-menu__navigation-item-with-subsection-backdrop'
              onClick={handleMenu}
            />
            <div className='dashboard-with-topbar-menu__navigation-item--subsections'>
              {subsections?.map((subsection) => (
                <Link
                  onClick={handleMenu}
                  to={`/${selectedCourse?.guid}${subsection?.href}`}
                  key={subsection?.href}
                  className={`dashboard-with-topbar-menu__navigation-item ${
                    checkIsCurrentTab(subsection?.key)
                      ? 'dashboard-with-topbar-menu__navigation-item--active'
                      : ''
                  }`}
                  data-testid={subsection?.testId}
                >
                  {t(subsection?.name)}
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <Link
        to={href}
        className={`dashboard-with-topbar-menu__navigation-item ${
          isCurrentTab
            ? 'dashboard-with-topbar-menu__navigation-item--active'
            : ''
        }`}
        data-testid={testId}
      >
        {t(name)}
      </Link>
    </div>
  );
};

export default DashboardMenuItem;
