import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, RemoveIcon } from '../../../../../../../assets/icons';
import { TDButton, TDInputText } from '../../../../../../../atoms/TDUILib';
import uuidv4 from '../../../../../../../utils/helpers/uuidv4';
import './manualAddStudentsForm.scss';

const ManualAddStudentsForm = ({ onCancel, onSave, isSaving }) => {
  const { t } = useTranslation();
  const [students, setStudents] = useState(
    Array.from({ length: 6 }, () => ({
      id: uuidv4(),
      name: '',
      lastname: ''
    }))
  );

  const [attemptedSaveWithErrors, setAttemptedSaveWithErrors] = useState(false);
  const [newStudentAdded, setNewStudentAdded] = useState(false);

  const containerRef = useRef(null);
  const inputRefs = useRef([]);

  const handleStudentChange = (studentId, field, value) => {
    const newStudents = students.map((student) => {
      if (student.id === studentId) {
        return { ...student, [field]: value };
      }
      return student;
    });
    setStudents(newStudents);
  };

  const handleAddStudent = () => {
    setStudents((prevStudents) => [
      ...prevStudents,
      { id: uuidv4(), name: '', lastname: '' }
    ]);
    setNewStudentAdded(true);
  };

  const handleRemoveStudent = (studentIdToRemove) => {
    setStudents((currentStudents) =>
      currentStudents.filter((student) => student.id !== studentIdToRemove)
    );
  };

  const saveStudents = () => {
    const filteredStudents = students.filter(
      (student) => student.name || student.lastname
    );

    const allFieldsFilled = filteredStudents.every(
      (student) =>
        (student.name && student.lastname) ||
        (!student.name && !student.lastname)
    );

    if (!allFieldsFilled) {
      setAttemptedSaveWithErrors(true);
      const firstErrorIndex = students.findIndex(
        (student) => !student.name || !student.lastname
      );
      if (firstErrorIndex !== -1 && inputRefs.current[firstErrorIndex]) {
        const errorElement = inputRefs.current[firstErrorIndex];
        const containerElement = containerRef.current;
        if (containerElement && errorElement) {
          const errorElementRect = errorElement.getBoundingClientRect();
          const containerRect = containerElement.getBoundingClientRect();
          containerElement.scrollTo({
            top:
              errorElementRect.top -
              containerRect.top +
              containerElement.scrollTop,
            behavior: 'smooth'
          });
          errorElement.focus();
        }
      }
    } else {
      onSave(filteredStudents);
      setAttemptedSaveWithErrors(false);
    }
  };

  useEffect(() => {
    if (newStudentAdded && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      setNewStudentAdded(false);
    }
  }, [students.length, newStudentAdded]);

  return (
    <div className='manual-add-students-form-container'>
      <div
        className={`manual-add-students-form ${
          !isSaving ? 'manual-add-students-form--hidden' : ''
        }`}
      >
        <div className='manual-add-students-form__content'>
          <div className='manual-add-students-form__loading'>
            <CircularProgress size='48px' />
            <div className='manual-add-students-form__loading-text'>
              {t('students_management_adding_students')}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`manual-add-students-form ${
          isSaving ? 'manual-add-students-form--hidden' : ''
        }`}
      >
        <div ref={containerRef} className='manual-add-students-form__content'>
          <div className='manual-add-students-form__info'>
            {t('students_management_add_students_info')}
          </div>
          {students.map((student, index) => (
            <div
              key={student.id}
              className='manual-add-students-form__student-row'
              ref={(el) => (inputRefs.current[index] = el)}
            >
              <TDInputText
                placeholder={t('students_management_name')}
                value={student.name}
                onChange={(e) =>
                  handleStudentChange(student.id, 'name', e.target.value)
                }
                hasError={
                  attemptedSaveWithErrors && !student.name && student.lastname
                }
                errorText={t('students_management_add_students_field_error')}
              />
              <TDInputText
                placeholder={t('students_management_lastname')}
                value={student.lastname}
                onChange={(e) =>
                  handleStudentChange(student.id, 'lastname', e.target.value)
                }
                hasError={
                  attemptedSaveWithErrors && !student.lastname && student.name
                }
                errorText={t('students_management_add_students_field_error')}
              />
              <div
                className='manual-add-students-form__remove-button'
                onClick={() => handleRemoveStudent(student.id)}
              >
                <RemoveIcon />
              </div>
            </div>
          ))}

          <TDButton
            onClick={handleAddStudent}
            color='primary'
            variant='text'
            icon={<AddIcon />}
          >
            {t('students_management_add_more')}
          </TDButton>
        </div>
        <div className='manual-add-students-form__actions'>
          <TDButton onClick={onCancel} color='primary' variant='transparent'>
            {t('cancel')}
          </TDButton>

          <TDButton onClick={saveStudents} color='primary' variant='outline'>
            {t('save')}
          </TDButton>
        </div>
      </div>
    </div>
  );
};

export default ManualAddStudentsForm;
