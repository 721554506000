import { Add, Remove } from '@mui/icons-material';
import { Checkbox, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StandardChips from '../standardChips/StandardChips';
import './LessonCard.scss';

const LessonCard = ({
  lessonGuid = '',
  onSelect = () => {},
  lessonName = '',
  isSelected = false,
  numberOfActivities = 0,
  standards = [],
  variant = 'default'
}) => {
  const { t } = useTranslation();
  const [numberOfActivitiesToAdd, setNumberOfActivitiesToAdd] = useState(1);

  useEffect(() => {
    if (variant !== 'addActivity' || !isSelected) return;

    onSelect({
      lessonGuid,
      numberOfActivitiesToAdd
    });
  }, [numberOfActivitiesToAdd, variant, isSelected]);

  return (
    <div
      className={`lesson-card ${isSelected ? 'lesson-card--selected' : ''}`}
      onClick={() => {
        if (variant === 'addActivity' && !isSelected) {
          onSelect({
            lessonGuid,
            numberOfActivitiesToAdd
          });
        } else if (variant === 'addActivity' && isSelected) {
          onSelect({
            lessonGuid,
            numberOfActivitiesToAdd: 0 // 0 indicated that the lesson should be removed
          });
        } else {
          onSelect(lessonGuid);
        }
      }}
    >
      <div className='lesson-card__check'>
        <Checkbox checked={isSelected} disableRipple />
      </div>
      <div className='lesson-card__info'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <div>
            <Typography variant='subtitle1'>{`${numberOfActivities} ${t(
              'Activity',
              { count: numberOfActivities }
            )}`}</Typography>

            <Typography variant='h6'>{lessonName}</Typography>
          </div>
          <Stack direction='row' spacing={3} alignItems='center'>
            <StandardChips standards={standards} />
            {variant === 'addActivity' && isSelected && (
              <div
                className='lesson-card__number-selector'
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  disabled={numberOfActivitiesToAdd < 2}
                  variant='counter'
                  onClick={(e) => {
                    e.stopPropagation();
                    setNumberOfActivitiesToAdd(numberOfActivitiesToAdd - 1);
                  }}
                >
                  <Remove />
                </IconButton>
                <div
                  className='lesson-card__number-selector-text'
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <span>{numberOfActivitiesToAdd}</span>{' '}
                  <div>{t('activities')}</div>
                </div>
                <IconButton
                  variant='counter'
                  onClick={(e) => {
                    e.stopPropagation();
                    setNumberOfActivitiesToAdd(numberOfActivitiesToAdd + 1);
                  }}
                >
                  <Add />
                </IconButton>
              </div>
            )}
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

LessonCard.propTypes = {
  onSelect: PropTypes.func,
  lessonName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  numberOfActivities: PropTypes.number.isRequired,
  lessonGuid: PropTypes.string.isRequired,
  standards: PropTypes.array,
  variant: PropTypes.oneOf(['default', 'addActivity'])
};

export default LessonCard;
