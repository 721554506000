import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnalysisSegment } from '../../../../../../utils/analysis';
import { useCourse } from '../../../../../../utils/contexts/CourseContext';
import { useStudent } from '../../../../../../utils/contexts/StudentContext';
import {
  getStudentLoDetails,
  validateInterventions
} from '../../../../../../utils/crud/student';
import { tryJsonParse } from '../../../../../../utils/helpers/tryJsonParse';
import useAnalytics from '../../../../../../utils/hooks/useAnalytics';

const useLessonDetailsListItem = ({
  currentLoGuid,
  loName,
  interventionGuid,
  hasIntervention,
  KCGuid,
  KCName,
  loInterventionFeedback,
  isFirstLOWithInterventions
}) => {
  const { selectedCourse } = useCourse();
  const { studentInterventions, setStudentInterventions } = useStudent();

  const [isLoading, setIsLoading] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(
    isFirstLOWithInterventions
  );
  const [loDetailsData, setLoDetailsData] = useState([]);
  const [loAttemptsData, setLoAttemptsData] = useState([]);
  const [loTotalTime, setLoTotalTime] = useState([]);
  const [loTotalActivities, setTotalActivities] = useState([]);
  const [isLOFlagged, setIsLOFlagged] = useState(false);
  const [interventionFeedback, setInterventionFeedback] = useState(
    loInterventionFeedback
  );
  const { studentGuid } = useParams();
  const { trackEvent } = useAnalytics();

  function convertTimeArray(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return [hours, minutes, seconds];
  }

  function sumElapsedTimeAndConvert(data) {
    const totalSeconds = data.reduce(
      (acc, obj) => acc + obj.elapsedTimeInSeconds,
      0
    );
    return convertTimeArray(totalSeconds);
  }

  function removeHtmlTags(htmlString) {
    return htmlString?.replace(/<[^>]*>?/gm, '') || '';
  }

  function parseAttemptsData(data) {
    return data.map((item) => {
      const { is_ok, elapsedTimeInSeconds, end_at } = item;

      let activityState;
      if (is_ok === null) {
        activityState = 'abandoned';
      } else if (is_ok === 1) {
        activityState = 'success';
      } else {
        activityState = 'error';
      }

      const endDate = new Date(end_at);
      const dateFormatter = new Intl.DateTimeFormat('es', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      const timeFormatter = new Intl.DateTimeFormat('es', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });

      const formattedDate = dateFormatter.format(endDate);
      const formattedTime = timeFormatter.format(endDate);

      const isFlagged = item.elapsedTimeInSeconds < 2;

      // Parse el question and get stimulus
      const questionObj = tryJsonParse(item?.question);
      const stimulusHTML =
        questionObj?.type === 'scaffold'
          ? questionObj?.questions[0]?.data?.stimulus
          : questionObj?.data?.stimulus;

      // Following the same approach as in ViewActivity parseAttemptData to extract the html of the first paragraph.
      // This is to avoid rendering the image tags and other unwanted tags in the stimulus.
      // Also, it allows to render the math content of the stimulus on one line instead of having multiples
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(stimulusHTML, 'text/html');
      const firstParagraph = htmlDoc.querySelector('p');
      const firstParagraphHTML =
        firstParagraph?.innerHTML || stimulusHTML || '';

      const stimulus = firstParagraphHTML;

      const questionData =
        questionObj?.type === 'scaffold'
          ? questionObj?.questions[0]
          : questionObj;

      const questionResponse = tryJsonParse(item?.question_response);

      return {
        activityState,
        elapsedTimeInSeconds,
        formattedDate,
        formattedTime,
        stimulus,
        endDate,
        isFlagged,
        questionData,
        questionResponse,
        isCorrect: Boolean(is_ok === 1)
      };
    });
  }

  const orderAttemptsData = (data) => {
    return data.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
  };

  // Get data
  const getLoData = async () => {
    if (isLoading) return;
    setIsLoading(true);

    let tempData = await getStudentLoDetails({
      courseGuid: selectedCourse?.guid,
      userGuid: studentGuid,
      loGuid: currentLoGuid
    });

    let apiData = tempData || [];

    const tempAttemptsData = parseAttemptsData(apiData?.attempts);
    const orderedTempAttemptsData = orderAttemptsData(tempAttemptsData);

    setIsLOFlagged(tempAttemptsData?.some((attempt) => attempt.isFlagged));

    setLoAttemptsData(orderedTempAttemptsData);
    setLoTotalTime(sumElapsedTimeAndConvert(apiData?.attempts));
    setTotalActivities(apiData?.attempts?.length);

    setLoDetailsData(apiData);
    setIsLoading(false);
  };

  useEffect(() => {
    getLoData();
  }, [currentLoGuid]);

  const onToggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const onInterventionFeedback = async ({ validationStatus }) => {
    setInterventionFeedback(validationStatus);

    await validateInterventions({
      courseGuid: selectedCourse?.guid,
      studentGuid,
      validationData: [
        {
          learningOutcomeGuid: currentLoGuid,
          validation: validationStatus
        }
      ]
    });

    // update local state of student interventions to manage interventions popup state on exiting the page
    const updatedInterventions = studentInterventions.map((intervention) => {
      if (intervention.interventionGuid === interventionGuid) {
        return { ...intervention, intervention_validation: validationStatus };
      }
      return intervention;
    });
    // update local state with updated intervention
    setStudentInterventions(updatedInterventions);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_LO_Validated, {
      student_id: studentGuid,
      lo_id: currentLoGuid,
      lo_name: loName,
      kc_id: KCGuid,
      kc_name: KCName,
      validation_info: validationStatus,
      source: 'LO Detail'
    });
  };

  useEffect(() => {
    if (isAccordionOpen) {
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Profile_LO_Seen, {
        lo_id: currentLoGuid,
        lo_name: loName,
        need_help: hasIntervention,
        kc_id: KCGuid,
        kc_name: KCName
      });
    }
  }, [isAccordionOpen]);

  return {
    getLoData,
    loDetailsData,
    loAttemptsData,
    loTotalTime,
    loTotalActivities,
    isLoading,
    isLOFlagged,
    isAccordionOpen,
    interventionFeedback,
    onToggleAccordion,
    onInterventionFeedback
  };
};

export default useLessonDetailsListItem;
