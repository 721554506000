import { useState } from 'react';
import './TDTooltip.scss';

const TDTooltip = ({
  mode = 'hover',
  text,
  placement = 'bottom',
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let triggerProps = {};

  switch (mode) {
    case 'hover':
      triggerProps = {
        onMouseEnter: () => setIsOpen(true),
        onMouseLeave: () => setIsOpen(false)
      };
      break;
    case 'click':
      triggerProps = {
        onClick: () => setIsOpen(!isOpen)
      };
      break;
    default:
      triggerProps = {};
  }

  return (
    <div className='td-tooltip' {...triggerProps}>
      <div className='td-tooltip__trigger'>{children}</div>
      {isOpen && (
        <div className={`td-tooltip__content ${placement}`}>
          {text}
          <div className={`td-tooltip__arrow ${placement}`} />
        </div>
      )}
    </div>
  );
};

export default TDTooltip;
