import './TDNavigator.scss';

const TDNavigator = ({ children, active, onClick, className, icon }) => {
  const classes = [
    'td-navigator',
    className,
    active ? 'td-navigator--active' : ''
  ];

  return (
    <div onClick={onClick} className={classes.filter(Boolean).join(' ')}>
      {children}
      <div className='td-navigator__icon'>{icon}</div>
    </div>
  );
};

export default TDNavigator;
