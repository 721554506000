import { useTranslation } from 'react-i18next';
import Bubble from '../assets/bubble.png';
import PodiumBlob1 from '../assets/podium_blob_1.svg';
import PodiumBlob2 from '../assets/podium_blob_2.svg';
import PodiumBlob3 from '../assets/podium_blob_3.svg';
import PodiumStar1 from '../assets/podium_star_1.svg';
import PodiumStar2 from '../assets/podium_star_2.svg';
import PodiumStar3 from '../assets/podium_star_3.svg';

const AvatarOnPodiumComponent = ({
  position,
  userName,
  userPoints,
  userAvatar
}) => {
  const { t } = useTranslation();

  let podiumBlob = null;
  let podiumStar = null;
  let podiumClass = '';

  switch (position) {
    case 0:
      podiumBlob = PodiumBlob1;
      podiumStar = PodiumStar1;
      podiumClass = 'first';
      break;
    case 1:
      podiumBlob = PodiumBlob2;
      podiumStar = PodiumStar2;
      podiumClass = 'second';
      break;
    case 2:
      podiumBlob = PodiumBlob3;
      podiumStar = PodiumStar3;
      podiumClass = 'third';
      break;
  }

  return (
    <div className='play-quiz-podium__avatar-group'>
      <div className={'play-quiz-podium__podium-tag ' + podiumClass}>
        <div>{userName}</div>
        <div>{`${userPoints} ${t('quiz_points')}`}</div>
      </div>

      <div className={'play-quiz-podium__podium-avatar ' + podiumClass}>
        <img
          className='play-quiz-podium__podium-avatar_avatar'
          src={userAvatar}
          alt={userName}
        />
        <img className='play-quiz-podium__podium-avatar_bubble' src={Bubble} />
      </div>

      <img
        className={'play-quiz-podium__star ' + podiumClass}
        src={podiumStar}
        alt={userName}
      />

      <img
        className={'play-quiz-podium__blob ' + podiumClass}
        src={podiumBlob}
        alt={position}
      />
    </div>
  );
};
export default AvatarOnPodiumComponent;
