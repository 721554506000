import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AnalysisSegment } from '../../../utils/analysis';
import { useCourse } from '../../../utils/contexts/CourseContext';
import useAnalytics from '../../../utils/hooks/useAnalytics';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../utils/localStorageKeys';

const useClassProgress = () => {
  const { pathname } = useLocation();
  const { selectedCourse } = useCourse();
  const { t } = useTranslation();
  const { getItem, removeItem } = useLocalStorage();
  const { trackEvent } = useAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();

  const getHref = (path, key) => {
    let filter = getItem(path);

    if (filter) {
      try {
        filter = JSON.parse(filter);
      } catch (e) {
        filter = {};
      }
      return `${path}?${key}=${filter[key]}`;
    }
    return path;
  };

  const UNIT_TAB = {
    name: t('units'),
    href: `/${selectedCourse?.guid}/class-progress/units`,
    link: `/${selectedCourse?.guid}/class-progress/units`,
    filter: 'unit',
    testId: 'class-progress-units-tab'
  };

  const STANDARD_TAB = {
    name: t('Standards'),
    href: `/${selectedCourse?.guid}/class-progress/standards`,
    link: `/${selectedCourse?.guid}/class-progress/standards`,
    filter: 'standard',
    testId: 'class-progress-standards-tab'
  };

  const TIMELINE_TAB = {
    name: t('timeline'),
    href: `/${selectedCourse?.guid}/class-progress/timeline`,
    link: `/${selectedCourse?.guid}/class-progress/timeline`,
    filter: 'timeline',
    testId: 'class-progress-timeline-tab'
  };

  const [currentTab, setCurrentTab] = useState(UNIT_TAB.name);
  const [tabs, setTabs] = useState([]);
  const [isWaitingForTabManagement, setIsWaitingForTabManagement] =
    useState(true);

  useEffect(() => {
    if (!selectedCourse?.guid && !isWaitingForTabManagement) return;

    setTabs([]);
    setIsWaitingForTabManagement(true);
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse) return;

    const isFromLogin =
      localStorage.getItem(localStorageKeys.traceIsLoginHome) || false;

    if (searchParams.get('source')) {
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Open_TD_Button_Clicked, {
        source: searchParams.get('source')
      });
    }

    // set a timeout to avoid race condition
    setTimeout(() => {
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Class_Page_Viewed, {
        source: searchParams.get('source')
      });

      searchParams.delete('source');
      setSearchParams(searchParams);
    }, 100);

    removeItem(localStorageKeys.traceIsLoginHome);
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse?.guid) return;

    let unitTab = {
      ...UNIT_TAB,
      href: getHref(`/${selectedCourse?.guid}/class-progress/units`, 'unit')
    };
    let standardTab = {
      ...STANDARD_TAB,
      href: getHref(
        `/${selectedCourse?.guid}/class-progress/standards`,
        'standard'
      )
    };

    if (selectedCourse.standard_blueberry === 1) {
      setTabs([unitTab, standardTab]);
    } else {
      setTabs([unitTab]);
    }

    if (isWaitingForTabManagement) {
      setIsWaitingForTabManagement(false);
    }
  }, [selectedCourse, currentTab, pathname]);

  const onChangeTab = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  return {
    tabs,
    currentTab,
    onChangeTab
  };
};

export default useClassProgress;
