import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import './i18n/i18n';
import i18n from './i18n/i18n';
import Router from './router/Router';
import './style/index.scss';
import { AuthProvider } from './utils/contexts/AuthContext';
import getEnvironment from './utils/helpers/environment';
import { initializeSentry } from './utils/helpers/sentryHelper';
import { setBBPracticeConfig } from './utils/helpers/setBBPracticeConfig';

console.log(`BB TD v. 2024.11.14`, import.meta.env.VITE_ENVIRONMENT);
setBBPracticeConfig();

if (getEnvironment() === 'PRODUCTION') {
  initializeSentry();
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </I18nextProvider>
  </GoogleOAuthProvider>
);
