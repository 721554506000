import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';

import useStudentsDataParsers from '../../../students/useStudentsDataParsers';
import useUserReport from '../userReport/useUserReport';

import clockImage from '../../../../../assets/images/pdf-clock.png';
import happyImage from '../../../../../assets/images/pdf-emoji-happy.png';
import sadImage from '../../../../../assets/images/pdf-emoji-sad.png';
import exclamationImage from '../../../../../assets/images/pdf-exclamation.png';
import abandonedImage from '../../../../../assets/images/pdf-hand.png';
import logoImage from '../../../../../assets/images/pdf-logo.png';
import pencilImage from '../../../../../assets/images/pdf-pencil.png';
import incorrectImage from '../../../../../assets/images/pdf-thumb-down.png';
import correctImage from '../../../../../assets/images/pdf-thumb-up.png';

Font.register({
  family: 'Poppins',
  fonts: [
    { src: '/assets/fonts/Poppins-Medium.ttf', fontWeight: '500' },
    { src: '/assets/fonts/Poppins-Bold.ttf', fontWeight: 'bold' }
  ]
});

const ZOOM_RATIO = 0.75;

const styles = StyleSheet.create({
  page: {
    padding: 24 * ZOOM_RATIO,
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    color: '#303949',
    fontSize: 14 * ZOOM_RATIO
  },
  pageHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'spaceBetween'
  },
  pageHeaderLeft: {
    width: '50%'
  },
  pageHeaderRight: {
    width: '50%',
    position: 'relative'
  },
  headerTitle: {
    fontSize: 18 * ZOOM_RATIO
  },
  headerSubtitle: {
    color: '#939DB0',
    fontSize: 14 * ZOOM_RATIO
  },
  logo: {
    width: 123 * ZOOM_RATIO,
    height: 54 * ZOOM_RATIO,
    position: 'absolute',
    right: 0
  },
  card: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8 * ZOOM_RATIO,
    marginBottom: 8 * ZOOM_RATIO
  },
  cardHeader: {
    width: '100%',
    borderTopLeftRadius: 16 * ZOOM_RATIO,
    borderTopRightRadius: 16 * ZOOM_RATIO,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    paddingTop: 12 * ZOOM_RATIO,
    paddingBottom: 12 * ZOOM_RATIO,
    paddingLeft: 16 * ZOOM_RATIO,
    paddingRight: 16 * ZOOM_RATIO,
    backgroundColor: '#F0F4F8',
    color: '#4D586C',
    fontSize: 16 * ZOOM_RATIO
  },
  cardBody: {
    width: '100%',
    flexDirection: 'column',
    padding: 16 * ZOOM_RATIO,
    border: 1 * ZOOM_RATIO + 'px solid #DFE5EC'
  },
  cardBodyCenter: {
    alignItems: 'center'
  },
  cardBodyMiddle: {
    borderTop: 'none',
    borderBottom: 'none'
  },
  cardBodyLast: {
    borderBottomRightRadius: 16 * ZOOM_RATIO,
    borderBottomLeftRadius: 16 * ZOOM_RATIO
  },
  tableTitle: {
    fontWeight: 600,
    fontSize: 14 * ZOOM_RATIO,
    width: 470 * ZOOM_RATIO,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8 * ZOOM_RATIO
  },
  tableTitleInfo: {
    flexDirection: 'row'
  },
  tableTitleInfoIcon: {
    width: 18 * ZOOM_RATIO,
    height: 18 * ZOOM_RATIO,
    marginRight: 6 * ZOOM_RATIO,
    marginLeft: 6 * ZOOM_RATIO
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 470 * ZOOM_RATIO,
    borderTop: 1 * ZOOM_RATIO + 'px solid #DFE5EC'
  },
  tableRowLast: {
    borderBottom: 1 * ZOOM_RATIO + 'px solid #DFE5EC'
  },
  tableCellHeader: {
    backgroundColor: '#FBFCFD',
    color: '#4D586C',
    fontSize: 14 * ZOOM_RATIO,
    width: 270 * ZOOM_RATIO,
    paddingTop: 0 * ZOOM_RATIO,
    paddingBottom: 0 * ZOOM_RATIO,
    paddingLeft: 12 * ZOOM_RATIO,
    paddingRight: 12 * ZOOM_RATIO,
    borderLeft: 1 * ZOOM_RATIO + 'px solid #DFE5EC',
    height: 40 * ZOOM_RATIO,
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCell: {
    fontSize: 16 * ZOOM_RATIO,
    width: 100 * ZOOM_RATIO,
    height: 40 * ZOOM_RATIO,
    borderLeft: 1 * ZOOM_RATIO + 'px solid #DFE5EC',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableCellLast: {
    borderRight: 1 * ZOOM_RATIO + 'px solid #DFE5EC'
  },
  sectionTitle: {
    flexDirection: 'row',
    marginBottom: 8 * ZOOM_RATIO,
    alignItems: 'center'
  },
  sectionTitleText: {
    fontSize: 14 * ZOOM_RATIO,
    fontWeight: 600
  },
  sectionHighlight: {
    color: '#647082',
    fontSize: 14 * ZOOM_RATIO,
    fontWeigh: 500,
    padding: 12 * ZOOM_RATIO,
    borderRadius: 8 * ZOOM_RATIO,
    border: 1 * ZOOM_RATIO + 'px solid #F0F4F8',
    backgroundColor: '#FBFCFD',
    marginBottom: 8 * ZOOM_RATIO,
    lineHeight: 1.3
  },
  sectionItem: {
    marginTop: 2 * ZOOM_RATIO,
    marginBottom: 2 * ZOOM_RATIO,
    marginLeft: 12 * ZOOM_RATIO
  },
  sectionItemEmptyCase: {
    color: '#939DB0',
    marginLeft: 12 * ZOOM_RATIO
  },
  icon: {
    width: 18 * ZOOM_RATIO,
    height: 18 * ZOOM_RATIO,
    marginRight: 6 * ZOOM_RATIO
  }
});

const UserReportPDF = ({ className, user, currentDate, translations }) => {
  const { losNeedsHelp, losIsMastered, losIsMasteredRecently } =
    useUserReport(user);
  const { getUserPerformanceData } = useStudentsDataParsers();
  const performanceData = getUserPerformanceData(user?.activities);

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.pageHeader}>
          <View style={styles.pageHeaderLeft}>
            <Text style={styles.headerTitle}>
              {user.name + ' ' + user.lastname}
            </Text>
            <Text style={styles.headerSubtitle}>{className}</Text>
            <Text style={styles.headerSubtitle}>
              {`${translations.reportDate} ${currentDate}`}
            </Text>
          </View>
          <View style={styles.pageHeaderRight}>
            <Image cache src={logoImage} style={styles.logo} />
          </View>
        </View>

        <View style={styles.card}>
          <View style={styles.cardHeader}>
            <Text>{translations.progressTimeRangeTitle}</Text>
          </View>
          <View
            style={[
              styles.cardBody,
              styles.cardBodyLast,
              styles.cardBodyCenter
            ]}
          >
            <View style={styles.tableTitle}>
              <Text>{translations.activitiesTitle}</Text>
              <View style={styles.tableTitleInfo}>
                <Image
                  cache
                  src={clockImage}
                  style={styles.tableTitleInfoIcon}
                />
                <Text>{user.time}</Text>
                <Image
                  cache
                  src={pencilImage}
                  style={styles.tableTitleInfoIcon}
                />
                <Text>{user.number_activities}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCellHeader}>
                <Image cache src={correctImage} style={styles.icon} />
                <Text>{translations.activitiesCorrect}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{performanceData?.correct}</Text>
              </View>
              <View style={[styles.tableCell, styles.tableCellLast]}>
                <Text>{performanceData?.correctPercent}%</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCellHeader}>
                <Image cache src={incorrectImage} style={styles.icon} />
                <Text>{translations.activitiesIncorrect}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{performanceData?.incorrect}</Text>
              </View>
              <View style={[styles.tableCell, styles.tableCellLast]}>
                <Text>{performanceData?.incorrectPercent}%</Text>
              </View>
            </View>

            <View style={[styles.tableRow, styles.tableRowLast]}>
              <View style={styles.tableCellHeader}>
                <Image cache src={abandonedImage} style={styles.icon} />
                <Text>{translations.activitiesAbandoned}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{performanceData?.abandoned}</Text>
              </View>
              <View style={[styles.tableCell, styles.tableCellLast]}>
                <Text>{performanceData?.abandonedPercent}%</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.card}>
          <View style={styles.cardHeader}>
            <Text>{translations.progressSummaryTitle}</Text>
          </View>
          <View style={[styles.cardBody, styles.cardBodyFirst]}>
            <View style={styles.sectionTitle}>
              <Image cache src={exclamationImage} style={styles.icon} />
              <Text style={styles.sectionTitleText}>
                {translations.difficultiesTitle}
              </Text>
            </View>
            <Text style={styles.sectionHighlight}>
              {translations.difficultiesInfo}
            </Text>
            {losNeedsHelp?.length > 0 ? (
              losNeedsHelp.map((lo, index) => (
                <Text key={index} style={styles.sectionItem}>
                  {`${lo?.unit?.name} > ${lo?.lesson?.name}: ${lo?.name}`}
                </Text>
              ))
            ) : (
              <Text style={styles.sectionItemEmptyCase}>
                {translations.noContent}
              </Text>
            )}
          </View>
          <View style={[styles.cardBody, styles.cardBodyMiddle]}>
            <View style={styles.sectionTitle}>
              <Image cache src={happyImage} style={styles.icon} />
              <Text style={styles.sectionTitleText}>
                {translations.masteredTitle}
              </Text>
            </View>

            <Text style={styles.sectionHighlight}>
              {translations.masteredInfo}
            </Text>
            {losIsMastered?.length > 0 ? (
              losIsMastered?.map((lo, index) => (
                <Text key={index} style={styles.sectionItem}>
                  {`${lo?.unit?.name} > ${lo?.lesson?.name}: ${lo?.name}`}
                </Text>
              ))
            ) : (
              <Text style={styles.sectionItemEmptyCase}>
                {translations.noContent}
              </Text>
            )}
          </View>

          <View style={[styles.cardBody, styles.cardBodyLast]}>
            <View style={styles.sectionTitle}>
              <Image cache src={sadImage} style={styles.icon} />
              <Text style={styles.sectionTitleText}>
                {translations.forgottenTitle}
              </Text>
            </View>

            <Text style={styles.sectionHighlight}>
              {translations.forgottenInfo}
            </Text>
            {losIsMasteredRecently?.length > 0 ? (
              losIsMasteredRecently?.map((lo, index) => (
                <Text key={index} style={styles.sectionItem}>
                  {`${lo?.unit?.name} > ${lo?.lesson?.name}: ${lo?.name}`}
                </Text>
              ))
            ) : (
              <Text style={styles.sectionItemEmptyCase}>
                {translations.noContent}
              </Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default UserReportPDF;
