import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as ChevronRight } from '../../../../../assets/icons/chevron_right.svg';
import LessonDetailsActivityDialog from './lessonDetailsActivityDialog/LessonDetailsActivityDialog';
import LessonDetailsListItem from './LessonDetailsListItem/LessonDetailsListItem';
import LessonProgressPanel from './LessonProgressPanel/LessonProgressPanel';
import './studentLessonDetails.scss';
import useStudentLessonDetails from './useStudentLessonDetails';

const LessonDetails = () => {
  const { lessonGuid } = useParams();
  const { lessonsData, isLoading } = useStudentLessonDetails(lessonGuid);

  const [activityDialogOpen, setActivityDialogOpen] = useState(false);
  const [selectedLO, setSelectedLO] = useState(null);

  const [loAttemptsData, setLoAttemptsData] = useState(null);
  const [attemptIndex, setAttemptIndex] = useState(null);

  if (isLoading) {
    return (
      <div className='lesson-details'>
        <div className='lesson-details__content'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className='lesson-details'>
      <div className='lesson-details__content'>
        <div className='lesson-details__breadcrumbs'>
          <div className='lesson-details__breadcrumbs-unit'>
            {lessonsData?.los[0]?.unit?.name}
          </div>
          <div className='lesson-details__breadcrumbs-separator'>
            <ChevronRight />
          </div>
          <div className='lesson-details__breadcrumbs-lesson '>
            {lessonsData?.los[0]?.lesson?.name}
          </div>
        </div>
        <div className='lesson-details__lesson-progress'>
          <LessonProgressPanel
            progress={lessonsData?.progress}
            totalActivities={lessonsData?.number_of_activities}
            timeInMs={lessonsData?.total_time_in_ms}
          />
        </div>
        <div className='lesson-details__list'>
          {isLoading ? (
            <CircularProgress />
          ) : (
            lessonsData?.los?.map((item) => (
              <LessonDetailsListItem
                key={item?.interventionGuid}
                loData={item}
                interventionGuid={item?.interventionGuid}
                isFirstLOWithInterventions={item.isFirstLOWithInterventions}
                onShowActivityDialogClick={({
                  loAttemptsData,
                  attemptIndex
                }) => {
                  setActivityDialogOpen(true);
                  setSelectedLO(item);
                  setLoAttemptsData(loAttemptsData);
                  setAttemptIndex(attemptIndex);
                }}
              />
            ))
          )}
        </div>

        <LessonDetailsActivityDialog
          isOpen={activityDialogOpen}
          onClose={() => setActivityDialogOpen(false)}
          selectedLO={selectedLO}
          loAttemptsData={loAttemptsData}
          startAttemptIndex={attemptIndex}
        />
      </div>
    </div>
  );
};

export default LessonDetails;
