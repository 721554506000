import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { CloseCircleIcon, InfoCircleIcon } from '../../assets/icons';
import { TDHeaderButton } from '../../atoms/TDUILib';
import { AnalysisSegment } from '../../utils/analysis';
import generateAssetURL from '../../utils/helpers/generateAssetURL';
import useAnalytics from '../../utils/hooks/useAnalytics';
import useAuth from '../../utils/hooks/useAuth';
import './PageWithHeader.scss';

// This is a component that has a header with three sections: left, center, and right.
// It also has a tooltip that can be displayed on the left section.
const PageWithHeader = ({
  left = null,
  center = null,
  right = null,
  tooltip = null,
  children = null,
  pageName = ''
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);
  const { schoolAssetsPath } = useAuth();
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (buttonRef.current && tooltipRef.current) {
      // the horizontal position of the button is different from the tooltip left side to the button left side
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      setButtonPosition({
        top: -25, // static because it is based on the height of the tooltip arrow
        left: buttonRect.left + buttonRect.width / 2 - tooltipRect.left - 10
      });
    }
  }, [buttonRef.current, tooltipRef.current, isTooltipOpen]);

  const onToggleTooltip = () => {
    // track event only if the tooltip is being toggled by the user.
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Info_Panel_Clicked, {
      page: pageName,
      status: isTooltipOpen ? 'closed' : 'opened'
    });

    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <div className='page-with-header__container'>
      <div className='page-with-header__header'>
        <div className='page-with-header__header--row'>
          <div className='page-with-header__header--left'>
            <div className='page-with-header__header--left-content'>{left}</div>
            {tooltip && (
              <div
                className='page-with-header__header--left-tooltip'
                ref={buttonRef}
              >
                <TDHeaderButton
                  icon={
                    isTooltipOpen ? <CloseCircleIcon /> : <InfoCircleIcon />
                  }
                  border
                  onClick={onToggleTooltip}
                />
              </div>
            )}
          </div>
          <div className='page-with-header__header--center'>{center}</div>
          <div className='page-with-header__header--right'>{right}</div>
        </div>
        {tooltip && isTooltipOpen && (
          <div className='page-with-header__tooltip' ref={tooltipRef}>
            <style>
              {`
                .page-with-header__tooltip::after {
                  left: ${buttonPosition.left}px;
                }
              `}
            </style>
            <div className='page-with-header__tooltip-content-container'>
              <div className='page-with-header__tooltip-content-image'>
                <img
                  src={generateAssetURL({
                    asset: 'avatar_searching.svg',
                    schoolAssetsPath
                  })}
                  alt='page-with-header'
                />
              </div>
              <div className='page-with-header__tooltip-content'>{tooltip}</div>
            </div>
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

PageWithHeader.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
  tooltip: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default PageWithHeader;
