import { useTranslation } from 'react-i18next';
import { CheckmarkIcon, CloseIcon, UploadIcon } from '../../assets/icons';
import './TDDragAndDrop.scss';
import useTDDragAndDrop from './useTDDragAndDrop';

const TDDragAndDrop = ({
  acceptedTypes = [],
  maxFileSize = 10 * 1024 * 1024,
  onChange = () => {},
  onClear = () => {}
}) => {
  const { t } = useTranslation();

  const {
    rootProps,
    inputProps,
    isDragActive,
    hasError,
    errorMessage,
    openFileDialog,
    isFileDroppedSuccessfully,
    acceptedFiles,
    onClearFiles
  } = useTDDragAndDrop({
    acceptedTypes,
    maxFileSize,
    onChange
  });

  const showSizeToTheNearestDenomination = (size) => {
    let tempSize = size;
    let unit = 'B';
    if (size < 1024 * 1024) {
      unit = 'KB';
      tempSize = size / 1024;
    } else if (size < 1024 * 1024 * 1024) {
      unit = 'MB';
      tempSize = size / 1024 / 1024;
    } else {
      unit = 'GB';
      tempSize = size / 1024 / 1024 / 1024;
    }

    return `${Math.round(tempSize * 100) / 100} ${unit}`;
  };

  const getFileExtension = (fileName) => {
    return `.${fileName.split('.').pop()}`;
  };

  return (
    <div className='td-drag-and-drop'>
      <div
        {...rootProps}
        className={`td-drag-and-drop--container ${
          isDragActive ? 'is-dragging' : ''
        } ${isFileDroppedSuccessfully ? 'is-success' : ''}`}
      >
        <input {...inputProps} />

        {!isFileDroppedSuccessfully && (
          <div className='td-drag-and-drop--placeholder'>
            <div className='td-drag-and-drop--placeholder-icon'>
              <UploadIcon />
            </div>
            <p className='td-drag-and-drop--description'>
              {t('drag_and_drop_description')}{' '}
              <span
                className='td-drag-and-drop--select'
                onClick={openFileDialog}
              >
                {t('drag_and_drop_select')}
              </span>
              {t('drag_and_drop_it')}.
            </p>
            <p className='td-drag-and-drop--max-file-size'>
              {t('drag_and_drop_max_file_size', {
                maxFileSize: maxFileSize / 1024 / 1024
              })}
            </p>
          </div>
        )}

        {isFileDroppedSuccessfully && (
          <div className='td-drag-and-drop--success'>
            <div className='td-drag-and-drop--close-icon'>
              <CloseIcon
                onClick={() => {
                  onClearFiles();
                  onClear();
                }}
              />
            </div>
            <div className='td-drag-and-drop--success-icon'>
              <CheckmarkIcon />
            </div>
            <div className='td-drag-and-drop--success-message'>
              <div>{t('drag_and_drop_file_uploaded')}</div>
              {acceptedFiles.map((file) => (
                <div
                  className='td-drag-and-drop--success-file-details'
                  key={file.name}
                >
                  <span>{getFileExtension(file.name)}</span>
                  <span>{showSizeToTheNearestDenomination(file.size)}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {hasError && <p className='td-drag-and-drop--error'>{errorMessage}</p>}
    </div>
  );
};

export default TDDragAndDrop;
