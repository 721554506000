import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../../../../assets/icons';
import {
  TDButton,
  TDDialog,
  TDHeaderButton,
  TDTypography
} from '../../../../../atoms/TDUILib';
import { AnalysisSegment } from '../../../../../utils/analysis';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import { useStudent } from '../../../../../utils/contexts/StudentContext';
import { validateInterventions } from '../../../../../utils/crud/student';
import useAnalytics from '../../../../../utils/hooks/useAnalytics';
import useAuth from '../../../../../utils/hooks/useAuth';
import './studentInterventionsValidationDialog.scss';

const StudentInterventionsValidationDialog = ({
  isOpen = false,
  onClose = () => {},
  callback = () => {}
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { selectedCourse } = useCourse();
  const { student, studentGuid, studentInterventions } = useStudent();
  const { user } = useAuth();

  const [interventionsToValidate, setInterventionsToValidate] = useState([]);
  const [isSavingInterventionsValidation, setIsSavingInterventionsValidation] =
    useState(false);

  const handleAcceptAndClose = async () => {
    const filteredInterventionsToValidate = interventionsToValidate.filter(
      (intervention) => intervention.intervention_validation !== 'pending'
    );

    if (
      user?.devmode &&
      user?.is_demo &&
      !filteredInterventionsToValidate.length > 0
    ) {
      callback();
      return;
    }

    setIsSavingInterventionsValidation(true);
    const payload = {
      courseGuid: selectedCourse?.guid,
      studentGuid,
      validationData: filteredInterventionsToValidate.map((intervention) => ({
        learningOutcomeGuid: intervention.learning_outcome.guid,
        validation: intervention.intervention_validation
      }))
    };

    // send event for each intervention
    filteredInterventionsToValidate.forEach((intervention) => {
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_LO_Validated, {
        student_id: studentGuid,
        lo_id: intervention.learning_outcome.guid,
        lo_name: intervention.learning_outcome.description,
        kc_id: intervention.kcs[0]?.guid,
        kc_name: intervention.kcs[0]?.name,
        validation_info: intervention.intervention_validation,
        source: 'Validation Pop up'
      });
    });

    await validateInterventions(payload);
    setIsSavingInterventionsValidation(false);

    // execute callback function once the interventions are validated
    callback();
  };

  const handleValidateIntervention = (
    indexOfInterventionToUpdate,
    validation
  ) => {
    let tempInterventionsToValidate = [...interventionsToValidate];
    tempInterventionsToValidate[
      indexOfInterventionToUpdate
    ].intervention_validation = validation;
    setInterventionsToValidate(tempInterventionsToValidate);
  };

  useEffect(() => {
    setInterventionsToValidate(
      (studentInterventions || []).filter(
        (intervention) => intervention.intervention_validation === 'pending'
      )
    );
  }, [studentInterventions]);

  return (
    <TDDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('interventions_popup_title', {
        studentName: `${student?.name}`
      })}
    >
      <div className='student-details-topbar__interventions-container'>
        {interventionsToValidate.map((intervention, index) => (
          <div
            key={`${intervention.learning_outcome.guid}-${intervention?.last_student_attempt}`}
            className='student-details-topbar__intervention-row'
          >
            <div className='student-details-topbar__intervention-row-description'>
              <TDTypography variant='body-l'>
                {intervention.learning_outcome.description}
              </TDTypography>
            </div>
            <div className='student-details-topbar__intervention-row-buttons'>
              <TDHeaderButton
                border
                selected={intervention.intervention_validation === 'ok'}
                icon={<ThumbsUpIcon />}
                onClick={() => handleValidateIntervention(index, 'ok')}
              />
              <TDHeaderButton
                border
                selected={intervention.intervention_validation === 'not-ok'}
                icon={<ThumbsDownIcon />}
                onClick={() => handleValidateIntervention(index, 'not-ok')}
              />
            </div>
          </div>
        ))}
        <div className='student-details-topbar__interventions-buttons'>
          <TDButton
            variant='outline'
            color='primary'
            disabled={
              (!(user?.devmode && user?.is_demo) &&
                interventionsToValidate.filter(
                  (intervention) =>
                    intervention.intervention_validation === 'pending'
                ).length > 0) ||
              isSavingInterventionsValidation
            }
            onClick={handleAcceptAndClose}
          >
            {isSavingInterventionsValidation ? (
              <CircularProgress size={20} />
            ) : (
              t('interventions_popup_accept_and_close')
            )}
          </TDButton>
        </div>
      </div>
    </TDDialog>
  );
};

export default StudentInterventionsValidationDialog;
