import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import { useStudent } from '../../../../../utils/contexts/StudentContext';
import {
  getStudentInterventions,
  getStudentLessonDetails
} from '../../../../../utils/crud/student';
import uuidv4 from '../../../../../utils/helpers/uuidv4';

const useStudentLessonDetails = (currentLessonGuid) => {
  const { studentGuid } = useParams();
  const { selectedCourse } = useCourse();
  const [isLoading, setIsLoading] = useState(true);
  const { setStudentInterventions } = useStudent();
  const [lessonsData, setLessonsData] = useState([]);

  // Get data
  const getData = async () => {
    setIsLoading(true);

    const tempInterventionsData = await getStudentInterventions({
      courseGuid: selectedCourse?.guid,
      studentGuid: studentGuid
    });

    const tempData = await getStudentLessonDetails({
      courseGuid: selectedCourse?.guid,
      studentGuid: studentGuid,
      lessonGuid: currentLessonGuid
    });

    const apiData = tempData || [];

    const losWithInterventions = tempInterventionsData?.los.map((item) => {
      return {
        interventionGuid: uuidv4(),
        ...item,
        ...item.learning_outcome,
        feedback: item.intervention_validation
      };
    });

    setStudentInterventions(losWithInterventions || []);

    apiData.los.map((item) => {
      const tempIntervention = losWithInterventions?.find(
        (intervention) => intervention?.guid === item.guid
      );
      item.hasIntervention = tempIntervention ? true : false;
      item.interventionReason = tempIntervention?.need_help_reason;
      item.interventionFeedback = tempIntervention?.feedback;
      item.interventionGuid = tempIntervention?.interventionGuid;
    });

    // flag first LO with interventions
    const firstLOWithInterventions = apiData?.los?.find(
      (item) => item.hasIntervention
    );

    firstLOWithInterventions.isFirstLOWithInterventions = true;
    apiData.los.forEach((item) => {
      if (item.guid === firstLOWithInterventions.guid) {
        item.isFirstLOWithInterventions = true;
      }
    });

    setLessonsData(apiData);
    setIsLoading(false);
  };

  // Get initial data
  useEffect(() => {
    if (!selectedCourse?.guid) return;
    getData();
  }, [studentGuid, selectedCourse]);

  return {
    lessonsData,
    isLoading
  };
};

export default useStudentLessonDetails;
