import BulkStudentImportForm from '../BulkStudentImportForm/BulkStudentImportForm';
import ManualAddStudentsForm from '../ManualAddStudentsForm/ManualAddStudentsForm';
import { useAddStudentsForm } from '../useAddStudentsForm';

const AddStudentsFormTabs = ({ onSave, onCancel, isSaving }) => {
  const { selectedTab } = useAddStudentsForm();

  return (
    <>
      {selectedTab === 'manual' && (
        <ManualAddStudentsForm
          onCancel={onCancel}
          onSave={onSave}
          isSaving={isSaving}
        />
      )}
      {selectedTab === 'import' && (
        <BulkStudentImportForm
          onCancel={onCancel}
          onSave={onSave}
          isSaving={isSaving}
        />
      )}
    </>
  );
};

export default AddStudentsFormTabs;
