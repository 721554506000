import { useTranslation } from 'react-i18next';
import { ReactComponent as HelpIcon } from '../../../../../assets/icons/help_sm.svg';
import TooltipCell from '../TooltipCell/TooltipCell';
import './cellStudentNameWithHelpIcon.scss';

const CellStudentNameWithHelpIcon = ({
  name,
  lastName,
  needsHelpInfo,
  onClickOnStudent
}) => {
  const { t } = useTranslation();
  return (
    <TooltipCell
      title={
        needsHelpInfo?.length > 0 ? (
          <div className='cell-student-name-with-help-icon__tooltip'>
            <div>{t('student_problem')}</div>
            {needsHelpInfo.map((info, index) => (
              <div key={`info-tooltip-${name}-${lastName}-${index}`}>
                {info.description}
              </div>
            ))}
          </div>
        ) : null
      }
      placement='right'
    >
      <div
        className={`cell-student-name-with-help-icon ${
          needsHelpInfo?.length > 0
            ? 'cell-student-name-with-help-icon--needs-help'
            : ''
        }`}
        onClick={onClickOnStudent}
      >
        <div className='cell-student-name-with-help-icon__icon'>
          <HelpIcon />
        </div>

        <div className='cell-student-name-with-help-icon__name'>{`${name} ${lastName}`}</div>
      </div>
    </TooltipCell>
  );
};

export default CellStudentNameWithHelpIcon;
