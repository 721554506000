import { KeyboardDoubleArrowUpSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from '../../atoms/card/Card';
import InfoTooltip from '../../atoms/tooltip/InfoTooltip';
import { useCourse } from '../../utils/contexts/CourseContext';

const SingleStudentProfilePopup = ({
  name,
  lastLogin,
  guid,
  effort,
  progress,
  onClickStudentDetail
}) => {
  const { t, i18n } = useTranslation();
  const { selectedCourse } = useCourse();
  return (
    <Card variant='outlined'>
      <div className='single-student-profile-popup'>
        <div className='single-student-profile-popup__header'>
          <div className='single-student-profile-popup__header-name'>
            {name}
          </div>
          <div className='single-student-profile-popup__header-details'>
            <div className='single-student-profile-popup__header-last-login'>
              {t('last_login')}
              {`: `}
              {Intl.DateTimeFormat(i18n.language, {
                day: 'numeric',
                month: 'short'
              }).format(new Date(lastLogin))}
            </div>
            <div className='single-student-profile-popup__header-see-profile'>
              <Link
                to={`/${selectedCourse?.guid}/students/${guid}`}
                onClick={() => onClickStudentDetail(guid)}
                state={{ from: location.pathname }}
              >
                {t('view_profile')}
              </Link>
            </div>
          </div>
        </div>
        <div className='single-student-profile-popup__body'>
          <div className='single-student-profile-popup__body-item'>
            <div className='single-student-profile-popup__body-item-label'>
              <div>{t('effort')}</div>
              <InfoTooltip text={t('effort_description')} />
            </div>
            <div className='single-student-profile-popup__body-item-content'>
              {effort > 100 && (
                <div className='single-student-profile-popup__body-item-content-icon'>
                  <KeyboardDoubleArrowUpSharp color='success' />
                </div>
              )}
              <div className='single-student-profile-popup__body-item-content-text'>
                <div className='single-student-profile-popup__body-item-value'>
                  {effort > 100 ? 100 : parseInt(effort)}%
                </div>
                <div className='single-student-profile-popup__body-item-helper'>
                  {effort > 100 && (
                    <div>{`+${parseInt(effort)}% ${t('total')}`}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='single-student-profile-popup__body-item'>
            <div className='single-student-profile-popup__body-item-label'>
              <div>{t('progress')}</div>
              <InfoTooltip text={t('progress_description')} />
            </div>
            <div className='single-student-profile-popup__body-item-content'>
              {progress > 100 && (
                <div className='single-student-profile-popup__body-item-content-icon'>
                  <KeyboardDoubleArrowUpSharp color='success' />
                </div>
              )}
              <div className='single-student-profile-popup__body-item-content-text'>
                <div className='single-student-profile-popup__body-item-value'>
                  {progress > 100 ? 100 : parseInt(progress)}%
                </div>
                <div className='single-student-profile-popup__body-item-helper'>
                  {progress > 100 && (
                    <div>{`+${parseInt(progress)}% ${t('total')}`}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SingleStudentProfilePopup;
