import { useEffect, useState } from 'react';
import { useCourse } from '../../../utils/contexts/CourseContext';
// import dummyJsonData from './course-insights.json';
import { AnalysisSegment } from '../../../utils/analysis';
import { getReportCourse } from '../../../utils/crud/report';
import { getStudents } from '../../../utils/crud/student';
import { tryJsonParse } from '../../../utils/helpers/tryJsonParse';
import useAnalytics from '../../../utils/hooks/useAnalytics';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import useStudentsSorting from '../students/useStudentsSorting';

const useListReports = (t) => {
  const { selectedCourse } = useCourse();
  const { sortData } = useStudentsSorting();
  const { getItem, setItem } = useLocalStorage();
  const { trackEvent } = useAnalytics();

  const [report, setReport] = useState([]);
  const [fullStudentsReport, setFullStudentsReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTimeRange, setSelectedTimeRange] = useState();
  const [selectedOrderBy, setSelectedOrderBy] = useState();

  const REPORTS_PAGE_LOCAL_STORAGE_KEY = '/reports';

  const DEFAULT_TIME_RANGE = { label: t('last_week'), value: '7' };
  const DEFAULT_ORDER_BY = {
    label: t('alphabetical_order_by_lastname'),
    value: 'lastname'
  };

  const SORT_OPTIONS = ['name', 'lastname'].map((option) => ({
    label: t(`alphabetical_order_by_${option}`),
    value: option
  }));

  const TIME_RANGE_OPTIONS = [
    { value: '7', label: t('last_week') },
    { value: '30', label: t('last_month') },
    { value: '90', label: t('last_term') }
  ];

  const fetchReportCourse = async () => {
    setIsLoading(true);
    const reportResponseApi = await getReportCourse({
      courseGuid: selectedCourse?.guid,
      days: selectedTimeRange.value
    });

    if (!reportResponseApi) {
      setIsLoading(false);
      setReport([]);
      return;
    }

    let reportResponse = calculatePercentage(reportResponseApi);
    const sortedStudents = sortData(
      reportResponse.students,
      selectedOrderBy.value
    );

    // order by student last name
    reportResponse = {
      ...reportResponse,
      students: sortedStudents
    };

    setReport(reportResponse);

    // get activities data
    let tempData = await getStudents({
      courseGuid: selectedCourse?.guid,
      days: selectedTimeRange.value
    });

    const fullReport = reportResponse.students.map((student) => {
      const userActivities =
        tempData.users.find((user) => user.guid === student.guid)?.activities ||
        [];

      return {
        ...student,
        activities: userActivities
      };
    });

    setFullStudentsReport(fullReport);
    setIsLoading(false);
  };

  const calculatePercentage = (data) => {
    const studentsArray = data?.students || [];

    const totalActivities = studentsArray.reduce(
      (total, student) => total + student?.number_activities,
      0
    );
    const totalStudents = studentsArray.length || 1; // Avoid division by zero
    const averageActivities = totalActivities / totalStudents;

    let studentsWithPercentage = studentsArray.map((student) => ({
      ...student,
      percentage:
        totalActivities === 0
          ? 0
          : (
              ((student?.number_activities - averageActivities) /
                averageActivities) *
              100
            ).toFixed(2)
    }));

    return { ...data, students: studentsWithPercentage };
  };

  const saveSelectedOptions = () => {
    setItem(
      REPORTS_PAGE_LOCAL_STORAGE_KEY,
      JSON.stringify({
        timeRange: selectedTimeRange.value,
        orderBy: selectedOrderBy.value
      })
    );
  };

  useEffect(() => {
    if (report?.students?.length > 0) {
      const sortedStudents = sortData(report.students, selectedOrderBy.value);
      setReport({ ...report, students: sortedStudents });
      saveSelectedOptions();
    }
  }, [selectedOrderBy]);

  useEffect(() => {
    if (selectedCourse && selectedTimeRange) {
      fetchReportCourse();
      saveSelectedOptions();
    }
  }, [selectedCourse, selectedTimeRange]);

  useEffect(() => {
    const reportsPagePreferences = getItem(REPORTS_PAGE_LOCAL_STORAGE_KEY);

    let parsedReportsPagePreferences = tryJsonParse(reportsPagePreferences);

    if (parsedReportsPagePreferences) {
      // recover sorting option
      const orderByOption = SORT_OPTIONS.find(
        (option) => option.value === parsedReportsPagePreferences?.orderBy
      );

      if (orderByOption) {
        setSelectedOrderBy(orderByOption);
      } else {
        setSelectedOrderBy(DEFAULT_ORDER_BY);
      }

      // recover time range option
      const timeRangeOption = TIME_RANGE_OPTIONS.find(
        (option) => option.value === parsedReportsPagePreferences?.timeRange
      );

      if (timeRangeOption) {
        setSelectedTimeRange(timeRangeOption);
      } else {
        setSelectedTimeRange(DEFAULT_TIME_RANGE);
      }
    } else {
      setSelectedOrderBy(DEFAULT_ORDER_BY);
      setSelectedTimeRange(DEFAULT_TIME_RANGE);
    }

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Reports_Page_Viewed, {
      program_id: selectedCourse?.program_guid,
      class_name: selectedCourse?.school_group_name,
      number_of_students: selectedCourse?.users?.filter(
        (user) => user.role_guid === 'R01'
      ).length
    });
  }, []);

  return {
    report,
    fullStudentsReport,
    isLoading,
    selectedCourse,
    selectedTimeRange,
    selectedOrderBy,
    setSelectedTimeRange,
    setSelectedOrderBy,
    SORT_OPTIONS,
    TIME_RANGE_OPTIONS
  };
};

export default useListReports;
