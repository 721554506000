import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../utils/contexts/AuthContext';

const PrivateRoute = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return <Outlet />;
  }

  // add redirect_uri to the login url maintaining the current search params if they exist in a scalable way without string manipulation
  const redirect_uri = `${window.location.host}${window.location.pathname}`;
  let loginUrl = `/login`;
  const searchParams = window.location.search;

  if (searchParams.includes('user_logout')) {
    return <Navigate to='/login' />;
  }

  // check if there are search params
  if (searchParams) {
    // check if redirect_uri is already in the search params
    if (!searchParams.includes('redirect_uri')) {
      loginUrl += `${searchParams}&redirect_uri=${redirect_uri}`;
    } else {
      loginUrl += `&redirect_uri=${redirect_uri}`;
    }
  } else {
    loginUrl += `?redirect_uri=${redirect_uri}`;
  }

  return <Navigate to={loginUrl} />;
};

export default PrivateRoute;
