import { useTranslation } from 'react-i18next';
import { TDTypography } from '../../../../../../atoms/TDUILib';
import './studentsManagementTooltip.scss';
const StudentsManagementTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className='students-management-tooltip'>
      <div>
        <TDTypography variant='body-m' fontWeight='bold'>
          {t('page_tooltip.manage_and_configure_students_info')}
        </TDTypography>
      </div>
      <div>
        <TDTypography variant='body-m' fontWeight='medium'>
          {t('page_tooltip.add_and_edit_students')}
        </TDTypography>
        <TDTypography variant='body-m' fontWeight='medium'>
          {t('page_tooltip.download_login_sheets')}
        </TDTypography>
      </div>
    </div>
  );
};

export default StudentsManagementTooltip;
