import { Avatar } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './HeaderTabs.scss';

const HeaderTabs = ({
  tabs = [],
  selectedTab = '',
  onChangeTab = () => {},
  type = 'default',
  alignment = 'row'
}) => {
  return (
    <div
      className={clsx(
        'header-tabs',
        `header-tabs__type-${type}`,
        `header-tabs__align-${alignment}`
      )}
    >
      {tabs.map((tab) => (
        <div
          className={clsx('header-tabs__tab', {
            'header-tabs__tab--active': tab.id === selectedTab
          })}
          key={tab.id}
          onClick={() => onChangeTab(tab.id)}
        >
          <div className='header-tabs__tab-row'>
            {tab?.index && (
              <div className='header-tabs__tab-index'>{tab.index}</div>
            )}
            {tab?.image && <Avatar src={tab.image} />}
            <div className='header-tabs__tab-text'>{tab.name}</div>
            {tab?.badge && (
              <div className='header-tabs__tab-badge'>
                {tab.badge.split(' ')[0]}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

HeaderTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image: PropTypes.string
    })
  ),
  selectedTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  type: PropTypes.oneOf(['default', 'pill', 'light']),
  alignment: PropTypes.oneOf(['col', 'row'])
};

export default HeaderTabs;
