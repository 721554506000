import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '../../../../../../assets/icons/clock_sm.svg';
import { ReactComponent as PencilIcon } from '../../../../../../assets/icons/pencil_sm.svg';
import './lessonProgressPanel.scss';

const LessonProgressPanel = ({ progress, totalActivities, timeInMs }) => {
  const { t } = useTranslation();
  const userTimeHours = Math.floor(timeInMs / (1000 * 60 * 60));
  const userTimeMinutes = Math.floor((timeInMs / (1000 * 60)) % 60);
  const userTimeSeconds = Math.floor((timeInMs / 1000) % 60);

  return (
    <div className='lesson-progress-panel'>
      <div className='lesson-progress-panel__top-content'>
        <div className='lesson-progress-panel__legend'>
          <div className='lesson-progress-panel__title'>
            {t('student_details_mastered')}
          </div>
          <div className='lesson-progress-panel__progress'>
            {Math.round(progress * 100)}%
          </div>
        </div>
        <div className='lesson-progress-panel__progress-bar-bg'>
          <div
            className='lesson-progress-panel__progress-bar'
            style={{ width: progress * 100 + '%' }}
          ></div>
        </div>
      </div>
      <div className='lesson-progress-panel__footer'>
        <div className='lesson-progress-panel__footer-left'>
          {t('student_details_total_engagement')}
        </div>
        <div className='lesson-progress-panel__footer-right'>
          <div className='lesson-progress-panel__time'>
            <ClockIcon />
            {userTimeHours > 0 && (
              <>
                <span>{userTimeHours}</span>h
              </>
            )}
            <span>{userTimeMinutes}</span>m<span>{userTimeSeconds}</span>s
          </div>
          <div className='lesson-progress-panel__activities'>
            <PencilIcon />
            <span>{totalActivities}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonProgressPanel;
