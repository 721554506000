import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/outline/download.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/outline/plus-circle.svg';
import TDDialog from '../../../../atoms/TDDialog/TDDialog';
import TDHeaderButton from '../../../../atoms/TDHeaderButton/TDHeaderButton';
import TDSelect from '../../../../atoms/TDSelect/TDSelect';
import PageTitleWithTooltip from '../../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import Table from '../../../../components/table/Table';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import { AnalysisSegment } from '../../../../utils/analysis';
import AddStudentsForm from './components/AddStudentsForm/AddStudentsForm';
import ChangeClassForm from './components/ChangeClassForm/ChangeClassForm';
import DeleteStudentForm from './components/DeleteStudentForm/DeleteStudentForm';
import EditStudentForm from './components/EditStudentForm/EditStudentForm';
import EmptyStudentList from './components/EmptyStudentList/EmptyStudentList';
import LoginSheetsForm from './components/LoginSheetsForm/LoginSheetsForm';
import StudentsManagementTooltip from './components/StudentsManagementTooltip/StudentsManagementTooltip';
import './studentsManagement.scss';
import useStudentsManagement from './useStudentsManagement';

const StudentsManagement = () => {
  const { t } = useTranslation();
  const {
    columns,
    usersData,
    isLoading,
    onAddStudents,
    onDeleteStudent,
    groupName,
    isAddStudentsDialogOpen,
    openAddStudentsDialog,
    closeAddStudentsDialog,
    isSaving,
    isDeleteStudentDialogOpen,
    closeDeleteStudentDialog,
    itemToDelete,
    isDeleting,
    TABLE_MENU_OPTIONS,
    SORT_OPTIONS,
    selectedSortOption,
    onChangeSortOption,
    itemToEdit,
    isEditStudentDialogOpen,
    onEditStudent,
    isEditing,
    closeEditStudentDialog,
    isChangeClassDialogOpen,
    closeChangeClassDialog,
    itemToChangeClass,
    isChangingClass,
    onChangeClass,
    onChangeClassOption,
    selectedClassOption,
    classOptions,
    isLoginSheetsDialogOpen,
    openLoginSheetsDialog,
    closeLoginSheetsDialog
  } = useStudentsManagement();

  return (
    <div className='students-management'>
      <PageWithHeader
        left={
          <PageTitleWithTooltip
            title={t('students_management')}
            testId='students-management-page-heading'
          />
        }
        right={
          <div className='students-management__actions'>
            <TDSelect
              options={SORT_OPTIONS}
              selectedOption={selectedSortOption}
              onChange={onChangeSortOption}
              placeholder={t('order_by')}
              optionsTitle={t('order_by')}
            />
            <TDHeaderButton
              onClick={openLoginSheetsDialog}
              icon={<DownloadIcon />}
              disabled={usersData.length === 0}
              border
              small
            >
              {t('login_sheets_button')}
            </TDHeaderButton>
            <TDHeaderButton
              onClick={openAddStudentsDialog}
              icon={<AddIcon />}
              border
              small
            >
              {t('add_students_button')}
            </TDHeaderButton>
          </div>
        }
        tooltip={<StudentsManagementTooltip />}
        pageName={AnalysisSegment.PAGE_NAMES.Students_Management_Page}
      >
        <Table
          data={usersData}
          columns={columns}
          isLoading={isLoading}
          options={TABLE_MENU_OPTIONS}
          testId='students-management-table'
        />

        {!isLoading && usersData.length === 0 && (
          <EmptyStudentList onAddStudents={openAddStudentsDialog} />
        )}

        <TDDialog
          isOpen={isLoginSheetsDialogOpen}
          onClose={closeLoginSheetsDialog}
          title={t('students_management_login_sheets_title')}
        >
          <LoginSheetsForm
            usersData={usersData}
            onCancel={closeLoginSheetsDialog}
          />
        </TDDialog>

        <TDDialog
          isOpen={isAddStudentsDialogOpen}
          onClose={closeAddStudentsDialog}
          title={t('students_management_add_students_title') + ' ' + groupName}
        >
          <AddStudentsForm
            onCancel={closeAddStudentsDialog}
            onSave={onAddStudents}
            isSaving={isSaving}
          />
        </TDDialog>

        <TDDialog
          isOpen={isEditStudentDialogOpen}
          onClose={closeEditStudentDialog}
          title={t('students_management_edit_student_title')}
        >
          <EditStudentForm
            student={itemToEdit}
            onCancel={closeEditStudentDialog}
            onSave={onEditStudent}
            isSaving={isEditing}
          />
        </TDDialog>

        <TDDialog
          isOpen={isChangeClassDialogOpen}
          onClose={closeChangeClassDialog}
          title={t('students_management_change_class')}
          allowOverflow
        >
          <ChangeClassForm
            onCancel={closeChangeClassDialog}
            onChangeClass={onChangeClass}
            isChangingClass={isChangingClass}
            onChangeClassOption={onChangeClassOption}
            selectedClassOption={selectedClassOption}
            classOptions={classOptions}
            itemToChangeClass={itemToChangeClass}
          />
        </TDDialog>

        <TDDialog
          isOpen={isDeleteStudentDialogOpen}
          onClose={closeDeleteStudentDialog}
          title={
            t('students_management_delete_student') +
            ' ' +
            itemToDelete?.username
          }
        >
          <DeleteStudentForm
            onCancel={closeDeleteStudentDialog}
            onDelete={onDeleteStudent}
            isDeleting={isDeleting}
          />
        </TDDialog>
      </PageWithHeader>
    </div>
  );
};

export default StudentsManagement;
