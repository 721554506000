import { useTranslation } from 'react-i18next';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import Table from '../../../components/table/Table';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import { AnalysisSegment } from '../../../utils/analysis';
import EmptyStudentAnalysis from './components/emptyStudentAnalysis/EmptyStudentAnalysis';
import SelectStudentsOrder from './components/SelectStudentsOrder/SelectStudentsOrder';
import SelectStudentsTimeRange from './components/SelectStudentsTimeRange/SelectStudentsTimeRange';
import StudentsAnalysisTooltip from './components/StudentsAnalysisTooltip/StudentsAnalysisTooltip';
import './studentsPage.scss';
import useStudentsPage from './useStudentsPage';

const StudentsPage = ({ isMock = false }) => {
  const { t } = useTranslation();
  const {
    columns,
    usersData,
    isLoading,
    selectedOrderBy,
    selectedTimeRange,
    setSelectedOrderBy,
    setSelectedTimeRange
  } = useStudentsPage(isMock);

  return (
    <div className='students-page'>
      <PageWithHeader
        left={
          <PageTitleWithTooltip
            title={t('students_analysis')}
            testId='students-page-heading'
          />
        }
        right={
          <div className='students-page__filters-container'>
            <SelectStudentsOrder
              selectedOrderBy={selectedOrderBy}
              onChange={setSelectedOrderBy}
            />
            <SelectStudentsTimeRange
              selectedTimeRange={selectedTimeRange}
              onChange={setSelectedTimeRange}
            />
          </div>
        }
        tooltip={<StudentsAnalysisTooltip />}
        pageName={AnalysisSegment.PAGE_NAMES.Students_Analysis_Page}
      >
        <Table
          data={usersData}
          columns={columns}
          isLoading={isLoading}
          testId='students-analysis-table'
        />

        {!isLoading && usersData.length === 0 && <EmptyStudentAnalysis />}
      </PageWithHeader>
    </div>
  );
};

export default StudentsPage;
