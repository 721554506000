import { TDTypography } from '../../atoms/TDUILib';
import InfoTooltip from '../../atoms/tooltip/InfoTooltip';
import './PageTitleWithTooltip.scss';

const PageTitleWithTooltip = ({ title, tooltip, testId }) => {
  return (
    <div className='page-title-with-tooltip__container'>
      <TDTypography
        as='h3'
        variant='body-l'
        className='page-title-with-tooltip__title'
        fontWeight='bold'
        data-testid={testId}
      >
        {title}
      </TDTypography>
      {tooltip && (
        <div className='page-title-with-tooltip__tooltip'>
          <InfoTooltip text={tooltip} />
        </div>
      )}
    </div>
  );
};

export default PageTitleWithTooltip;
