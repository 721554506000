import './TDHeading.scss';

const TDHeading = ({ children, variant = 'h1', className, color = 'dark' }) => {
  const Tag = variant;
  return (
    <Tag
      className={`td-heading td-heading--${variant} td-heading--${color} ${
        className || ''
      }`}
    >
      {children}
    </Tag>
  );
};

export default TDHeading;
