import { useTranslation } from 'react-i18next';
import TooltipCell from '../TooltipCell/TooltipCell';
import './cellStudentPerformance.scss';

const CellStudentPerformance = ({ studentPerformanceData }) => {
  const {
    total,
    correct,
    incorrect,
    abandoned,
    correctPercent,
    incorrectPercent,
    abandonedPercent
  } = studentPerformanceData;

  const { t } = useTranslation();

  const getBarClasses = ({ percent }) => {
    const classes = ['cell-student-performance__progress-bar'];
    if (percent === 100) {
      classes.push('cell-student-performance__progress-bar--full');
    }
    if (percent === 0) {
      classes.push('cell-student-performance__progress-bar--none');
    }
    return classes.join(' ');
  };

  const bars = [
    { type: 'correct', percent: correctPercent },
    { type: 'incorrect', percent: incorrectPercent },
    { type: 'abandoned', percent: abandonedPercent }
  ].filter((bar) => bar.percent > 0);

  const PerformanceTooltipComponent = ({
    total,
    correct,
    incorrect,
    abandoned,
    correctPercent,
    incorrectPercent,
    abandonedPercent
  }) => {
    return (
      <div className='cell-student-performance-tooltip'>
        <div className='cell-student-performance-tooltip__title'>
          {`${total} ${t('activities')}`}
        </div>
        <div className='cell-student-performance-tooltip__legend cell-student-performance-tooltip__legend--correct'>
          <span />
          {`${correct} ${t('student_activities_correct')} - ${correctPercent}%`}
        </div>
        <div className='cell-student-performance-tooltip__legend cell-student-performance-tooltip__legend--incorrect'>
          <span />
          {`${incorrect} ${t(
            'student_activities_incorrect'
          )} - ${incorrectPercent}%`}
        </div>
        <div className='cell-student-performance-tooltip__legend cell-student-performance-tooltip__legend--abandoned'>
          <span />
          {`${abandoned} ${t(
            'student_activities_abandoned'
          )} - ${abandonedPercent}%`}
        </div>
      </div>
    );
  };

  return (
    <TooltipCell
      // open={true}
      title={
        studentPerformanceData && (
          <PerformanceTooltipComponent
            total={total}
            correct={correct}
            incorrect={incorrect}
            abandoned={abandoned}
            correctPercent={correctPercent}
            incorrectPercent={incorrectPercent}
            abandonedPercent={abandonedPercent}
          />
        )
      }
      placement='bottom'
    >
      <div className='cell-student-performance'>
        <div className='cell-student-performance__progress-bar'>
          <div className='cell-student-performance__progress-bar-bg'></div>
          <div className='cell-student-performance__progress-bar-percentages'>
            {bars.map((bar, index) => (
              <div
                key={bar.type}
                className={`cell-student-performance__progress-bar-${
                  bar.type
                } ${getBarClasses({
                  percent: bar.percent
                })}`}
                style={{ width: `${bar.percent}%` }}
              />
            ))}
          </div>
        </div>
      </div>
    </TooltipCell>
  );
};

export default CellStudentPerformance;
