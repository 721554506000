import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TDButton from '../../../../../../atoms/TDButton/TDButton';
import TDSelect from '../../../../../../atoms/TDSelect/TDSelect';
import { useCourse } from '../../../../../../utils/contexts/CourseContext';
import './changeClassForm.scss';

import { ReactComponent as ExclamationIcon } from '../../../../../../assets/icons/solid/exclamation.svg';
import { getStudentDetails } from '../../../../../../utils/crud/student';

const ChangeClassForm = ({
  onCancel,
  onChangeClass,
  isChangingClass,
  onChangeClassOption,
  selectedClassOption,
  classOptions,
  itemToChangeClass
}) => {
  const { t } = useTranslation();

  const STEPS = ['SELECT_CLASS', 'CONFIRM_CLASS_CHANGE'];

  const [step, setStep] = useState(STEPS[0]);
  const [showWarning, setShowWarning] = useState(false);
  const { selectedCourse } = useCourse();
  const [isCheckingIfStudentHasProgress, setIsCheckingIfStudentHasProgress] =
    useState(true);

  const checkIfStudentHasProgress = async () => {
    const studentDetails = await getStudentDetails({
      studentGuid: itemToChangeClass.guid,
      courseGuid: selectedCourse?.guid
    });

    setIsCheckingIfStudentHasProgress(false);

    if (studentDetails.has_activity) {
      setShowWarning(true);
    }
  };

  useEffect(() => {
    if (!selectedCourse?.guid) return;
    checkIfStudentHasProgress();
  }, [selectedCourse?.guid]);

  if (isChangingClass) {
    return (
      <div className='change-class-form'>
        <div className='change-class-form__content'>
          <div className='change-class-form__loading'>
            <CircularProgress size='48px' />
            <div className='change-class-form__loading-text'>
              {t('students_management_changing_class')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='change-class-form'>
      <div className='change-class-form__content'>
        {step === STEPS[0] && (
          <TDSelect
            label={t('students_management_class_label')}
            options={classOptions}
            selectedOption={selectedClassOption}
            onChange={onChangeClassOption}
            fullWidth
            disabledItems={[
              {
                label: selectedCourse?.school_group_name,
                value: selectedCourse?.school_group_guid
              }
            ]}
          />
        )}
        {step === STEPS[1] && (
          <div className='change-class-form__warning'>
            <div>
              {t('students_management_change_class_warning', {
                studentName:
                  itemToChangeClass?.name + ' ' + itemToChangeClass?.lastname,
                currentClass: selectedCourse?.school_group_name,
                targetClass: selectedClassOption?.label
              })}
            </div>
            {showWarning && (
              <div>
                <ExclamationIcon />
                {t('students_management_change_class_progress_warning')}
              </div>
            )}
          </div>
        )}
      </div>
      <div className='change-class-form__actions'>
        <TDButton onClick={onCancel} variant='transparent'>
          {t('cancel')}
        </TDButton>

        <TDButton
          onClick={step === STEPS[0] ? () => setStep(STEPS[1]) : onChangeClass}
          color='primary'
          variant='outline'
          disabled={
            selectedClassOption?.value === selectedCourse?.school_group_guid ||
            (step === STEPS[1] && isCheckingIfStudentHasProgress)
          }
        >
          {t('students_management_change_class')}
        </TDButton>
      </div>
    </div>
  );
};

export default ChangeClassForm;
