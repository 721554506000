import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import QuizToast from '../../pages/teacherDashboard/practices/viewQuiz/components/quizToast/QuizToast';
import { useCourse } from '../../utils/contexts/CourseContext';
import { customStorage } from '../../utils/customStorage';
import { fetchWithToken } from '../../utils/helpers/fetcher';

export default function useGenerateTraces() {
  const { selectedCourse } = useCourse();
  const [isGenerateTracesLoading, setIsGenerateTracesLoading] = useState(false);
  const [lastDateGenerateTraces, setLastDateGenerateTraces] = useState('');
  const [isGenerateTracesDialog, setIsGenerateTracesDialog] = useState(false);

  useEffect(() => {
    checkStateGenerateTraces();
  }, [selectedCourse?.guid]);

  useEffect(() => {
    let intervalo = null;
    if (isGenerateTracesLoading) {
      intervalo = setInterval(() => {
        checkStateGenerateTraces();
      }, 30000);
    } else {
      clearInterval(intervalo);
    }

    // Limpiar el intervalo cuando el componente se desmonte
    return () => {
      clearInterval(intervalo);
    };
  }, [isGenerateTracesLoading]);

  const checkStateGenerateTraces = async () => {
    // Comprobar el estado de las trazas de ese curso para saber si está en proceso
    if (selectedCourse) {
      let response = await fetchWithToken({
        method: 'GET',
        path: `lms/courses/${selectedCourse?.guid}/blueberry/demo`
      });
      // Si esta en proceso se activa el loading
      if (response && response.data && response.data.length > 0) {
        const responseCourse = response.data.find((statusCourse) => {
          return statusCourse.guid === selectedCourse.guid;
        });
        if (responseCourse && responseCourse.demo_generator_status) {
          const dateLastGenerate = responseCourse?.demo_generator_completed_at;

          if (dateLastGenerate && dateLastGenerate != null) {
            const fecha = new Date(dateLastGenerate);
            let dia = fecha.getDate();
            let mes = fecha.getMonth() + 1; // Los meses en JavaScript están indexados desde 0
            let año = fecha.getFullYear();
            let horas = fecha.getHours();
            let minutos = fecha.getMinutes();

            // Aseguramos formatos de dos dígitos para días, mes, horas y minutos
            dia = dia < 10 ? `0${dia}` : dia;
            mes = mes < 10 ? `0${mes}` : mes;
            horas = horas < 10 ? `0${horas}` : horas;
            minutos = minutos < 10 ? `0${minutos}` : minutos;

            const fechaFormateada = `${dia}/${mes}/${año} ${horas}:${minutos}`;
            setLastDateGenerateTraces(fechaFormateada);
          }

          switch (responseCourse?.demo_generator_status) {
            case 'completed':
              const isCreateGenerateTraces = customStorage.get(
                'bb_generate_traces_' + selectedCourse?.guid
              );
              if (isCreateGenerateTraces) {
                toast(<QuizToast variant='generate-traces-success' />, {
                  position: 'bottom-left',
                  autoClose: 3000,
                  hideProgressBar: true
                });
                customStorage.remove(
                  'bb_generate_traces_' + selectedCourse?.guid
                );
              }
              setIsGenerateTracesLoading(false);
              break;

            case 'in_progress':
              setIsGenerateTracesLoading(true);
              break;

            default:
              setIsGenerateTracesLoading(false);
              break;
          }
        }
      }
    }
  };

  function onConfirmGenerateTracesDialog() {
    handleCloseGenerateTracesDialog();
    generateTracesByCourse();
  }

  function handleCloseGenerateTracesDialog() {
    setIsGenerateTracesDialog(false);
  }

  async function generateTracesByCourse() {
    setIsGenerateTracesLoading(true);
    // LLamada al endpoint de generar datos
    let response = await fetchWithToken({
      method: 'POST',
      path: `lms/courses/${selectedCourse?.guid}/blueberry/demo`,
      data: {}
    });
    if (response && response.data) {
      window.localStorage.setItem(
        'bb_generate_traces_' + selectedCourse?.guid,
        true
      );
      switch (response.data?.status) {
        case 'completed':
          setIsGenerateTracesLoading(false);
          break;

        case 'in_progress':
          toast.error(<QuizToast variant='generate-traces-warning' />, {
            position: 'bottom-left',
            autoClose: 3000,
            hideProgressBar: true,
            icon: false
          });
          setIsGenerateTracesLoading(true);
          break;

        default:
          setIsGenerateTracesLoading(false);
          break;
      }
    }
  }
  return {
    selectedCourse,
    isGenerateTracesLoading,
    lastDateGenerateTraces,
    setIsGenerateTracesDialog,
    isGenerateTracesDialog,
    onConfirmGenerateTracesDialog,
    handleCloseGenerateTracesDialog
  };
}
