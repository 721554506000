import PropTypes from 'prop-types';
import '../../style/TDUILib.scss';
import './TDTypography.scss';

const TDTypography = ({
  as: Component = 'div', // Default to 'div'
  className,
  children,
  variant = 'body-m',
  fontWeight = 'medium',
  ...props
}) => {
  // Construct the class names based on variant and fontWeight
  const classes = `td-typography ${variant}-${fontWeight} ${
    className ? className : ''
  }`.trim();

  return (
    <Component className={classes} {...props}>
      {children}
    </Component>
  );
};

TDTypography.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'body-xl',
    'body-l',
    'body-m',
    'body-s'
  ]),
  fontWeight: PropTypes.oneOf(['medium', 'semibold', 'bold'])
};

export default TDTypography;
