import { useTranslation } from 'react-i18next';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../utils/hooks/useAuth';
import GameQuizButton from '../components/gameQuizButton/GameQuizButton';
import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import GameQuizTitle from '../components/gameQuizTitle/GameQuizTitle';
import LobbyPlayerCounter from '../components/lobbyPlayerCounter/LobbyPlayerCounter';
import LobbyRotatingText from '../components/lobbyRotatingText/LobbyRotatingText';
import PlayerCard from '../components/playerCard/PlayerCard';
import './playQuizLobby.scss';
import usePlayQuizLobby from './usePlayQuizLobby';

const PlayQuizLobby = ({
  quizGuid,
  quizName,
  gotoCountdown,
  playerList,
  totalPlayers,
  setPlayerList,
  playSoundEffect,
  currentGameGuid,
  closeGame
}) => {
  const { schoolAssetsPath } = useAuth();
  const DEFAULT_AVATAR = generateAssetURL({
    asset: 'default_single_avatar.png',
    schoolAssetsPath
  });

  const {
    userIsDemo,
    connectedPlayers,
    isLoadingResendInvitation,
    onCloseQuiz,
    onResendInvitations
  } = usePlayQuizLobby({
    quizGuid,
    playerList,
    setPlayerList,
    playSoundEffect,
    currentGameGuid
  });

  const { t } = useTranslation();

  return (
    <div className='play-quiz-lobby'>
      <div className='play-quiz-lobby__header'>
        <GameQuizHeader
          quizGuid={quizGuid}
          closeGame={closeGame}
          leftComponent={
            userIsDemo && (
              <GameQuizButton
                text={
                  isLoadingResendInvitation
                    ? t('quiz_resend_invitations_button_loading')
                    : t('quiz_resend_invitations_button')
                }
                onClick={onResendInvitations}
                outline
                disabled={isLoadingResendInvitation}
              />
            )
          }
          centerComponent={<GameQuizTitle title={quizName} />}
          rightComponent={
            <GameQuizButton
              text={t('quiz_start_button')}
              onClick={gotoCountdown}
            />
          }
        />
      </div>
      <div className='play-quiz-lobby__content'>
        <div className='play-quiz-lobby__waiting-text'>
          {connectedPlayers === totalPlayers
            ? t('quiz_ready')
            : t('quiz_waiting_players')}
        </div>
        <div className='play-quiz-lobby__player-counter'>
          <LobbyPlayerCounter
            connectedPlayers={connectedPlayers}
            totalPlayers={totalPlayers}
          />
        </div>

        <div className='play-quiz-lobby__player-list'>
          {playerList?.map((player) => (
            <PlayerCard
              key={player.user_guid}
              name={player.name}
              avatar={player.avatar ? player.avatar : DEFAULT_AVATAR}
              isConnected={player.is_online}
              size={playerList?.length < 6 ? 'large' : 'small'}
            />
          ))}
        </div>

        <div className='play-quiz-lobby__random-text'>
          <LobbyRotatingText />
        </div>
      </div>
    </div>
  );
};

export default PlayQuizLobby;
