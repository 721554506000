import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../../../../../assets/icons/outline/plus-circle.svg';
import TDHeaderButton from '../../../../../../atoms/TDHeaderButton/TDHeaderButton';
import generateAssetURL from '../../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../../utils/hooks/useAuth';
import './emptyStudentList.scss';
const EmptyStudentList = ({ onAddStudents }) => {
  const { schoolAssetsPath } = useAuth();
  const { t } = useTranslation();

  const searchingAvatar = generateAssetURL({
    asset: 'avatar_searching.svg',
    schoolAssetsPath
  });

  return (
    <div className='empty-student-list'>
      <img src={searchingAvatar} alt='searching' />
      <div className='empty-student-list__title'>
        {t('student_management_empty_title')}
      </div>
      <div className='empty-student-list__button--container'>
        <TDHeaderButton onClick={onAddStudents} icon={<AddIcon />} border>
          {t('add_students_button')}
        </TDHeaderButton>
      </div>
    </div>
  );
};

export default EmptyStudentList;
