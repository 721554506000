import './addStudentsForm.scss';
import AddStudentsFormNavigation from './AddStudentsFormNavigation/AddStudentsFormNavigation';
import AddStudentsFormTabs from './AddStudentsFormTabs/AddStudentsFormTabs';
import { AddStudentsFormProvider } from './useAddStudentsForm';

const AddStudentsForm = ({ onSave, onCancel, isSaving }) => {
  return (
    <div>
      <AddStudentsFormProvider>
        {!isSaving && <AddStudentsFormNavigation />}
        <AddStudentsFormTabs
          onSave={onSave}
          onCancel={onCancel}
          isSaving={isSaving}
        />
      </AddStudentsFormProvider>
    </div>
  );
};

export default AddStudentsForm;
