import { ReactComponent as ExclamationIcon } from '../../assets/icons/solid/exclamation.svg';
import './TDWarning.scss';

const TDWarning = ({
  showExclamationIcon = true,
  fullWidth = false,
  children,
  className = ''
}) => {
  return (
    <div
      className={`td-dialog-warning ${
        fullWidth ? 'full-width' : ''
      } ${className}`}
    >
      {showExclamationIcon && (
        <div className='td-dialog-warning__icon'>
          <ExclamationIcon />
        </div>
      )}
      <div className='td-dialog-warning__text'>{children}</div>
    </div>
  );
};

export default TDWarning;
