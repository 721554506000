import { useTranslation } from 'react-i18next';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationCircleOutlineIcon
} from '../../../../../../../../assets/icons';
import uuidv4 from '../../../../../../../../utils/helpers/uuidv4';

const BulkStudentImportFormStepTwo = ({ data, isFileValid }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`td-bulk-student-import-form--summary-status ${
          isFileValid
            ? 'td-bulk-student-import-form--summary-status-valid'
            : 'td-bulk-student-import-form--summary-status-invalid'
        }`}
      >
        <div className='td-bulk-student-import-form--summary-total-users'>
          {t('students_management_add_students_import_summary_total_users', {
            total: data.length
          })}
        </div>
        {data.length > 0 && (
          <div className='td-bulk-student-import-form--summary-total-status'>
            {isFileValid ? (
              <CheckCircleIcon />
            ) : (
              <ExclamationCircleOutlineIcon />
            )}
            <div>
              {isFileValid
                ? t(
                    'students_management_add_students_import_summary_status_valid'
                  )
                : t(
                    'students_management_add_students_import_summary_status_invalid',
                    {
                      total: `${
                        data.filter((student) => student.error).length
                      }/${data.length}`
                    }
                  )}
            </div>
          </div>
        )}
      </div>
      {Boolean(data.length) && (
        <div>
          <div className='td-bulk-student-import-form--table-header'>
            <div className='td-bulk-student-import-form--table-header-cell'>
              {t('students_management_add_students_import_table_header_name')}
            </div>
            <div className='td-bulk-student-import-form--table-header-cell'>
              {t(
                'students_management_add_students_import_table_header_lastname'
              )}
            </div>
            <div className='td-bulk-student-import-form--table-header-cell'></div>
          </div>
          {data.map((student, index) => (
            <div
              key={student.name + student.lastname + uuidv4()}
              className={`td-bulk-student-import-form--table-row ${
                student.error
                  ? 'td-bulk-student-import-form--table-row-error'
                  : ''
              }`}
            >
              <div className='td-bulk-student-import-form--table-row-cell'>
                {student.name}
              </div>
              <div className='td-bulk-student-import-form--table-row-cell'>
                {student.lastname}
              </div>
              <div className='td-bulk-student-import-form--table-row-cell'>
                {student.error ? (
                  <ExclamationCircleIcon />
                ) : (
                  <CheckCircleIcon />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BulkStudentImportFormStepTwo;
