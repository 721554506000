import { isValidElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LoginWithLayers = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let script = document.createElement('script');
    script.src = 'https://js.layers.digital/v1/LayersAuth.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isValidElement(<layers-auth-button />)) {
        console.log('layers-auth-button is loaded');
        setIsLoaded(true);
      }
    }, 100);

    if (isLoaded) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isLoaded]);

  if (!isLoaded) {
    return null;
  }

  return (
    <layers-auth-button
      client-id='blueberry'
      scope='openid email profile fullname related.communities'
      response-type='code'
      redirect-uri={window.location.protocol + '//' + window.location.host}
      label={t('layer_login_text')}
      style={{
        width: '100%'
      }}
    />
  );
};

export default LoginWithLayers;
