import { useTranslation } from 'react-i18next';
import { TDTypography } from '../../../../../atoms/TDUILib';
import './listReportsTooltip.scss';

const ListReportsTooltip = () => {
  const { t } = useTranslation();
  return (
    <div className='list-reports-tooltip'>
      <div>
        <TDTypography variant='body-m' fontWeight='bold'>
          {t('page_tooltip.reports_overview')}
        </TDTypography>
      </div>
      <div>
        <TDTypography variant='body-m' fontWeight='medium'>
          {t('page_tooltip.reports_info')}
        </TDTypography>
      </div>

      <div className='list-reports-tooltip-item-separator' />

      <div>
        <TDTypography variant='body-m' fontWeight='semibold'>
          {t('page_tooltip.content_states')}
        </TDTypography>
      </div>

      <div className='list-reports-tooltip__row'>
        <TDTypography variant='body-m'>
          <TDTypography variant='body-m' fontWeight='semibold' as='span'>
            {t('page_tooltip.has_difficulties')}
          </TDTypography>
          {t('page_tooltip.difficulties_info')}
        </TDTypography>
      </div>

      <div className='list-reports-tooltip__row'>
        <TDTypography variant='body-m' fontWeight='medium'>
          <TDTypography variant='body-m' fontWeight='semibold' as='span'>
            {t('page_tooltip.content_mastered')}
          </TDTypography>
          {t('page_tooltip.mastered_info')}
        </TDTypography>
      </div>

      <div className='list-reports-tooltip__row'>
        <TDTypography variant='body-m' fontWeight='medium'>
          <TDTypography variant='body-m' fontWeight='semibold' as='span'>
            {t('page_tooltip.content_forgotten')}
          </TDTypography>
          {t('page_tooltip.forgotten_info')}
        </TDTypography>
      </div>
    </div>
  );
};

export default ListReportsTooltip;
