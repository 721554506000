import { ClickAwayListener } from '@mui/base';
import { Fade, Popper } from '@mui/material';
import { useEffect, useState } from 'react';
import MultipleStudentXrayPopup from './MultipleStudentXrayPopup';
import SingleStudentProfilePopup from './SingleStudentProfilePopup';

const StudentXrayPopup = ({
  anchorEl,
  selectedStudents,
  progressRange,
  effortRange,
  onClose,
  onClickStudentDetail
}) => {
  const [open, setOpen] = useState(Boolean(anchorEl));
  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        if ([...event?.target?.classList].includes('MuiAvatarGroup-avatar'))
          return;
        setOpen(false);
        onClose && onClose();
      }}
    >
      <Popper
        id={Boolean(anchorEl) ? 'simple-popper' : undefined}
        open={open}
        disablePortal={false}
        placement='auto-start'
        anchorEl={anchorEl}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              padding: { top: 5, bottom: 5, left: 70, right: 5 }
            }
          }
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              {console.log(progressRange, effortRange)}
              {selectedStudents.length === 1 && (
                <SingleStudentProfilePopup
                  name={`${selectedStudents[0].name} ${selectedStudents[0].lastname}`}
                  guid={selectedStudents[0]?.person_guid}
                  lastLogin={selectedStudents[0]?.last_login}
                  effort={(100 * selectedStudents[0]?.effort) / effortRange.max}
                  progress={
                    (100 * selectedStudents[0]?.progress) / progressRange.max
                  }
                  color={selectedStudents[0]?.color}
                  onClickStudentDetail={onClickStudentDetail}
                />
              )}
              {selectedStudents.length > 1 && (
                <MultipleStudentXrayPopup
                  students={selectedStudents}
                  progressRange={progressRange}
                  effortRange={effortRange}
                  onClickStudentDetail={onClickStudentDetail}
                />
              )}
            </div>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default StudentXrayPopup;
